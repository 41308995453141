
import  React from 'react';

export class ErrorBoundary extends React.Component<{}, { error: Error|null, errorInfo: any, }> {
  constructor(props:any) {
    super(props);
    this.state = {error: null, errorInfo: null };
  }

  componentDidCatch( error:Error, errorInfo:any ) {
    // Catch errors in any components below and re-render with error message
    this.setState({ error, errorInfo });
    // You can also log error messages to an error reporting service here
  }

  render() {
   // const { message: $Message } = this.props;

    if (this.state.errorInfo) {
      return (
        <section>
          <h2>エラーが発生しました</h2>
          {
            process.env.NODE_ENV == "development" ?
                <>
                  <p>{this.state.error && this.state.error.toString()}</p> 
                  <ul>{ this.state.errorInfo.componentStack.split("\n").filter( (line:string)=>!!line).map( (line:string,idx:number)=><li key={idx}>{line}</li>) }</ul>
                </>
                :
                null
          }
           
        </section>
      );
    }
    // Normally, just render children
    return this.props.children;
  }  
}
