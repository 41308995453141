
import React, { 
	useEffect, 
	useState, 
	useRef, 
	useMemo 
} from 'react';

import { TPane } from './usePane';
import { Grip } from './Grip';

import styled from 'styled-components';

// type
export interface IStyle {
	width?: number|string;
	height?: number|string;
	top?: number|string;
	bottom?: number|string;
	left?: number|string;
	right?: number|string;
};

type TGripKey = "top" | "bottom" | "left" | "right";

export type TResize = ( isDone:boolean, targetKey:string, value?: number )=>void;

interface IProps {
	id: string;
	children: React.ReactNode;
	dispatch?: TPane["dispatch"];
	gripKeys?: TGripKey[];
	open?: boolean;
	//disabled?: boolean;
	onOpen?: ()=>void;
	onClose?: ()=>void;
	onEnter?: ()=>void;
	onLeave?: ()=>void;
	closeButton?: boolean;
	style?: IStyle;
	className?:string; // styled component で FCを継承するために必要
	/*
	left?: number|string;
	right?: number|string;
	top?: number|string;
	bottom?: number|string;
	width: number|string;
	height: number|string;
	*/
}




const DivRoot = styled.div`

	// 一番底の要素。背景は黒。この子要素は透明度を操作して Focusが当たっているかどうかのスタイルを表現する。
	border-radius: 2px;
	filter: drop-shadow(0px 0px 1px rgba(0,0,0,.8));
	background: black;
	position: absolute;
	margin: auto auto;

	// focus があたった時に内側直下DIVのスタイルを変更
	&[data-focus="true"]>div[data-pane-inside] {
		opacity: 1.0;
	}

	&[data-open="false"] {
		display: none;
	}

	&:hover>div[data-pane-aside] {
		display: block;
	}

`;
DivRoot.displayName = "PaneDivRoot";

const DivInside = styled.div`
	// 子要素。フォーカスが当たっている場合は opacity が100%になってハッキリみえる。
	//opacity: .95;
	//border-radius: 2px;
	//border: 2px solid #888;
	//background: black;
	width: 100%;
	//height: 100%;
	//overflow: scroll;
`;
DivInside.displayName = "PaneDivInside";

const DivAside = styled.div`
	// 閉じるボタンなど
	display: none;
	position: absolute;
	right: 5px; top: 5px ;
`;
DivAside.displayName = "PaneDivAside";

//
//
//
const IconButton = styled.i`
	cursor: pointer;
	color :gray;
	&:hover { color: #CCC; }
`;



export const Pane = ( props: IProps )=>{

	const here = `pane#Pane[${ props.id }]`;

	console.time(`in ${here}`);

	const refObject = useRef<HTMLDivElement>(null);

	const [ isOpen, setOpen ] = useState<boolean>( false );
	const [ isFocus, setFocus ] = useState<boolean>( false );
	const [ isDisabled, setDisabled ] = useState<boolean>( false );

	const initialAdd = { 
		"width": 0, 	  // 今回の加減分
		"height": 0, 	  // 今回の加減分
		"prev-width": 0,  // 前回の加減分
		"prev-height": 0, // 前回の加減分
	};
	const [ add, setAdd ] = useState(()=>initialAdd );
	//console.log("resize add===>", add )

	useEffect( ()=>{
		console.debug(`in ${here}, mounted.`);
		if( props.id === undefined ) throw new Error(`id is required.`);

		// gripKeys が設定されていて、styleの設定に不足がある場合はエラー
		if ( props.gripKeys && Array.isArray(props.gripKeys) && props.gripKeys.length>0 ){
			const el = refObject.current;
			if ( !el ) throw new Error(`refObject is invalid`);
			// それぞれの gripKey を設定する場合は、それに対応する 2点の Styleが設定されていないとダメ
			const rule:{ [P in TGripKey]: string[] } = {
				left: ["width","right"],	right: ["width","left"], top: ["height","bottom"], 	bottom: ["height","top"],
			}
			props.gripKeys.map( gripKey=>{
				for ( let targetGripKey of rule[gripKey] ){
					if ( !el.style.getPropertyValue(targetGripKey) ){
						// それぞれのgripKeyに対応するスタイルが無いとエラー
						throw new Error(`in ${here}, gripKey '${gripKey}' required style '${ targetGripKey }'`) 
					}
				}
			});
		}

		// デフォルトでOPENの設定acceptで
		props.open && setOpen(true);
		// デフォルトDisabled
		//props.disabled && setDisabled(true);

		return ()=>{ 
			console.debug(`in ${here}, unmounted.`);
			props.dispatch && props.dispatch({ type: "destroy", id: props.id });
		}
	}, [] );

	// マウントした、または状況が変わった時に 操作する関数や変数を格納して、外部から使えるようにする。
	useEffect( ()=>{
		const paneState = {
			el: refObject.current,
			isOpen, setOpen, 
			isFocus, setFocus, 
			isDisabled, setDisabled,
			onOpen: props.onOpen || (()=>{}),
			onClose: props.onClose || (()=>{}),
			onEnter: props.onEnter || (()=>{}),
			onLeave: props.onLeave || (()=>{}),
		}

		// 状態が変わった時、accept で状態を伝える。
		props.dispatch && props.dispatch({ 
			type: "accept",
			id: props.id,
			paneState
		});

		console.debug(`in ${here}, accept`);

	}, [ isOpen, isFocus, isDisabled, refObject.current ] );


	// OpenされたりCloseされた時にイベントを起こす。
	useEffect( ()=>{
		if ( isOpen === true ){
			props.dispatch&&props.dispatch({ type: "onOpen", id: props.id });
		} else {
			props.dispatch&&props.dispatch({ type: "onClose", id: props.id });
		}
	}, [ isOpen ]);


	const onClick = (e: React.SyntheticEvent)=>{
		e.preventDefault();
		e.stopPropagation();
		props.dispatch&&props.dispatch({ type: "focus", id: props.id });
	};

	const onEnter = (e: React.SyntheticEvent)=>{
		e.preventDefault();
		e.stopPropagation();
		props.dispatch&&props.dispatch({ type: "onEnter", id: props.id });
	};

	const onLeave = (e: React.SyntheticEvent)=>{
		e.preventDefault();
		e.stopPropagation();
		props.dispatch&&props.dispatch({ type: "onLeave", id: props.id });
	};

	const close = ()=>{
		props.dispatch&&props.dispatch({ type: "close", id: props.id });
	};

	// 位置をリセットする
	/*const reset = ()=>{
		setAdd( initialAdd );
	};*/

	const resize: TResize = ( isDone, targetKey, value )=>{
		console.debug(`in ${here}, resize.`);
		if ( targetKey !== "width" && targetKey !== "height" ) {
			console.error( "targetKey==>", targetKey );
			throw new Error(`target key invalid`);
		}

		// 前回の加減分（prev-width）に、今回の加減分を加えたものを 現在の加減分（width）とする。
		// done された場合は、現在の加減分を 前回の加減分として格納する。
		const prevTargetKey = `prev-${targetKey}`;
		if ( prevTargetKey !== "prev-width" && prevTargetKey !== "prev-height" ) throw new Error(`target key invalid`)

		if ( isDone ){
			// サイズ変更完了
			setAdd( add=>{
				//const lastValue = ( add[prevTargetKey] || 0 ) + ( value || 0 );
				//console.debug(`in ${here}, resize. isDone: ${isDone?"yes":"no"}, ${prevTargetKey}: ${add[prevTargetKey]}, ${targetKey}: ${value}, lastValue: ${lastValue}`);
				return { ...add, [prevTargetKey]: add[targetKey] }
			});
			return;
		}
		setAdd( add=>{
			const lastValue = ( add[prevTargetKey] || 0 ) + ( value || 0 );
			console.debug(`in ${here}, resize. isDone: ${isDone?"yes":"no"}, value: ${value}, ${prevTargetKey}: ${add[prevTargetKey]}, ${targetKey}: ${value}, lastValue: ${lastValue}`);
			return { ...add, [targetKey]: lastValue }
		});
	};

	// Grip （最大4辺）を表示
	const Grips = useMemo( ()=>{
		return Array.isArray(props.gripKeys) && props.gripKeys.map( gripKey=>(
			<Grip key={gripKey} gripKey={gripKey} resizePane={resize} />
		));
	}, [ props.gripKeys ]);


	console.timeEnd(`in ${here}`);
	return useMemo( ()=>{

		// リサイズ分がある場合、addCalc オブジェクトにそれぞれ リサイズ分を追加計算したスタイルに変更して、あとでStyle属性で上書きする。
		const addedStyle:{ width?:string, height?:string } = {};
		if ( props.style && props.style.width !== undefined 	&& add.width !== 0 	) addedStyle.width = `calc( ${props.style.width} + ${add.width}px )`;
		if ( props.style && props.style.height !== undefined	&& add.height !== 0 ) addedStyle.height = `calc( ${props.style.height} + ${add.height}px )`;
		//console.log(`in ${here}, useMemo:render`, addedStyle );

		return (
			<DivRoot 
				data-pane
				id={props.id} 
				key={props.id} 
				data-focus={!!isFocus} 
				data-open={!!isOpen} 
				ref={refObject} 
				onClick={onClick} 
				onMouseEnter={onEnter} 
				onMouseLeave={onLeave} 
				data-disabled={isDisabled}
				style={{ ...props.style, ...addedStyle }}
				className={props.className||""} // styled component で FCを継承するために必要
				>
				{/* <DivInside data-pane-inside > */}
					{props.children}
				{/* </DivInside> */}
				<DivAside data-pane-aside >
					{/* 閉じるボタン */}
					{ props.closeButton  ? (<IconButton className={"icon-close"} onClick={close} title="閉じる"/>) : null }
					{/* 位置リセットボタン */}
					{/*  props.gripKeys && props.gripKeys.length > 0 ? (<IconButton className={"icon-egg-with-a-crack"} onClick={reset} title="サイズリセット"/>) : null */}
				</DivAside>
				{ Grips }
			</DivRoot>
		);
	}, [ isFocus, isOpen, isDisabled, add, props.children ]);

};

Pane.displayName = `Pane`;

/*
					style={{
						left: props.left 		=== undefined ? "auto" : props.left,
						right: props.right 		=== undefined ? "auto" : props.right,
						top: props.top 			=== undefined ? "auto" : props.top,
						bottom: props.bottom 	=== undefined ? "auto" : props.bottom,
						width: props.width 		=== undefined ? "auto" : props.width,
						height: props.height	=== undefined ? "auto" : props.height,
					}}
*/

