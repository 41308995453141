import React,{
	//useContext,
	useState,
	//useEffect,
	//useMemo,
	useRef,
	//useReducer
	//Suspense,
} from 'react';

import {
	IStylingState,
	IFeatureItems,
	TStylingDispatch,
} from "../types";

import * as types from "../../../../types";

import {
	ColorSelected,
} from "./colorSelected";

/*
import {
	ImageSelected,
} from "./imageSelected";
*/

import {
	ColorPallet
} from "../../colorPallet";

/*
import {
	Gallery
} from "../../gallery";
*/

import {
    IModalClientProperties
} from "../../../../portal/modal"


export const Strong: React.FC<{
    styleItems: IFeatureItems;
    sectionKey: keyof IStylingState[keyof IStylingState];
    stylingDispatch: React.Dispatch<TStylingDispatch>;
}> = ( props )=>{

    const { color = null, weight = null } = props.styleItems.strong ? props.styleItems.strong: {} ;
    
    const client4colorPallet = useRef<IModalClientProperties|null>(null);

    return (
        <>
            <div className="secontItem">
                <div className="label">
                    color
                </div>
                <div className="value">
                    <ColorSelected 
                        colorValue={ color }
                        onClick={()=>client4colorPallet.current && client4colorPallet.current.setOpen(true) }
                        />
                </div>
            </div>

            <div className="secontItem">
                <div className="label">
                    weight
                </div>
                <div className="value">
                    <select 
                        value={weight||undefined}
                        onChange={ e=>{
                            props.stylingDispatch({
                                type: "setFeatureObject",
                                sectionKey: props.sectionKey,
                                property: "strong",
                                value: { color: color, weight: e.currentTarget.value },
                            })
                        }}>
                        <option value="">(unset)</option>
                        <option value="normal">normal</option>
                        <option value="bold">bold</option>
                    </select>
                </div>
            </div>
            
            
            <ColorPallet
                currentValue={ color }
                client={client4colorPallet}
                commit={ value=>{
                    props.stylingDispatch({
                        type: "setFeatureObject",
                        sectionKey: props.sectionKey,
                        property: "strong",
                        value: { color: value, weight: weight },
                    })
                }}
                />       
        </>
    );
}

Strong.displayName = "Text";