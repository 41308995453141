
import React,{
	//useContext,
	useState,
	useEffect,
	useMemo,
	//useRef,
	//useReducer
	Suspense,
} from 'react';

import {
	useApi2,
	TApiReturn,
	IApiJob,
	ApiError,
	$AbortError,
	isApiOrAbortError,
	TApiDescriptor,
	//IApiContext,
} from "../../../api/useApi2";

import * as types from "../../../types";

import {
	Loader
} from "../loader"

import {
    Modal,
    TModalClient
} from "../../../portal/modal"


const descriptor1: TApiDescriptor = { 
	
	onDuplicate: ( $abort )=>$abort,
	onUnmounted: ( $abort )=>$abort,
	onComplete: ( job )=>{},
	onError: ( job )=>{},
	onAbort: ( job )=>{},
}

export const Gallery: React.FC<{
	currentImage?: types.IImage|null;
	client?: TModalClient;
	commit: ( image: types.IImage )=>void;
}> = ( props )=>{

	const api1 = useApi2(descriptor1);

	const [ images, setImages ] = useState<types.IImage[]>([]);

    useEffect( ()=>{
        api1.get<{ images: types.IImage[]}>(`api/image/purpose/FEATURE`, {}, { label: "画像一覧取得"} ).then( res=>{
			setImages( res.data.images );
		})
	}, [] );

	return useMemo( ()=>{
		return (
			<Modal 
				className="modal-gallery" 
				open={false} 
				client={props.client}
				data-modal-gallery
				>
				<Suspense fallback={ < Loader /> }>
					<Images 
						images={images} 
						currentImage={ props.currentImage || null}
						commit={ image=>{
							props.commit(image);
							props.client && props.client.current && props.client.current.setOpen(false);
						}}/>
				</Suspense>
			</Modal>
		);
	}, [ images ] );
};


//
//
//
const Images: React.FC<{
	currentImage: types.IImage|null;
	images: types.IImage[];
	commit: ( image: types.IImage )=>void;
}> = ( props )=>{

	if ( props.images.length === 0 ) {
		throw ( new Promise( (resolve,reject)=> props.images.length > 0 && resolve(undefined) ));
	}

	const str2json = ( str: string )=>{
		try{
			return JSON.parse( str );
		} catch( err ){
			return { mini: { url: null }};
		}
	}

	return (
		<>
			{
				props.images.map( image=>{
					const src = image ? `${process.env.CDN_URL_BASE||""}/${image.objectKey}_min.${image.ext}` : null ;
					const isCurrent = props.currentImage ? 
										props.currentImage.id == image.id ? true : false
										:
										false
									;
					return (
						<div key={image.id} className={`gallery-thumbnail ${ isCurrent ? "gallery-thumbnail-current" : "" }`} >
							<img 
								crossOrigin="anonymous" 
								src={ src || "" } 
								key={image.id}
								onClick={()=>props.commit(image)}
								/>
						</div>
					);
				})
			}
		</>
	);
};
