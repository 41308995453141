import styled from 'styled-components';
/*
使い方
	<Button className={"icon-checkmark1"}>Save</Button>&nbsp;
	<Button className={"icon-cross"}>Cancel</Button>
*/

import React,{
	//useContext,
	//useState,
	//useEffect,
	//useMemo,
	//useRef,
	//useReducer
	//Suspense,
} from 'react';

export const _Button = styled.button`
	padding: 0;
	//padding: 10px 10px;
	//color: #F0F0F0;
	color: rgba( 255,255,255,.6);
	background: none;
	border: none;
	cursor: pointer;
	border-radius: 3px;
	&:active { 
		transform: translateY(1px);
	}; 
	&:focus { 
		outline:0; // クリック時の青い枠線を消す
	}; 
	&:hover { 
		color: white;
		//background-color: rgba( 255, 255, 255, .2 );
	}
	&.positive {
		color: #8E9;
	}
	&[disabled], &[disabled="true"] {
		//color: #777 ;
		&:hover { 
			background: none;
		}
	}
`;

export const Span = styled.span`

	//font-family: 'Odibee Sans';
	font-family: "Oswald";
	//font-family: 'Peddana';
	//font-family: 'Teko';
	//font-family: 'Open Sans Condensed';
`

export const Button = ( props: any )=>{
	return (
		<_Button {...props} >
			<Span>{ props.children }</Span>
		</_Button>
	)
}
Button.displayName = "Button";





