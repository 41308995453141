import React,{
	//useContext,
	useState,
	//useEffect,
	//useMemo,
	useRef,
	//useReducer
	//Suspense,
} from 'react';

import {
	IStylingState,
	IFeatureItems,
	TStylingDispatch,
} from "../types";

import * as types from "../../../../types";

import {
	ColorSelected,
} from "./colorSelected";

/*
import {
	ImageSelected,
} from "./imageSelected";
*/

import {
	ColorPallet
} from "../../colorPallet";

/*
import {
	Gallery
} from "../../gallery";
*/

import {
    IModalClientProperties
} from "../../../../portal/modal"


export const Text: React.FC<{
    styleItems: IFeatureItems;
    sectionKey: keyof IStylingState[keyof IStylingState];
    stylingDispatch: React.Dispatch<TStylingDispatch>;
}> = ( props )=>{

    const color = props.styleItems.text ? props.styleItems.text.color : null;
    const client4colorPallet = useRef<IModalClientProperties|null>(null);

    return (
        <>
            <div className="secontItem">
                <div className="label">
                    color
                </div>
                <div className="value">
                    <ColorSelected 
                        colorValue={ color }
                        onClick={()=>client4colorPallet.current && client4colorPallet.current.setOpen(true) }
                        />
                </div>
            </div>
            
            
            <ColorPallet
                currentValue={ color }
                client={client4colorPallet}
                commit={ value=>{
                    props.stylingDispatch({
                        type: "setFeatureObject",
                        sectionKey: props.sectionKey,
                        property: "text",
                        value: { color: value },
                    })
                }}
                />       
        </>
    );
}

Text.displayName = "Text";