import styled from 'styled-components';
import React,{
	//useContext,
	//useState,
	//useEffect,
	//useMemo,
	//useRef,
	//useReducer
	//Suspense,
} from 'react';



export const Toggle: React.FC<{
	group: string;
	onClick?: ( e: React.MouseEvent<HTMLSpanElement>, targetElement: HTMLSpanElement, elements: NodeListOf<Element> )=>void;
}> = ( props )=>{

	return (
		<span
			{...props}
			data-toggle
			data-toggle-state={"off"}
			data-toggle-group={ props.group }
			onClick={ ( e: React.MouseEvent<HTMLSpanElement> )=>{
				const group = e.currentTarget.dataset.toggleGroup;
				const elements = document.querySelectorAll(`*[data-toggle-group="${group}"]`);
				elements.forEach( element=>{
					element.setAttribute( `data-toggle-state`, "off" );
				});
				e.currentTarget.setAttribute( `data-toggle-state`, "on" );
				props.onClick && props.onClick( e, e.currentTarget, elements );
			}}
			>
				{ props.children ? props.children : null }
		</span>
	)
}
Toggle.displayName = "Toggle";





