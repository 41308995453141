import React,{
	useContext,
	useState,
	useEffect,
	useMemo,
	useReducer,
	//Suspense,
} from 'react';

import moment from 'moment';


import {Pane} from "../../pane/Pane";

//import * as types from "../../types";

// context
import {AppContext} from "../../app";


import {Articles} from "./Articles";
import {Article} from "./Article/";

import {
	Menu
} from "./Menu";

import {
	Followers
} from "./Followers";

import {
	requesterFactory
} from "./requester";

import {
	IArticleState,
	TArticleDispatch,
	reducerInitialValue,
	reducer,
} from "./reducer";

import {
	controllerFactory,
} from "./controller";

import {
	useApi2,
	TApiReturn,
	IApiJob,
	ApiError,
	$AbortError,
	isApiOrAbortError,
	//IApiContext,
} from "../../api/useApi2";


import styled from 'styled-components';

import {
	P1,
} from "./styled";

import {
	meta_el,
	Button,
	Loader,
	Draggable, Droppable,
} from "../helper"


const Pane2 = styled(Pane)`
	position: relative;
	margin:0;
	height: 100vh;
	overflow: scroll;
`;
const Pane3= styled(Pane)`
	position: relative;
	margin:0;
`;
const Pane4= styled(Pane)`
	position: relative;
	margin:0;
`;
const Pane5= styled(Pane)`
	position: relative;
	margin:0;
`;




interface IArticleContext {
	controller: ReturnType<typeof controllerFactory>
	articleState: IArticleState,
}
export const ArticleContext = React.createContext( {} as IArticleContext );


export const index = ()=>{
	const here = "pages#Articles";
	console.time(`in ${here}`);

	const { $Pane, $Snackbar } = useContext(AppContext);


	const [ articleState, dispatch ] = useReducer( reducer, reducerInitialValue );

	// API のグループと挙動を定義
	const descriptor1 = { 
		onDuplicate: ( $abort: symbol )=>$abort,
		onUnmounted: ( $abort: symbol )=>$abort,
		onComplete: ( job: IApiJob )=>{
			job.option.snackbar && $Snackbar.client.info(`"${job.option.label}" が完了しました`);
		},
		onError: ( job: IApiJob )=>{
			$Snackbar.client.error(`"${job.option.label}" が失敗しました`);
			
		},
		onAbort: ( job: IApiJob )=>{
			$Snackbar.client.warn(`"${job.option.label}" をキャンセルしました`)
		},
	}
	const descriptor2 = Object.assign(
		{},
		descriptor1,
		{ onDuplicate: ( $abort: symbol )=>undefined }
	);

	// 覧取得、新規 article作成、新規 post作成
	const api1 = useApi2(descriptor1);
	// revision更新（複数動作可能）
	const api2 = useApi2(descriptor2);

	// api作成
	const requester = useMemo( ()=>requesterFactory( api1, api2 ), [] );
	// コントローラ（ api と reducer をカバーする ））
	const controller = useMemo( ()=>controllerFactory( requester, dispatch ), [] );

	// mount
	useEffect( ()=>{ 
		console.debug(`in ${here}, mounted.`);
		controller.getAllArticles();
		return ()=>console.debug(`in ${here}, unmounted.`)
	}, [] );

	// 使用不能にする
	useEffect( ()=>{ 
		if ( api1.isBusy ){
			// APIが使用中なら使用不可にする
			$Pane.dispatch({ type: "beDisabled", id: "article" });
			$Pane.dispatch({ type: "beDisabled", id: "articles" });
			$Pane.dispatch({ type: "beDisabled", id: "followers" });
			$Pane.dispatch({ type: "beDisabled", id: "article-menu" });
		} else {
			// APIが使用中なら使用可にする
			$Pane.dispatch({ type: "beEnabled", id: "article" });
			$Pane.dispatch({ type: "beEnabled", id: "articles" });
			$Pane.dispatch({ type: "beEnabled", id: "followers" });
			$Pane.dispatch({ type: "beEnabled", id: "article-menu" });
		}
	}, [api1.isBusy] );


	console.timeEnd(`in ${here}`);
	return (
		<ArticleContext.Provider value={{ articleState, controller }}>
			
				<div id="page-article">
					<Pane2 id={"articles"} dispatch={$Pane.dispatch} 
						//style={{ left: "0", width: "25%", top: "0" }} 
						//gripKeys={["right"]} 
						open={true} 
						>

						<section>
							<Button className={"icon-plus"} onClick={ controller.newArticle } title="article作成">&nbsp;Article</Button>
							&nbsp;&nbsp;
							<Button className={"icon-sphere"} onClick={ ()=>{
									controller.publish().then( res=>{
										$Snackbar.client.info(`公開しました`);
									});
								}} title="公開">&nbsp;Publish</Button>
						</section>
						<Articles articles={ articleState.articles } />

					</Pane2>
					<div id={"article-single"}>
						<Pane3 id={"article"} dispatch={$Pane.dispatch} open={true}>
							
							<Article article={ articleState.article } purpose={ articleState.purpose4single } />
							
						</Pane3>
						<Pane4 id={"followers"} dispatch={$Pane.dispatch} open={true}>
							{
								articleState.purpose4single === "EXISTED" ?
									<Followers article={articleState.article} />
									:
									null
							}
						</Pane4>
					</div>
					<Pane5 id={"article-menu"} dispatch={$Pane.dispatch} open={true}>
						<Menu />
					</Pane5>
				</div>
			
		</ArticleContext.Provider>

		);
};



//
//
//
/*
const reducer: React.Reducer<
	TArticleState,
	{ 
		type: "undefined";
	}|{
		type: "existed";
		article: types.IArticle|ApiError|$AbortError|null;
	}|{ 
		type: "new";
	}
	> = ( state , action )=>{
	const here = `articles#index#reducer`;
	console.debug(`in ${here} , `, "action=>", action ,`reducer state=>`, state,);
	switch ( action.type ){
		case "undefined":
			return { ...state, purpose: "UNDEFINED", article: null };
		break;
		case "existed":
			return { ...state, purpose: "EXISTED", article: action.article };
		break;
		case "new":
			return { ...state, purpose: "NEW", article: null };
		break;
	}
};
*/

