import React from 'react';
//import {I$Message} from "../message/useMessage";

//
type Props = {
	//$Message: I$Message,
};

//export const Spinner: React.FC<Props> = ({ $Message })=>{
export const Spinner: React.FC<Props> = ()=>{
	return (
		<section>
			<h2>Ready</h2>
			{/*<ul data-process>{ $Message.Li }</ul>*/}
		</section>
	);
};

