
import moment from "moment";

export const isYmd = ( org: string )=>{
	// 2020/12/24 のケース（ 分割して、数値にする )
	const c1 = org.replace(/\s/,"").split("/").filter( x=>!isNaN(parseInt(x)) ).map( x=>parseInt(x) );
	if ( _isYmd(c1) ) return true;

	// 2021-12-14T15:00:00.000Z のケース（ 分割して、数値にする )
	const c2 = (org.replace(/\s/,"").split("T"))[0].split("-").filter( x=>!isNaN(parseInt(x)) ).map( x=>parseInt(x) );
	if ( _isYmd(c2) ) return true;

	return false;
}

const _isYmd = ( x: number[] )=>{
	if ( ( x[0] >= 2020  && x[0] < 2099 ) && ( x[1] >= 1 && x[1] <= 12 ) &&  ( x[2] >= 1 && x[2] <= 31 ) ){
		if ( moment( `${x[0]}/${x[1]}/${x[2]}`, "YYYY/MM/DD" )){
			 // 2/31 とかは通さないためにありえる日付か最終チェック
			return true;
		}
	}
	return false;
}