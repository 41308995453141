
export * from "./debounce";
export * from "./keys";
export * from "./throttle";
export * from "./eventType";
export * from "./ymd";
//export * from "./htmlComponent";
//export * from "./isHTMLElement";
//export * from "./omit";

/*
export const isHTMLElementNode = ( node: any ): node is HTMLElement =>{
	return node.nodeType === 1 ;
};
*/

/*
export const isTextNode = ( node: any )  =>{
	return node.nodeType === 3 ;
};
*/