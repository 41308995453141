import styled from 'styled-components';
/*
使い方
	<Button className={"icon-checkmark1"}>Save</Button>&nbsp;
	<Button className={"icon-cross"}>Cancel</Button>
*/

import React,{
	useContext,
	//useState,
	//useEffect,
	//useMemo,
	//useRef,
	//useReducer
	//Suspense,
} from 'react';

import * as types from "../../types";

import {ArticleContext} from "./index";



export const Move: React.FC<{ 
		entity: types.IPost|types.IPassage|types.IArticle;
		entityType: "post"|"passage"|"article";
		children?: React.ReactNode ;
	}> = ({ entity, entityType, children })=>{

	const { articleState, controller } = useContext( ArticleContext );
	const el = document.querySelector<HTMLDivElement>(`#${entityType}-${entity.id}`);
	

	return (
		<span
			className="icon-hand-paper-o move-drag"
			draggable="true"
			onDragStart={(e: React.DragEvent<HTMLDivElement>)=>{
				controller.moveDrag( e, entity, entityType, "start" );
				el && el.classList.add("dragging")
			}}
			onDragEnd={(e: React.DragEvent<HTMLDivElement>)=>{
				controller.moveDrag( e, entity, entityType, "end" );
				el && el.classList.remove("dragging")
			}}
			>
			{ children ? <span>{children}</span> : null }
		</span>
	)
}
Move.displayName = "Move";


export const Drop4Move: React.FC<{ 
		entity: types.IPost;
		entityType: "post";
		parentId?: never;
	}|{ 
		entity: null;
		entityType: "post";
		parentId: string; // 各々のPassage中、最上位に置きたい場合、どうしても prev-post は nullになる。そうなるとどの Passage の prev-post かわからなくなるので必要
	}|{ 
		entity: types.IPassage;
		entityType: "passage";
		parentId?: never;
	}|{ 
		entity: null;
		entityType: "passage";
		parentId?: never;
	}> = ({ entity, entityType, parentId  })=>{
	const here = `page#helper#Drop4Move`;

	const { articleState, controller } = useContext( ArticleContext );

	return (
		<div
			className="move-drop" 
			onDragOver={(e: React.DragEvent<HTMLDivElement>)=>{
				// DragOver を Preventしないと Dropが動作しない！
				e.preventDefault();
			}}
			onDragEnter={(e: React.DragEvent<HTMLDivElement>)=>{
				e.currentTarget.classList.add("dragover")
			}}
			onDragLeave={(e: React.DragEvent<HTMLDivElement>)=>{
				e.currentTarget.classList.remove("dragover")
			}}
			onDrop={(e: React.DragEvent<HTMLDivElement>)=>{
				console.log(`in ${here}#drop ,org entity[data: ${ e.dataTransfer.getData(`application/article-entity-${entityType}`) }] ,to entity[type: ${entityType}, parentId: ${parentId ? parentId: "x" }]===>`, entity);
				controller.moveDrop( e, articleState.article, entity, entityType, ( parentId ? parentId : null ) );
			}}
			/>
	);
}
Drop4Move.displayName = "Drop4Move";




