import React, { useEffect }  from 'react'
import { HashRouter , Route, Switch } from 'react-router-dom'

import * as page from '../pages/index';

import { Navi } from './Navi';


export const Router = ()=>{

	const here = "router#Router";
	console.time(`in ${here}`);

	useEffect( ()=>{
		console.debug(`in ${here}, mounted.`);
	}, [] );

	console.timeEnd(`in ${here}`);
	return (
		<HashRouter>
			<Navi />{/* Navi内部で<Link>を使っているのでRouter内部で使用しないければいけない  */} 
			<Switch>
				<Route exact path="/" component={ page.Home } />
				<Route exact path="/articles" component={ page.Articles } />
			</Switch>
		</HashRouter>
	);
}

