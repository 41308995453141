
export const common = `
	*[disabled], *[disabled="true"]{
		//color: #777 !important;
		opacity: .7;
	}

	*.positive {
		color: #8E9;
	}

	*.editing {
		color: #770;
	}

	*.updated {
		//color: rgba(255,255,0,.2);
		color: #DDDDBB;
	}

	*.right {
		text-align: right;
	}
`;