

export const dragdrop = `
	// dragエリア
	.move-drag {
		display: inline-block;
		&>span {
			font-family: "Oswald";
		}
		//padding: 2px 10px;
		color: rgba( 255,255,255,.6);
		background: none;
		border: none;
		//cursor: ns-resize;
		cursor: grab;
		border-radius: 3px;
		&:hover { 
			color: white;
			//background-color: rgba( 255, 255, 255, .2 );
		}
		&[disabled], &[disabled="true"] {
			//color: #777 ;
			&:hover { 
				background: none;
			}
		}
		
	}

	// dropエリア
	.move-drop {
		height: 10px;
		width: 100%;
		border-radius: 3px;

		// passage や post の下につく
		position: absolute;
		bottom: 0px;
		left: 0px;
		background: rgba( 255, 255, 255, .3 );

		&.dragover {
			background: white;
		}
	}

	.dragover {
		.move-drop {
			background: white;
		}
		.image-drop {
			background: rgba(255,255,255, .3 );
		}
	}
`;