import styled from 'styled-components';
import React,{
	//useContext,
	//useState,
	//useEffect,
	//useMemo,
	//useRef,
	//useReducer
	//Suspense,
} from 'react';

export const ColorSelected: React.FC<{
    colorValue: string|null;
    onClick: ()=>void;
}> = ({ 
    colorValue,
    onClick
})=>{
    return (
        <div
         className="color-selected"
         onClick={onClick}
         >
            {
                colorValue ? 
                    <div className="color-selected-cube" style={{ backgroundColor: colorValue }}/>
                    :
                    <div>NULL</div>
            }
        </div>
    );
};