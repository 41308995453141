



import * as types from "../../types";

import {
	ApiError,
	$AbortError,
	isApiOrAbortError,
	//IApiContext,
} from "../../api/useApi2";

//
export interface IArticleState {
	articles: types.IArticle[]|ApiError|$AbortError|null;
	purpose4single: "UNSELECTED"|"EXISTED"|"NEW";
	article: types.IArticle|ApiError|$AbortError|null;
	followers: types.IFollowers|ApiError|$AbortError|null;
	editor: {
		dragging4postId: string| null;
		dragging4passageId: string| null;
		dragging4menu4post: string| null;
		dragging4menu4passage: string| null;
	};
}

export const reducerInitialValue = {
	articles: null,
	purpose4single: "UNSELECTED",
	article: null,
	followers: null,
	editor: {
		dragging4postId: null,
		dragging4passageId: null,
		dragging4menu4post: null,
		dragging4menu4passage: null,
	},
} as const;

//
export type TArticleDispatch = { 
	type: "setArticles";
	articles: types.IArticle[]|ApiError|$AbortError|null;
}|{ 
	type: "setNull2Article4Unselected";
}|{
	type: "setArticle4Select";
	article: types.IArticle|ApiError|$AbortError|null;
}|{ 
	type: "setNull2Article4New";
}|{
	type: "replaceOneOfArticles";
	article: types.IArticle;
}|{
	type: "addOneOfArticles";
	article: types.IArticle;
}|{ 
	type: "setFollowers";
	followers: types.IFollowers|ApiError|$AbortError|null;
}|{
	type: "addPassage";
	passage: types.IPassage;
}|{
	type: "replaceOneOfRevision";
	post: types.IPost;
	revision: types.IPostRevision;
	image: types.IImage|null;
}|{
	type: "addPost";
	passage: types.IPassage;
	post: types.IPost;
	revision: types.IPostRevision;
	image: types.IImage|null;
}|{
	type: "drag&drop4Move";
	entityType: "post"|"passage"|"article";
	id: string|null;
}|{
	type: "drag&drop4Menu";
	entityType: "post"|"passage";
	menuType: string|null;
}|{
	type: "deletePost";
	postId: string;
}|{
	type: "deletePassage";
	passageId: string;
}|{
	type: "deleteArticle";
	articleId: string;
}



//
//
//
export const reducer: React.Reducer<
	IArticleState,
	TArticleDispatch
	> = ( state , action )=>{
	const here = `articles#index#reducer`;
	console.debug(`in ${here} , `, "action==>", action ,`reducer state=>`, state,);
	switch ( action.type ){
		case "setArticles":
			return { ...state, articles: action.articles };
		break;
		case "setNull2Article4Unselected":
			return { ...state, purpose4single: "UNSELECTED", article: null };
		break;
		case "setArticle4Select":
			return { ...state, purpose4single: "EXISTED", article: action.article };
		break;
		case "setNull2Article4New":
			return { ...state, purpose4single: "NEW", article: null };
		break;
		case "replaceOneOfArticles":
			// Articlesのリストで、1点を置き換える
			if ( state.articles === null || isApiOrAbortError(state.articles) ) return state;
			const index = state.articles.findIndex( a=>a.id===action.article.id );
			if ( index === -1 ) throw new Error("invalid!");
			state.articles.splice( index, 1, action.article )
			return { ...state, articles: state.articles.slice() };
		
		break;
		case "addOneOfArticles":
			// Articlesのリストに1点追加
			if ( state.articles === null || isApiOrAbortError(state.articles) ) return state;
			const newArray = state.articles.concat( action.article )
			return { ...state, articles: newArray };
		
		break;
		case "setFollowers":
			// リモートから取得した値を設定
			return { ...state, followers: action.followers };
		break;
		case "addPassage":
			if ( state.followers === null || isApiOrAbortError(state.followers) ) return state;
			state.followers.passages.push( action.passage );
			// posts$passageId を1個作成
			// state.followers.posts$passageId[ action.passage.id ]=[];
			return { ...state, 
					followers: { 
						...state.followers,
						passages: state.followers.passages.slice() ,
						posts$passageId: { ...state.followers.posts$passageId } ,
					}
				};
		break;
		case "replaceOneOfRevision":
			if ( state.followers === null || isApiOrAbortError(state.followers) ) return state;
			// revision を上書き
			state.followers.revision$postId[action.post.id] = Object.assign(
				{},
				state.followers.revision$postId[ action.post.id ],
				action.revision,
			);
			// image もあれば加える
			if ( action.image !== null ){
				state.followers.image$id = Object.assign(
					{},
					state.followers.image$id,
					{ [action.image.id]: action.image }, 
				);
			}
			//console.log("state.followers.revision$postId[action.post.id]==>", state.followers.revision$postId[action.post.id] );
			return { ...state, 
					followers: { 
						...state.followers,
						revision$postId: { ...state.followers.revision$postId } ,
						image$id: { ...state.followers.image$id }
					}
				};
		break;
		case "addPost":
			if ( state.followers === null || isApiOrAbortError(state.followers) ) return state;
			if ( !state.followers.posts$passageId[ action.passage.id ] ) state.followers.posts$passageId[ action.passage.id ] = [];
			state.followers.posts$passageId[ action.passage.id ].push( action.post );
			state.followers.revision$postId[ action.post.id ] = action.revision;
			// image もあれば加える
			if ( action.image !== null ){
				state.followers.image$id = Object.assign(
					{},
					state.followers.image$id,
					{ [action.image.id]: action.image }, 
				);
			}
			return { ...state, 
					followers: { 
						...state.followers,
						posts$passageId: { ...state.followers.posts$passageId } ,
						revision$postId: { ...state.followers.revision$postId } ,
						image$id: { ...state.followers.image$id }
					}
			};
		break;
		case "drag&drop4Move":
			return {
				...state,
				editor: {
					...state.editor,
					[`dragging4${action.entityType}Id`]: action.id,
				}
			}
		break;
		case "drag&drop4Menu":
			return {
				...state,
				editor: {
					...state.editor,
					[`dragging4menu4${action.entityType}`]: action.menuType,
				}
			}
		break;
		case "deletePost":
			if ( state.followers === null || isApiOrAbortError(state.followers) ) return state;
			{
				const followers = state.followers;
				// todo: post もマスタを作っておくべきだった、やはり全て サーバからは item$id で取得しておいて、ローカルでリレーションを作るべきかも。
				// todo: index が全く見つからない場合のエラーが検出できない
				// posts$passageId から対象の post だけを削除。 passageがわからないので全部見ないといけない
				Object.entries( followers.posts$passageId ).forEach( ([ passageId, posts ])=>{
					const index = posts.findIndex( p=>p.id===action.postId );
					if ( index !== -1 ) {
						followers.posts$passageId[passageId].splice( index, 1 );
					}
				});
				// revision$postId から 対象の revision を削除
				delete followers.revision$postId[ action.postId ];
				return { ...state, followers: { ...followers  }};
			}
		break;
		case "deletePassage":
			if ( state.followers === null || isApiOrAbortError(state.followers) ) return state;
			{
				const followers = state.followers;
				const posts = followers.posts$passageId[action.passageId];
				if ( posts ){
					// revision$postId から 対象の revision を削除
					posts.forEach( post=>{
						delete followers.revision$postId[ post.id ];
					});
				}
				
				// posts$passageId から対象の posts だけを削除。
				delete followers.posts$passageId[action.passageId];
				// 対象の passage を削除
				const index = followers.passages.findIndex( p=>p.id===action.passageId );
				if ( index === -1 ) throw new Error(`invalid!`);
				followers.passages.splice( index, 1 );

				return { ...state, followers: { ...followers  }};
			}
		break;
		case "deleteArticle":
			if ( state.articles === null || isApiOrAbortError(state.articles) ) return state;
			{	
				if ( state.article !== null && !isApiOrAbortError(state.article) ) {
					// articleが選択状態の場合
				}
				// 対象の article 削除
				const index = state.articles.findIndex( a=>a.id===action.articleId );
				if ( index === -1 ) throw new Error(`invalid!`);
				state.articles.splice( index, 1 );

				return { 
					...state, 
					articles: state.articles.slice(),
					purpose4single: "UNSELECTED", 
					article: null ,
					followers: null,
				};
			}
		break;
	}
};
