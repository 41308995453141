import React,{
	useContext,
	//useState,
	useEffect,
	//useMemo,
	//useRef,
	//Suspense,
} from 'react';

import moment from 'moment';

import {
	ApiError,
	$AbortError,
} from '../../api/useApi2';

//const api = Api.factory().api;

import * as types from "../../types";

// context
//import {AppContext} from "../../app";
//import {ReadyContext} from "../../ready/Ready";
import {ArticleContext} from "./index";
import styled from 'styled-components';


import {
	Loader,
	meta_el,
	Sign,

} from "../helper"

import {
	Ul,
	Li,
} from "./styled";

import {
	Move,
} from "./move";

//
//
//
export const Articles: React.FC<
	{ 
		articles: types.IArticle[]|null|ApiError|$AbortError, 
	}
> = ({ articles })=>{
	const here = "pages#Articles#Articles";

	if ( articles === null ) {
		return <Loader/>
	} else if ( articles instanceof ApiError ) {
		return <section>エラー</section>
	} else if ( articles instanceof $AbortError ) {
		return <section>キャンセルされた</section>
	}

	const { controller } = useContext( ArticleContext );
	
	useEffect( ()=>{
		console.debug(`in ${here}, mounted.`);
		return ()=>console.debug(`in ${here}, unmounted.`)
	}, [] );

	return articles.length === 0 ?
		( <section><Sign className="icon-info"/>記事がまだありません</section> )
		:
		(
			
			<Ul>
				{ 
					articles.map( article=>{
						return (
							<Li key={article.id} id={`article-${article.id}`} onClick={()=>controller.selectArticle(article)}>
								<div data-title>
									<span data-publish={article.publish ? "true":"false"}>{article.publish ? (<i className={"icon-view-show"}/>):<i className={"icon-view-hide"}/>}</span>
									{article.title}
									&nbsp;<Move entity={article} entityType={"article"}/>
								</div>
								<div data-meta >
									{/*
									<span data-date><span>記事日付:</span> { moment(article.displayDate).format("YY年MM月DD日") }</span>
									<span data-date><span>作成日時:</span> { moment(article.createdAt).format("YY年MM月DD日 HH:mm") }</span>
									*/}
									{ meta_el( "id", article.id ) }
									{ meta_el( "記事日付", moment(article.displayDate).format("YY/MM/DD") ) }
									{ meta_el( "created at", article.createdAt ) }
									{ meta_el( "updated at", article.updatedAt ) }
								</div>
							</Li>
						);
					})//articles.map
				}
			</Ul>
			
		);
}


