import React,{
	useContext,
	useState,
	useEffect,
	useMemo,
	//useRef,
	useReducer
	//Suspense,
} from 'react';

import moment from 'moment';

import * as types from "../../../../types";

// context
import {ArticleContext} from "../../index";
import {AppContext} from "../../../../app";

//import {ReadyContext} from "../../ready/Ready";
import styled from 'styled-components';




import {
	P1,
} from "../../styled";

import {
	Move,
	Drop4Move,
} from "../../move"

import {
	meta_el,
	//Button,
	Loader,
	Button,
	Droppable,
	Sign,
} from "../../../helper"


import {
	//getKeys,
	//debounce,
} from "../../../../util"



import {
	ApiError,
	$AbortError,
	isApiOrAbortError,
} from '../../../../api/useApi2';


import {
	parseText,
	toJsxElements,
	toMyElements,
	//lf2BrTag,
	//toNode,
} from "../../parseText2";

import {
	postTypes
} from "../../postTypes";


import {
	TUpdate
} from "./index";

import { Link } from 'react-router-dom';


export const PostDiv = styled.div`
`
export const RevisionBody = styled.div`
	
`


//
//	props.article が null の場合は新規作成とする
//
export const LinkPost: React.FC<{ 
		revision: types.IPostRevision;
		post: types.IPost;
		passage: types.IPassage;
		article: types.IArticle;
		update: TUpdate;
	}> = ({ revision, post, passage, article, update })=>{
	const here = "pages#Articles#Followers#Passage#Post#LinkPost";
	console.time(`in ${here}`);
	const { articleState, controller } = useContext( ArticleContext );

	const initObject$input = ( revision: types.IPostRevision )=>{
		return {
			linkUrl: 	revision ? revision.linkUrl : "",
			linkLabel:	revision ? revision.linkLabel : "",
		};
	};
	// INPUT
	const [ $input, set$Input ] = useState( ()=>initObject$input( revision ) );
	// 差分が出たか確認用
	const [ old, setOld ] = useState( revision );

	const isUpdated = {
		linkUrl: 	( old ? old.linkUrl : null ) !== $input.linkUrl,
		linkLabel: 	( old ? old.linkLabel : null ) !== $input.linkLabel,
	}

	useEffect( ()=>{
		console.debug(`in ${here}, mounted.`);
		return ()=>console.debug(`in ${here}, unmounted.`)
	}, [] );

	//
	const onCange = ( e: React.ChangeEvent<HTMLInputElement> )=>{
		const key = e.currentTarget.dataset.inputkey;
		const value = e.currentTarget.value;
		switch( key ){
			case "linkUrl":
				set$Input( x=>({ ...x, [key]: value }) );
			break;
			case "linkLabel":
				set$Input( x=>({ ...x, [key]: value }) );
			break;
		}
	}

	
	//
	const onBlur = ( e: React.FocusEvent<HTMLDivElement> )=>{
	}

	// save
	const save = ( e: React.FocusEvent<HTMLDivElement> )=>{
		update( {
			linkUrl: $input.linkUrl,
			linkLabel: $input.linkLabel,
		}, post, revision, postTypes.link ).then( res=>{
			if ( isApiOrAbortError(res) ){
				return;
			}
			setOld( res.revision )
		});
	}
	

	console.timeEnd(`in ${here}`);
	// article が nullの場合は 編集画面(新規作成)、articleに値がある場合は編集画面か表示画面
	return ( 
		<div
			id={`revision-${revision.id}`}
			className="revision-link-body"
			data-panedisabledtarget={true}
			>
			<input 
				data-inputkey={"linkUrl"}
				value={ $input.linkUrl ? $input.linkUrl : "" } 
				placeholder="https://www.example.com/path"  
				className={ isUpdated.linkUrl ? "updated" : "" }
				onChange={onCange}
				onBlur={onBlur}
				/>
			<input 
				data-inputkey={"linkLabel"}
				value={ $input.linkLabel ? $input.linkLabel : "" } 
				placeholder="リンクの文字（無い場合はURLそのまま）"  
				className={ isUpdated.linkLabel ? "updated" : "" }
				onChange={onCange}
				onBlur={onBlur}
				/>
			<div style={{marginTop:"10px"}}>
				{ $input.linkUrl ?
					( <><Sign className="icon-new-tab"/> 
						<a href={ $input.linkUrl } target={"_blank"} onClick={ (e) => { window.open( e.currentTarget.href ,'_blank') } }>
							{ $input.linkLabel ? $input.linkLabel : $input.linkUrl }
						</a>
					</>)
					:
					<><Sign className="icon-info"/>URLを入力するとリンクのプレビューを確認できます</>
				}
			</div>
			<div className="right">
				<Button 
					className={"icon-checkmark"}
					onClick={save}
					>&nbsp;Save</Button>&nbsp;
			</div>
		</div>
	);
};
LinkPost.displayName = "LinkPost"
