

export const throttle = ( callback:( ...x: any[] )=>any, duration: number )=>{
	//console.log("throttle set!")
	let tid: any = null;
	return function( ...args: any[] ){
		//console.log("throttle enter!", (new Date()).getTime() )
		if ( tid !== null ) {
			//console.log("throttle wait!")
			callback.apply( null, args );
			return;
		}
		tid = setTimeout( ()=>{
			//console.log("throttle go!")
			//callback.apply( null, args );
			tid = null;
		}, duration );
	};
}