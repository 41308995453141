
export const pageArticle = `
	#page-article {
		display: flex;
		background-color: black;

		#articles {
			width: 25%;
			height: 100vh;
			position: fixed;
			overflow: scroll;
		}
		#article-single {
			margin-left: 25%;
			width: calc( 75% - 50px );
			margin-right: 50px;
			height: 100%;
			padding-bottom: 50px;

			

			#article {

				padding-top:20px;
				margin-bottom:5px;
				//background-color: rgba( 225, 255, 235, .2 );
				//background-color: rgba( 255, 125, 35, .6 );
				background-color: rgba( 255, 255, 255, .1 );
				//background-image: linear-gradient( to bottom, rgba( 150, 230, 40, .15 ) 50% , rgba( 150, 230, 40, .3 ) );

				input {
					width: 100%;
				}
				
			}

			#followers {
				// dropエリアのため
				position: relative;



				.passage {
					// dropエリアのため
					position: relative;

					// Passage は上下にマージンがあった方がいい
					padding-top: 25px;
					padding-bottom: 25px;
					padding-left: 25px;
					padding-right: 25px;

					// 下に向かってドロップシャドウっぽく。
					// background: linear-gradient( to top,rgba( 0,0,0,.2 ) 0px,rgba( 0,0,0,.1 ) 4px,rgba( 0,0,0,0 ) 12px );
					// ちゃんとドロップシャドウに。
					box-shadow: 0px -2px 6px 0px rgba(0,0,0,0.3) inset;

					//border-top: 3px solid #000;
					margin-bottom: 3px;

					&:first-child {
						border-top: none;
					}
					
					//background-color: rgba( 225, 255, 235, .2 );
					background-color: rgba( 255, 255, 235, .1 );

					background-size: auto auto;
					//background-image: repeating-linear-gradient(45deg, transparent, transparent 2px, rgba(0, 0, 0, 0.08) 2px, rgba(0, 0, 0, 0.08) 4px );

					&: hover {
						//background-color: rgba( 225, 255, 235, .22 );
					}
					&.dragging {
						// ドラッグされた本人の色を変更
						//background-color: rgba( 0,0,0, .2 );
					}
				}
				.post {
					// dropエリアのため
					position: relative;
					// move-drop 用の pxを下にあける
					padding: 5px 0;
					margin: 5px 0;
					border-bottom: 1px solid #000;

					// TEXT
					.revision-text-body {
						line-height: 180%;
						font-size: 14pt;
						&:focus { 
							outline:0; // クリック時の青い枠線を消す
						}; 
						padding: 10px 10px ;
						//background-color: rgba(0,0,0, .1 );
						border-radius: 2px;
					}

					// LINK
					.revision-link-body {
						input {
							width: 100%;
						}
					}

					// IMAGE
					.revision-image-body {
						*[data-droppable]{
							display: block;
						}
						.image-drop {
							position: relative;
							text-align: center;
							border-radius: 3px;
							width: 100%;
							min-height: 100px;
							background-size: 10px 10px;
							background-image: radial-gradient( rgba(255,255,255, .1 ) 15%, rgba(255,255,255,0) 20%);

							img {
								    position: relative;
								    margin: auto;
								    max-width: 90%;
								    max-height: 200px;
							}
							p {
								position: absolute;
								margin: auto;
								top: 0; bottom: 0; left: 0; right: 0;
								height: 1em;
							}
						}
					}

					&: hover {
						//background-color: rgba(0,0,0, .07 );
						.revision-text-body {
							background-color: rgba(0,0,0, .1 );
						}
						.revision-link-body input {
							//background-color: rgba(0,0,0, .1 );
						}
					}

				}


			}
		}

		#article-menu {
			width: 50px;
			height: 100vh;
			position: fixed;
			right: 0;
			top: 0;
			bottom: 0;
			margin: auto auto;

			#article-menu-inner {
				width: 100%;
				position: absolute;
				top: 0; 
				bottom: 0;
				margin: auto 0;

				height: 400px;

				font-size: 15pt;
				text-align: center;

				font-family: 'Open Sans Condensed';
				//font-size: 5pt;

				*[data-drag-label],*[data-drop-label],*[data-toggle] {
					//display: block;
				}
			}
		}
	}
`;