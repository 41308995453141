import React,{
	useContext,
	useState,
	useEffect,
	useMemo,
	//useRef,
	useReducer
	//Suspense,
} from 'react';

import moment from 'moment';

import * as types from "../../../../types";

// context
import {ArticleContext} from "../../index";
import {AppContext} from "../../../../app";

//import {ReadyContext} from "../../ready/Ready";
import styled from 'styled-components';




import {
	P1,
} from "../../styled";

import {
	Move,
	Drop4Move,
} from "../../move"

import {
	meta_el,
	//Button,
	Loader,
	Button,
	Droppable,
} from "../../../helper"

// POST
import {
	TextPost
} from "./TextPost";
import {
	ImagePost
} from "./ImagePost";
import {
	LinkPost
} from "./LinkPost";

import {
	//getKeys,
	//debounce,
} from "../../../../util"



import {
	ApiError,
	$AbortError,
	isApiOrAbortError,
} from '../../../../api/useApi2';


import {
	parseText,
	toJsxElements,
	toMyElements,
	//lf2BrTag,
	//toNode,
} from "../../parseText2";

import {
	postTypes
} from "../../postTypes";

interface Window2 {
	clipboardData: any
}


export const PostDiv = styled.div`
`
export const RevisionBody = styled.div`
`

export type TUpdate = ( 
		data: { text: string }
				|{ linkUrl: string|null, linkLabel: string|null }
				|{ imageId: string|null }
		,
		post: types.IPost, 
		revision: types.IPostRevision4Request, 
		postType: keyof typeof postTypes|"style" 
	)=> Promise<{
		post: types.IPost,
		revision: types.IPostRevision,
	}|ApiError|$AbortError>;


//
//	props.article が null の場合は新規作成とする
//
export const Post: React.FC<{ 
		revision: types.IPostRevision;
		post: types.IPost;
		passage: types.IPassage;
		article: types.IArticle;
		image: types.IImage | null;
	}> = ({ revision, post, passage, article, image })=>{
	const here = "pages#Articles#Followers#Passage#Post";
	console.time(`in ${here}`);
	const { articleState, controller } = useContext( ArticleContext );

	useEffect( ()=>{
		console.debug(`in ${here}, mounted.`);
		return ()=>console.debug(`in ${here}, unmounted.`)
	}, [] );


	//
	//
	const update: TUpdate = ( data, post, revision, postType )=>{

		if ( postType == postTypes.text ){
			// textの場合並列に処理
			return controller.updateRevision( 
				post, 
				Object.assign(
					revision,
					data,
				),
				false,
			);
		} else if ( postType == "style" || postType == postTypes.link || postType == postTypes.image ){
			// text以外の場合は直列に処理
			return controller.updateRevision( 
				post, 
				Object.assign(
					revision,
					data,
				),
				true,
			);
		} else {
			throw new Error(`invalid post type[${postType}]`);
		}
	}

	console.timeEnd(`in ${here}`);
	// article が nullの場合は 編集画面(新規作成)、articleに値がある場合は編集画面か表示画面
	return ( 
		<div 
			data-post 
			className="post"
			id={`post-${post.id}`}
			>
			{/* data-drag-image がつくものが drag時にゴーストとして使われる */}
			<section data-drag-image>
				{
					revision.type == postTypes.text ?
						<TextPost article={article} passage={passage} post={post} revision={revision} update={update} />
						:
						revision.type == postTypes.image ?
							<ImagePost article={article} passage={passage} post={post} revision={revision} image={image} update={update} />
							:
							revision.type == postTypes.link ?
								<LinkPost article={article} passage={passage} post={post} revision={revision} update={update} />
								:
								null
				}
			</section>

			<div className="meta">
				{ meta_el( "post id", post.id ) }
				{ meta_el( "created at", post.createdAt ) }
				{ meta_el( "updated at", post.updatedAt ) }
				{ meta_el( "order", post.order ) }
				{/*</div><div>*/}
				{ meta_el( "revision id", revision.id ) }
				{ meta_el( "created at", revision.createdAt ) }
				{ meta_el( "updated at", revision.updatedAt ) }
				<Move entity={post} entityType={"post"}/>
				
			</div>
			{
				/* 自分以外のドロップゾーンを表示 */
				( articleState.editor.dragging4postId && articleState.editor.dragging4postId !== post.id ) ?
					<Drop4Move entity={post} entityType={"post"}  />
					:
					null
			}
			{
				articleState.editor.dragging4menu4post ?
					<Droppable 
						onDrop={(e)=>controller.dropMenu( e, { entity: post, entityType: "post", passage: passage, article: article } )}
						shouldAcceptType={ types=>types.some( t=>RegExp("^application/article-menu-").test(t) )}
						><div className="move-drop" /></Droppable>
					:
					null
			}
		</div>
	);
};
Post.displayName = "Post"
