

export const getNewValue = ( value: any )=>{
	if ( Array.isArray(value) ){
		return value.slice();
	} else if ( typeof value === "object" && value !== null ){
		return Object.assign( {}, value );
	} else {
		return value;
	}
}

export const hasChanged = ( current: any, old: any )=>{
	if ( !isNaN( current ) && !isNaN( old ) ){
		// 数値の場合は新しい物も数値に置き換えてみる
		return parseFloat( current ) !== old ;
	}
	return current !== old ;
}