
import axios, { 
	Method, 
	ResponseType,
	AxiosResponse,
}  from "axios";

import { 
	$AbortError,
	ApiError,
} from "./useApi2";

// const
import { API_BASE_URL } from './const';


// type
export interface IParameter$in {
	query?: object;
	//path?: object;
	body?: object;
	headers?: object;
	//cookie?: object;
}

interface IRequestParameter {
	method: Method; 
	url: string;
	baseURL: string;
	params: IParameter$in;
	data: {[key:string]: any };
	responseType: 'json';
	responseEncoding: 'utf8';
	maxContentLength: number;
	validateStatus: (status:number)=>boolean ;
	headers: {
		Authorization: string|null;
	}
}

const isRequester = ( requester: unknown )=>{
	return  requester 
};




/*
const isParamIn = ( paramIn: string ): paramIn is "body"|"query"|"path"|"cookie"|"header" =>{
	return  ["body","query","path","cookie","header"].includes(paramIn);
};
*/

export const request = <T>( method: Method, path: string, param$in: IParameter$in )=>{
	const here = `api#request`;
	const param = {
		method: method, 
		url: path,
		baseURL: API_BASE_URL,
		params: param$in.query || {},
		data: param$in.body || {},
		responseType: 'json' as ResponseType,
		responseEncoding: 'utf8',
		maxContentLength: 2000,
		validateStatus: (status:number)=>{
			return status >= 200 && status < 300; // default
		},
		headers: {
			'Authorization': sessionStorage.getItem("token")
		}
	};

	return axios.request<T>( param ).then( res=>{
		return res;
	}).catch( res=>{

		console.error(`エラー：${here} がキャッチ,  response==>`, res );
		if ( res instanceof Error ) {
			return Promise.reject( new ApiError( res.message, res ) );
		} else if ( res.body && res.body.message ) {
			return Promise.reject( new ApiError( res.body.message, res ) );
		} else {
			return Promise.reject( new ApiError( `????エラー`, res ) );
		}

		//return res ; // Promise { <rejected> reject value }
	});
};















