import styled from 'styled-components';
import React,{
	//useContext,
	useState,
	//useEffect,
	//useMemo,
	//useRef,
	//useReducer
	//Suspense,
} from 'react';


import * as types from "../../../types";

import {
	//Sign,
} from "../index";



import {
	//colors
} from "../../../util/colors"

import {
	Button
} from '../button';

import {
	//getEntries,
	myEntries,
} from '../../../util';


import {
	//getNewValue
	hasChanged,
} from "./helper";

import {
	IStylingState,
	IRawItems,
	TStylingDispatch,
} from "./types";


export const StylingPanelRaw: React.FC<{
	typeKey: keyof IStylingState;
	sectionKey: keyof IStylingState[keyof IStylingState];
	
	styleItems: IRawItems;
	stylingDispatch: React.Dispatch<TStylingDispatch>;
	oldStyleItems: IRawItems;
}> = ({ 
	typeKey, 
	sectionKey, 
	styleItems,
	stylingDispatch,
	oldStyleItems,
})=>{

	return (
		<>
			<table cellPadding="0" cellSpacing="0" >
				<tbody>
					{
						myEntries(styleItems).map( style=>{

							return (
								<tr key={ typeKey+"_"+sectionKey+"_"+style.key }>
									<th>{style.key}</th>
									<td>
										<input
											title={`old: ${ oldStyleItems[style.key] }`}
											value={style.value}
											onChange={ e=>{
												const value = e.currentTarget.value;
												stylingDispatch({
													type: "setRaw",
													sectionKey,
													property: style.key,
													value: value,
												})
											}}/// onChange={ e=>{
											className={ 
												hasChanged( styleItems[style.key] ,oldStyleItems[style.key] ) ?
													"changed"
													:
													""
											}
										/>
									</td>
								</tr>
							);
						})// getEntries(styleItems).map( ([ style.key, styleValue ])=>{
					}
				</tbody>
			</table>
			<div className="right" style={{ margin: "10px 5px 0 0"}}>
				<Button className={"icon-plus"} onClick={ ()=>{
						const property = prompt("propertyを入力してください");
						
						if ( property === null ) return ;
						if ( ! ( /^([a-z\-]){1,}$/.test(property || "") ) ){
							alert("propertyが正しくありません");
							return ;
						}
						//if ( !isRawObject(styleRaw) ) return ;
						if ( typeof sectionKey == "symbol" ) return ;

						stylingDispatch({
							type: "addRaw",
							sectionKey,
							property
						})
					}} title="Style行追加" >
						&nbsp;Style
				</Button>
			</div>
		</>
	);

}
StylingPanelRaw.displayName = "StylingPanelRaw";






