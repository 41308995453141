/*

const descriptor1 = { 
	onDuplicate: ( $abort: symbol )=>$abort,
	onUnmounted: ( $abort: symbol )=>$abort,
	onComplete: ( job: IApiJob )=>{},
	onError: ( job: IApiJob )=>{},
	onAbort: ( job: IApiJob )=>{},
}

// 覧取得、新規 article作成、新規 post作成
const api1 = useApi2(descriptor1);

api1.get<{ articles: types.IArticle[]}>( "/api/article/all", {}, { label: `記事一覧の取得` } ).then( res=>{
	return res.data.articles ;
}).catch( err=>{
	if ( isApiOrAbortError(err) ) return err;
	console.error(err); throw new Error(`invalid`);
});


*/


import React, { 
	useReducer,
	useEffect,
	useRef,
	useCallback,
	//useState, 
	useMemo 
} from 'react';

import moment from 'moment';

import axios, { 
	Method, 
	AxiosResponse, 
	ResponseType 
}  from "axios";

import { 
	request,
	IParameter$in,
} from "./request";

//
//	APIのエラー
//
export class ApiError extends Error { 

	private _debug_org: any;
	constructor( message: string, org: any ) {
		super(message);
		this._debug_org = org;
	}
	get name() { return this.constructor.name }
}

//
// キャンセル時のエラー
//
export class $AbortError extends Error {};
$AbortError.prototype.name = "$AbortError";


// const
import { API_BASE_URL } from './const';

// キャンセルのイベントを発火する
import AbortController from "abort-controller";

import {
	//getKeys
} from "../util/";

// useApiファイルから importできるようにしてるだけ。 
import { isApiOrAbortError } from "./helper";
export { isApiOrAbortError };


// Axiosのレスポンスを待つプロミス
type TResponsePromise = Promise<AxiosResponse>;

// JOB 
export interface IApiJob {
	id: number,
	controller: AbortController;
	promise: TResponsePromise|null;		// デバッグ目的
	response: AxiosResponse|null|ApiError|$AbortError ;
	option: IOption;					// そのAPIにラベルをつけて失敗時にSnackBarで表示したりするためのもの
	method: Method;						// デバッグ目的
	path: string;						// デバッグ目的
	param$in: IParameter$in;			// デバッグ目的
}

// JOBをIDをキーにして取得する
// type TJobs$id = { [id:number]: IApiJob };

const $abort = Symbol("abort");

export type TApiDescriptor = {
	// すでに走っている処理があるのに、新たな処理を走らせようとしたとき（ abortシンボルを返せばABORTされる ）
	onDuplicate?: ( $abort: symbol, newJob: IApiJob, existedJobs:IApiJob[] )=>void|typeof $abort ;
	// すでに走っている処理があるのに、アンマウントされるとき（ abortシンボルを返せばABORTされる ）
	onUnmounted?: ( $abort: symbol, jobs:IApiJob[] )=>void|typeof $abort ;
	// あるジョブがエラー発生時
	onError?: ( job: IApiJob )=>void;
	// あるジョブがキャンセル発生時
	onAbort?: ( job: IApiJob )=>void;
	// あるジョブが完了時
	onComplete?: ( job: IApiJob )=>void;
	// 走っている処理があるとき
	//onProcess?: ( jobs: IApiJob[] )=>void;
	// 走っている処理が1つも無くなった時
	//onCompleteAll?: ()=>void;
}


interface IState {
	descriptor: TApiDescriptor ;
	jobs: IApiJob[];
}

interface IOption {
	[x:string]: unknown
}

//type TRequest = {[ K in Method ]: ( path: string, param$in: IParameter$in, option?: IOption )=>TResponsePromise };
type TRequest = {[ K in Method ]: <T>( path: string, param$in: IParameter$in, option?: IOption )=>Promise<AxiosResponse<T>> }
// 
/*
export type TApiReturn = {
	request: TRequest ,
	state: IState,
}
*/

export type TApiReturn = ReturnType<typeof useApi2>;



//
//	アンマウントされた場合に ABORTされる
//
export const useApi2 = ( descriptor: TApiDescriptor )=>{
	
	const here = "useApi2";
	console.time(`in ${here}`);

	const [ state, dispatch ] = useReducer( reducer, undefined, ()=>({
		descriptor,
		jobs: [],
	}));

	console.debug( `in ${here}#state.jobs`, state.jobs );

	// JOB ID
	const lastJobId = useRef(0);

	useEffect( () =>{
		console.debug( `in ${here}#useEffect. mounted` );
		return () =>{
			const here = "api#useApi2#useEffect#un-mount";
			console.debug(`in ${here},`);
		}
	},[]);

	useEffect( () =>{
		// アンマウント時の挙動
		return () =>{
			const here = "api#useApi2#useEffect#onUnmount";
			console.debug(`in ${here},`);
			// コールバックを実行。$abortを返した時は全てABORT
			if ( descriptor.onUnmounted ){
				if ( descriptor.onUnmounted( $abort, state.jobs )  === $abort ){
					state.jobs.forEach( job=>{
						console.debug(`in ${here}, %cAbort because unmount, job==>`, 'color: orange' , job );
						job.controller.abort();
					});
				}
			}
		}
	},[]);

	// 関数内部では、中断のためのコントローラ、実際のリクエストPROMISEと中断のPROMISE いずれかを受け取る PROMISEを作成して、 dispatch で登録する。
	// また、並列実行されている者がないかチェックして、あれば処理する

	const requesters = Array<Method>("post","get","patch","delete").reduce( ( acc, method )=>{
		
		acc[method] = <T>( path: string, param$in: IParameter$in, option: IOption = {} )=>{

			const job: IApiJob = {
				id: 			lastJobId.current,
				controller: 	new AbortController(),
				option: 		option,
				response:		null,
				// ↓これ以降はデバッグ目的で保存
				promise: 		null, 
				method, 
				path,
				param$in,
				
			};
			
			const promiseFactory = ( job: IApiJob )=>{
				console.debug( `in ${here}#request start==> job[ id: ${job.id}, method: ${job.method}, path: ${job.path} ) ]` );
				/// 本来のリクエストと、ABORTのプロミスを実行。先にABORTされたら$AbortErrorを返す
				return Promise.race([ 
					// race 1
					request<T>( method, path, param$in ),
					// race 2
					new Promise<never>( ( resolve, reject )=>{
						job.controller.signal.addEventListener("abort", () => {
							console.debug(`${here}, Abort job==>`, job );
							reject( new $AbortError(`abort! jobId:[${job.id}]`) );
						});
					})
				// return Promise.race([ 
				]).then( res=>{ 
					console.debug( `in ${here}#request done==> job[ id: ${job.id}, method: ${job.method}, path: ${job.path} ) ]` );
					job.response = res;
					if ( descriptor.onComplete ){
						descriptor.onComplete(job);
					}
					return res;
				}).catch( res=>{
					if ( isApiOrAbortError( res ) ){
						job.response = res;
						// 中断された場合（現時点では結果は同じ）
						if ( res instanceof $AbortError ){
							console.debug( `in ${here}#request cancel==> job[ id: ${job.id}, method: ${job.method}, path: ${job.path} ) ]` );
							console.warn(`中断：${here} がキャッチ, job==>`, job );
							if ( descriptor.onAbort ) descriptor.onAbort(job);
							// 結局ここで rejectするとanyになるんだよな。。。
							return Promise.reject(res);
						}
						console.debug( `in ${here}#request fail==> job[ id: ${job.id}, method: ${job.method}, path: ${job.path} ) ]` );
						console.debug(`エラー：${here} がキャッチ, job==>`, job, `response==>`, res  );
						if ( descriptor.onError ) descriptor.onError(job);
						// 結局ここで rejectするとanyになるんだよな。。。
						return Promise.reject(res);
					} else if ( res instanceof Error ){
						// もし res が ApiErrror でも AbortErrorでもなかったら想定していない普通のエラー
						throw res;
					} else {
						// Errorですらなかったらなんなの。
						console.error( "Errorではない想定していないres===>", res );
						throw new Error("Errorではない想定していないresが返却された");
					}
				}).finally( ()=>{
					// 終わったJOBを削除
					dispatch({ type: "destroy", jobId: job.id });
				});
			};

			// // Promise内部でjobを参照できるように（デバッグ目的）渡すため .promise のみあとで作成。jobを束縛する。
			job.promise =  promiseFactory( job );
			// JOBを登録
			dispatch({ type: "create", job });
			// すでに何か別の処理が実行されていないか、チェック。（Abortするなど）
			dispatch({ type: "checkDuplicate", job });
			lastJobId.current++;

			return job.promise;
		};// acc[method] = ( path: string, param$in: IParameter$in, option: IOption )=>{

		return acc;
	}, {} as TRequest );


	console.timeEnd(`in ${here}`);
	return Object.assign( 
		requesters ,
		{
			descriptor: descriptor,
			jobs: state.jobs,
			isBusy: state.jobs.length > 0,
		}
	);
};


//
//
//
const reducer: React.Reducer<
	IState,
	{ 
		type: 	"create";
		job:	IApiJob;
	} | {
		type: 	"checkDuplicate";
		job:	IApiJob;
	} | { 
		type: 	"destroy";
		jobId:	number;
	}
	> = ( state , action )=>{
	const here = `api#useApi2#reducer`;
	console.debug(`in ${here} , `, "action=x=>", action ,`reducer state=>`, state,);
	//
	switch (action.type){
		case "create":
			return { ...state, jobs: state.jobs.concat(action.job) };
		break;
		case "checkDuplicate":
			// 重複実行された場合
			if ( state.descriptor.onDuplicate ){
				// すでに存在する（自分以外）JOBを抽出
				const existedJobs = state.jobs.filter( job=>job.id !== action.job.id );
				// すでに存在しているJOBがあった場合、コールバック実行。$abort を返したら今作ったやつはABORT
				if ( existedJobs.length > 0 ){
					if ( state.descriptor.onDuplicate( $abort, action.job, existedJobs ) === $abort ){
						action.job.controller.abort();
					}
				}
			}
			// 特に変化させずに終了
			return state;
		break;
		case "destroy":
			//
			const job = state.jobs.find( job=>job.id===action.jobId );
			if ( job !== undefined ){
				//delete job.controller;
				//delete job.option;
				//delete job.param$in;
				//delete job.promise;
				//delete job.response;
			}
			// 指定したJOBを削除（指定したJOB以外を残した新しいJOB配列を作る）
			const newJobs = state.jobs.filter( job=>job.id!==action.jobId );
			return { ...state, jobs: newJobs };
		break;
		default:
			throw new Error(`invalid action type.`);
	}
};



/*
	// AbortController使い方
	const controller = new AbortController();
	const signal = controller.signal;
	signal.addEventListener("abort", () => {
	    throw new $AbortError(`abort!`);
	});
	controller.abort();
*/