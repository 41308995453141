
import { 
	useEffect, 
	//useState,
	useReducer,
} from 'react';


export type TPane = ReturnType<typeof usePane>;

interface IPaneState$id {
	[key:string]: IPaneState;
}
export interface IPaneState {
	el: HTMLDivElement | null;
	isOpen: boolean;
	isFocus: boolean;
	setOpen: React.Dispatch< React.SetStateAction<boolean> >;
	setFocus: React.Dispatch< React.SetStateAction<boolean> >;
	onOpen: ()=>void;
	onClose: ()=>void;
	onEnter: ()=>void;
	onLeave: ()=>void;
	isDisabled: boolean;
	setDisabled: React.Dispatch< React.SetStateAction<boolean> >;
}

interface IPaneDispatchAction {
	type: string;
	id?: string;
	paneState?: IPaneState;

}


//
// HTML NODEを再帰的に辿って、INPUTやBUTTONを使用不能（可能）にする。
//
const recurciveSetDisabledNodes =( el: HTMLElement, bool: boolean )=>{
	if ( el.hasChildNodes() ){
		el.childNodes.forEach( child=>{
			// 子要素が対象の種類だったら 使用不能か可能にする。
			if ( 
				child instanceof HTMLInputElement || 
				child instanceof HTMLTextAreaElement || 
				child instanceof HTMLButtonElement ||
				child instanceof HTMLElement && child.dataset.panedisabledtarget === "true"
				){

				if ( bool ) {
					child.setAttribute( `disabled`,  "true" );
				} else {
					child.removeAttribute( `disabled` );
				}
			}
			// 子要素がHTMLだったら再帰
			if ( child instanceof HTMLElement ){
				recurciveSetDisabledNodes( child, bool );
			}
		});
	}
};


//
//	メイン
//
export const usePane = ()=>{

	const here = "pane#usePane";
	console.time(`in ${here}`);

	const [ paneState$id, dispatch ] = useReducer( reducer, undefined, ()=>({}) );

	useEffect( ()=>{
		console.debug(`in ${here}, mounted.`);
		return ()=>console.debug(`in ${here}, unmounted.`)
	}, [] );

	//const accept = ( paneState: IPaneState )=>{
	//	dispatch({ type: "accept", paneState });
	//};

	console.timeEnd(`in ${here}`);
	return {
		dispatch,
		paneState$id,
		//accept,
	}
};

//
//	Reducer
//
const reducer: React.Reducer< IPaneState$id, IPaneDispatchAction > = ( paneState$id , action )=>{
	const here = `usePane#reducer#${action.type}`;
	//console.debug(`in ${here}, action==>`, action );

	// idが必須
	if ( action.id === undefined ) throw new Error(`in ${here}, id is required.`);
	const id = action.id;
	//console.debug(`in ${here}, paneState==>`, paneState$id[id] ? paneState$id[id] : "※未作成" );

	// 面倒なのでまとめた。helper.focus( id ) とか、idだけ指定すればいいだけにした。
	const helper = reducerHelper( paneState$id );

	switch ( action.type ) {
		case "accept":
			if ( !action.paneState ) throw new Error(`invalid action.paneState`);
			return { ...paneState$id, [id]: action.paneState };
		case "destroy":
			delete paneState$id[id];
			return { ...paneState$id };
		case "focus":
			if ( paneState$id[ id ].isFocus ){
				return paneState$id;
			}
			helper.focus( id );
			return paneState$id;
		case "open":
			if ( paneState$id[ id ].isOpen ){
				return paneState$id;
			}
			helper.open( id );
			return paneState$id;
		case "close":
			if ( !paneState$id[ id ].isOpen ){
				return paneState$id;
			}
			helper.close( id );
			return paneState$id;
		case "toggle":
			paneState$id[ id ].isOpen ? helper.close(id) : helper.open(id);
			return paneState$id;
		case "onOpen":
			paneState$id[ id ].onOpen();
			return paneState$id;
		case "onClose":
			paneState$id[ id ].onClose();
			return paneState$id;
		case "onEnter":
			paneState$id[ id ].onEnter();
			return paneState$id;
		case "onLeave":
			paneState$id[ id ].onLeave();
			return paneState$id;
		case "":
			return paneState$id;
		case "beDisabled":
		{
			const el = paneState$id[id] && paneState$id[id].el ;
			if ( !el ) {
				/*
				console.error("paneState$id[id]===>",paneState$id[id]);
				console.error("el===>",el);
				console.error("id===>",id);
				throw new Error(`element is empty`);
				*/
				return paneState$id;
			}
			recurciveSetDisabledNodes( el, true );
			paneState$id[ id ].setDisabled(true);
			return paneState$id;
		}
		case "beEnabled":
		{
			const el = paneState$id[id] && paneState$id[id].el ;
			if ( !el ) {
				/*console.error("paneState$id[id]===>",paneState$id[id]);
				console.error("el===>",el);
				console.error("id===>",id);
				throw new Error(`element is empty`);*/
				return paneState$id;
			}
			recurciveSetDisabledNodes( el, false );
			paneState$id[ id ].setDisabled(false);
			return paneState$id;
		}
		default:
			throw new Error(`in ${here}, invalid action.type. [${action.type}]`);
	}

	//return paneState$id;
}


const reducerHelper = ( paneState$id: IPaneState$id )=>{

	//
	//
	const focus = (id: string )=>{
		paneState$id[ id ].setFocus( true );
		// 目的以外の focus を 偽にする
		Object.keys( paneState$id )
			.filter( otherId=> id!==otherId )
			.forEach( otherId=>paneState$id[ otherId ].setFocus( false ) );
	};

	//
	//
	const open = (id: string )=>{
		paneState$id[ id ].setOpen( true );
		focus( id );
	};

	//
	//
	const close = (id: string )=>{
		paneState$id[ id ].setOpen( false );
	}

	return {
		focus,
		open,
		close,
	}

};









