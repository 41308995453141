

//import {useMemo} from 'react';
//import { useFrameClient } from '../Frame/useFrameClient';

import {createGlobalStyle} from 'styled-components';

import { native } from "./native"; 
import { common } from "./common";
import { pageArticle } from "./page-article";
import { dragdrop } from "./dragdrop";
import { meta } from "./meta";
import { styling } from "./styling";
import { reactTabs } from "./reactTabs" 

export const GlobalStyle = createGlobalStyle`
	
	${ native }

	${ common }

	${ pageArticle }

	${ meta }

	${ dragdrop }

	${ styling }

	*[data-sign] {
		display: inline-block;
		padding: 5px 5px;
	}

	*[data-toggle] {
		display: inline-block;
		color: rgba( 255,255,255,.4);
		border: none;
		cursor: pointer;
		&:hover { 
			color: rgba( 255,255,255,.6);
			//background-color: rgba( 255, 255, 255, .2 );
		}
		&[disabled], &[disabled="true"] {
			&:hover { 
				background: none;
			}
		}
		&[data-toggle-value="on"]{
			color: rgba( 255,255,255, 1 );
		}
		&[data-toggle-value="off"]{
			//
		}
	}


	.changed {
		color: yellow;
	}



	


	.modal-colorpallet {
		position: fixed;
		width: 30vw;
		min-width: 400px;
		top: 0;
		right: 0;
		z-index: 2;
		height: 100vh;
		overflow: scroll;

		.colorPallet-color {
			display: inline-block;
			width: 50px;
			height: 50px;
			cursor: pointer;
			transition-duration: .1s;
			transform: scale(.95,.95);
			position: relative;
		
			&:hover {
				transform: scale(.90,.90);
				box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.2) inset;
			}
		}
		.colorPallet-color-current:after {
			conent: "current"
		}
	}

	.modal-gallery {
		position: fixed;
		width: 30vw;
		min-width: 400px;
		top: 0;
		right: 0;
		z-index: 2;
		height: 100vh;
		overflow: scroll;

		.gallery-thumbnail {
			width: 200px;
			height: 200px;
			overflow: hidden;
			position: relative;
			display: inline-block;
			cursor: pointer;
			img {
				position: absolute;
				left: -1000px;
				right: -1000px;
				top: -1000px;
				bottom: -1000px;
				margin: auto;
			}
		}

		.gallery-thumbnail-current {
			//border: 2px solid red;
		}
	}

	${reactTabs}
`;








