import React,{
	useContext,
	useState,
	useEffect,
	useMemo,
	useRef,
	//Suspense,
} from 'react';

import moment from 'moment';

import * as types from "../../../types";

// context
import {ArticleContext} from "../index";
//import {AppContext} from "../../app";
//import {ReadyContext} from "../../ready/Ready";
import styled from 'styled-components';

import {
	P1,
	P2,
} from "../styled";

import {
	meta_el,
	Button,
	Loader,
	Sign,
} from "../../helper"


import {
	ApiError,
	$AbortError,
	isApiOrAbortError,
} from '../../../api/useApi2';

import {
	//getKeys,
	//debounce,
	isChangeEvent,
	isMouseEvent,
	isYmd,
} from "../../../util"

//
//	props.article が null の場合は新規作成とする
//
export const Article: React.FC<{ 
		article: types.IArticle|null|ApiError|$AbortError;
		purpose: "UNSELECTED"|"EXISTED"|"NEW";
	}> = ({ article, purpose })=>{
	const here = "pages#Articles#Article";
	console.time(`in ${here}`);

	if ( purpose === "UNSELECTED" ) {
		return <section><Sign className="icon-info"/>記事を指定してください</section>
	} else if ( purpose === "EXISTED" ) {
		if ( article === null ) {
			return <Loader/>
		} else if ( article instanceof ApiError ) {
			return <section>エラー</section>
		} else if ( article instanceof $AbortError ) {
			return <section>キャンセルされた</section>
		}
	}

	if ( isApiOrAbortError( article ) ){
		throw new Error("invalid"); // type対策。IArticle か null に絞る
	}

	//
	const { controller } = useContext( ArticleContext );
	// 表示モード、編集モード切り替え
	//const [ editable, setEditable ] = useState( false ); 
	// INPUT初期化
	const initObject$input = ( article: types.IArticle|null )=>{
		return {
			title: 			article ? article.title : "",
			displayDate: 	article ? moment(article.displayDate).format("YYYY/MM/DD") : moment().format("YYYY/MM/DD"),
			publish: 		article ? ( article.publish ? true : false ) : true,
		};
	};
	// INPUT
	const [ $input, set$Input ] = useState( ()=>initObject$input( article ) );
	// 差分が出たか確認用
	const [ old, setOld ] = useState( article );

	const isUpdated = {
		title: 			( old ? old.title : null ) !== $input.title,
		displayDate: 	( old ? moment(old.displayDate).format("YYYY/MM/DD") : null ) !== moment($input.displayDate).format("YYYY/MM/DD"),
		publish: 		( old ? !!old.publish : null ) !== $input.publish,
	}

	//	新規作成または更新する
	const save = ()=>{
		const _here = `${here}#save`;
		console.log( `${_here}, $input===>`, $input )
		if ( article === null ){
			// 新規作成
			controller.createArticle( $input );
		} else {
			// 更新
			controller.updateArticle( article, $input );
		}
	};

	//
	const change = ( e: React.ChangeEvent<HTMLInputElement>|React.MouseEvent<HTMLButtonElement> )=>{
		const key = e.currentTarget.dataset.inputkey;

		// set$input の中で e.currentTarget は null になるのでここで取得しておく・・・
		const value = e.currentTarget.value;

		try{
		switch( key ){
			case "title":
				if ( !isChangeEvent(e) ) throw new Error("invalid Event Type");
				set$Input( x=>({ ...x, [key]: value }) );
			break;
			case "displayDate":
				if ( !isChangeEvent(e) ) throw new Error("invalid Event Type");
				set$Input( x=>({ ...x, [key]: value }) );
			break;
			case "publish":
				if ( !isMouseEvent(e) ) throw new Error("invalid Event Type");
				console.log( "pubish", $input[key] );
				set$Input( x=>({ ...x, [key]: x[key] == false ? true : false }) );
			break;
		}
		}catch(e){
			throw e;
		}
	}

	useEffect( ()=>{
		console.debug(`in ${here}, mounted.`);
		return ()=>console.debug(`in ${here}, unmounted.`)
	}, [] );

	// articleが変わった時に値があればReadOnlyにする
	useEffect( ()=>{
		//setEditable( article === null ? true : false );
		setOld( article );
		set$Input( initObject$input(article) );
	}, [article] );


	console.timeEnd(`in ${here}`);
	return (
		<>
		<section>
			<P1>
				<P2>
					<input 
						data-inputkey="title"
						type="text" 
						placeholder="記事タイトル" 
						value={ $input.title } 
						//style={{ width: "100%", maxWidth: "500px" }} 
						//style={{ width: "100%" }} 
						className={ isUpdated.title ? "updated" : "" }
						onChange={change}
						/>
				</P2>
				<P2>
					<span 
						className={ isUpdated.displayDate ? "updated" : "" }
						data-panedisabledtarget={true}
						>
					記事日付&nbsp;&nbsp;
					</span><input 
						data-inputkey="displayDate"
						type="text" 
						placeholder="記事の日付 YYYY/MM/DD" 
						value={ $input.displayDate } 
						style={{ width: 80, textAlign: "center" }}
						//value={ isYmd($input.displayDate) ? moment($input.displayDate).format("YYYY/MM/DD") : $input.displayDate } 
						className={ isUpdated.displayDate ? "updated" : "" }
						onChange={change}
						/>
				&nbsp;
				&nbsp;
					<span 
						className={ isUpdated.publish ? "updated" : "" }
						data-panedisabledtarget={true}
						>
						Publishで出力する
					</span>&nbsp;
					<Button 
						title="articleの保存"
						data-inputkey="publish"
						className={ "icon-view-" + ( $input.publish ? "show" : "hide" ) + " " + ( $input.publish ? "positive" : "" ) } 
						onClick={change}
						style={{ fontSize: "12pt" }} 
						/>
				</P2>
				<div className="right">
					<Button 
						className={"icon-checkmark"}
						onClick={save}
						>&nbsp;Save</Button>&nbsp;
				</div>
			</P1>
		</section>

		<div className="meta">
			{ meta_el( "id", article ? article.id : "---" ) }
			{ meta_el( "created at", article ? article.createdAt : "---" ) }
			{ meta_el( "updated at", article ? article.updatedAt : "---" ) }
		</div>
		</>
		
	);
};



