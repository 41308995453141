import React, { 
	//useState, 
	useReducer,
	useEffect,
	useMemo,
} from 'react';

import 
	styled, 
	{
		//css,
		//keyframes
	} 
from 'styled-components';

/*
const fadeInOut:{[key:number]: any} = {};
for ( let i=0; i<10; i++ ){
	fadeInOut[i] = keyframes`
	0% { transform: translateY(0px); opacity: 1 }
	2% { transform: translateY(-${i*50+50}px) }
	98% { transform: translateY(-${i*50+50}px);  }
	100% { transform: translateY(0px); opacity: 1   }
	`;
}
*/

const Div = styled.div`
	transform: translateY(0px);
	opacity: 1;
	left: 50px;
	bottom: 0;
	transition: .2s transform;

	position: fixed;
	background: #101010;
	border: 1px solid #555;
	border-radius: 2px;
	filter: drop-shadow(0px 2px 2px rgba(0,0,0,.8));
	min-width: 200px;
	padding: 5px 8px;
	color: white;
	
	z-index: 100;
`

const DivWarn = styled(Div)`
	border: 1px solid #C95;
	color: #C95;
`;

const DivError = styled(Div)`
	border: 1px solid #C55;
	color: #C55;
`;



// type
type TLevel = "INFO"|"WARN"|"ERROR";
export type TSnackbar = ReturnType<typeof useSnackbar>;
interface IItems {
	[key:number]: { 
		content: TContent ;
		style: TStyle|null;
		level: TLevel|undefined; // Reducerの型定義を正しくしたら undefined 不要
	}
}
interface IState {
	lastId: number;
	items: IItems;
}
type TContent = React.ReactDOM|HTMLElement|string;
type TSetSchedule = (id:number)=>void;
type TStyle = {[key:string]: string };



export const useSnackbar = ( messager:(x:string)=>void = (str)=>{} ) =>{
	const here = `snackbar#useSnackbar`;
	console.time(`in ${here}`);

	const [ state, dispatch ] = useReducer( reducer, undefined, ()=>({ lastId:0, items: {} }) );


	useEffect( () =>{
		console.debug( `in ${here}#useEffect. mounted` );
		return () =>{
			console.debug(`in ${here}#useEffect, un-mounted.`);
		}
	},[]);

	// Snackbarを新規作成する。時間がきあたら 出現する演出、削除する。
	const _creator = ( content: React.ReactDOM|HTMLElement|string, level: TLevel )=>{
		dispatch({ type: "create", content, level, setSchedule:(id)=>{ 
			// コンマX秒後に styleにNULLを代入する。（NULL=> 現在のSnackbarの数に応じて縦位置を設定する ）
			setTimeout( ( dispatch ).bind( null,{ type: "style", id, style: null }), 200 );
			// X秒後にDELETEする。reducer側に描きたかったがreducerは topレベルの関数として描かないと二回実行される
			setTimeout( ( dispatch ).bind( null,{ type: "delete", id }), 4100 );
		}});
	};

	const client = {
		/*create: ( content: React.ReactDOM|HTMLElement|string )=>{
			_creator( content );
		},
		withMessage: ( content: string )=>{
			_creator( content );
			messager(content);
		},*/
		info: ( content: React.ReactDOM|HTMLElement|string )=>{
			_creator( content, "INFO" );
			typeof content == "string" && messager(content);
		},
		warn: ( content: React.ReactDOM|HTMLElement|string )=>{
			_creator( content, "WARN" );
			typeof content == "string" && messager(content);
		},
		error: ( content: React.ReactDOM|HTMLElement|string )=>{
			_creator( content, "ERROR" );
			typeof content == "string" && messager(content);
		},
	}

	//これを↓どこかでrenderする。
	const Items = useMemo ( ()=>{
		// 現在表示されているSnackbarの数に応じて、縦の位置を決める。
		return Object.keys(state.items).map( ( key, idx )=>{
			const item = state.items[parseInt(key)];
			console.log( "item.style", item.style );
			const style = item.style !== null ? 
							item.style
							: // ↓X病後にstyleにnullを入力される
							Object.assign( { transform: `translateY( -${ ( idx ) * 50 + 50 }px )` } );
			return (
				item.level === "INFO" || item.level === undefined ?
					<Div key={key} style={style} >{item.content}</Div>
					:
					item.level === "WARN" ?
						<DivWarn key={key} style={style} >{item.content}</DivWarn>
						:
						item.level === "ERROR" ?
							<DivError key={key} style={style} >{item.content}</DivError>
							:
							(<div>NEVER!</div>)
			);
		});
	}, [state.items] );

	console.timeEnd(`in ${here}`);
	return {
		client,
		Items,
	};
};


const reducer = ( state: IState , action:{ type: string, content?: TContent, id?:number, setSchedule?: TSetSchedule, style?: TStyle|null, level?: TLevel } )=>{
	const here = `snackbar#useSnackbar#reducer`;
	console.debug(`in ${here} , reducer state=>`, state, "action=>", action);
	//
	switch (action.type){
		case "create":
			if ( action.content === undefined ) throw new Error(`content is required`);
			if ( action.setSchedule === undefined ) throw new Error(`action.setSchedule is required`);
			const id = state.lastId;
			state.items[id] = {
				content: action.content,
				// 最初の縦位置は、画面の外に。
				style: { transform: `translateY(+100px)`}, 
				level: action.level,
			};

			// x秒後にDELETEさせる
			action.setSchedule(id);

			return { ...state, lastId: ++state.lastId, items: { ...state.items }};
		break;
		case "style":
			if ( action.id === undefined ) throw new Error(`id is required`);
			if ( action.style === undefined ) throw new Error(`style is required`);
			const item = { ...state.items[action.id], style: action.style };
			return { ...state, items: { ...state.items, [action.id]: item } };
		break;
		case "delete":
			if ( action.id === undefined ) throw new Error(`id is required`);
			delete state.items[action.id]; 
			return { ...state, items: { ...state.items} };
		break;
		default:
			throw new Error(`invalid action type.`);
	}
};
