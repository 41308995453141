import React, { 
	useEffect, 
	useContext,
	Suspense,
}  from 'react'

// context
import {AppContext} from "../app";

import { useAuth, I$Auth, isAuthorized, I$Authorized } from  '../auth/useAuth';
//import {useIdb} from  '../idb/useIdb';
//import { I$Message } from  '../message/useMessage';

import {Spinner} from './Spinner';

// Context
interface IReadyContext {
	$Auth: I$Authorized,
	//$Idb: I$Idb,
}
export const ReadyContext = React.createContext( {} as IReadyContext );


type Props = {
	$Auth: I$Auth,
	//$Idb: I$Idb,
};

// アロー関数じゃなくて function Ready って書かないと ErrorBoundaryで出力時に Unknoun になる。
export const Ready: React.FC<{ children:React.ReactNode }> = function Ready ({ children }){ 

	//const { $Message } = useContext(AppContext);
	const here = "ready#Ready";
	console.time(`in ${here}`);

	const $Auth = useAuth( str=>console.debug(str) );
	//const $Auth = useAuth( str=>$Message.set(str) );
	//const $Idb = useIdb($Message);

	useEffect( ()=>{
		console.debug(`in ${here}, mounted.`);
		$Auth.request();
		//$Idb.request();
		return ()=>console.debug(`in ${here}, unmounted.`)
	}, [] );
	

	console.timeEnd(`in ${here}`);
	return (
		<>
			{/*<Suspense fallback={<Spinner $Message={$Message} />}>*/}
			<Suspense fallback={<Spinner />}>
				<ReadyOrNot {...{
					$Auth,
					//$Idb
				}}>
					
						{children}
					
				</ReadyOrNot>
			</Suspense>
		</>
	);
};


//
// $Authによる認証や$Idbによる接続がまだ完了していなかったら Promiseを投げる。
//
const ReadyOrNot: React.FC<Props> = ({ 
	$Auth, 
	//$Idb, 
	children 
})=>{
	const here = "ready#ReadyOrNot";
	//console.time(`in ${here}`);

	useEffect( ()=>{
		console.debug(`in ${here}, mounted.`);
		return ()=>console.debug(`in ${here}, unmounted.`)
	}, [] );

	// エラーが起きていたら停止
	if ( $Auth.error !== null ) throw $Auth.error ;
	//if ( $Idb.error !== null ) throw $Idb.error ;

	if ( $Auth.user === null ) throw new Promise( done=>{ $Auth.user && done(undefined); });
	//if ( $Idb.client === null ) throw new Promise( done=>{ $Idb.client && done(); });

	// $Authは承認済みの状態のはず
	if ( !isAuthorized($Auth) ) throw new Error(`$Auth is invalid`);

	return (
			<ReadyContext.Provider value={{
				$Auth,
				//$Idb 
			}}>
				<>{children}</>
			</ReadyContext.Provider>
		);
};







