
import React,{
	useContext,
	useState,
	useEffect,
	//useMemo,
	//useRef,
	//ComponentProps,
	//useCallback,
	//useReducer
	//Suspense,
} from 'react';
//import moment from 'moment';


// context
import {AppContext} from "../../app";
//import {ReadyContext} from "../../ready/Ready";
import styled from 'styled-components';

import {Pane} from "../../pane/Pane";

/*
import {
	ControlledInput,
	useControlledInputSource,
} from "../../controlledInput2"
*/

const Pane2 = styled(Pane)`
	//height: 50%;
	//width: 50%;
`;




export const index = ()=>{
	const here = "pages#Home";
	console.time(`in ${here}`);

	const { $Pane } = useContext(AppContext);

	/*
	const [ data, setData ] = useState( ()=>{
		return {
			yamada: {
				name: "taro",
				age: 100,
				aaa: {
					aa1: {
						a1: 99,
						b1: 88,
					},
					aa2: {
						a2: 999,
						b2: 888,
					}
				},
				bbb: {
					bb1: {
						a3: 9999,
						b3: 8888,
					},
					bb2: {
						a4: 99999,
						b4: 88888,
					}
				}
			},
			suzuki: {
				name: "ziro",
				have: [1,2,3],
			}
		}
	});
	*/
	//console.log("useState==>",data)

	//const source = useControlledInputSource( data );

	

	useEffect( ()=>{
		console.debug(`in ${here}, mounted.`);
		return ()=>console.debug(`in ${here}, unmounted.`)
	}, [] );


	console.timeEnd(`in ${here}`);
	return (
			<section>
				<h1>Home!!</h1>
				
				<Pane2 
					id={"home"}
					dispatch={$Pane.dispatch} 
					style={{ left: "50%", width: "20%", height: "20%", top: "50%" }} 
					gripKeys={["right","bottom"]} open={true} 
				>
					HOME!
					{/*}
					<ControlledInput 
						<typeof data>
						source={source}
						paths={["yamada","bbb","bb1","b3"]}
						changer={ x=>setData(x) }
						/>
	
					
					<button onClick={()=>{
					}}>更新</button>
					*/}
					
				</Pane2>
				
			</section>
		);
};