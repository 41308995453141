import React,{
	useContext,
	useState,
	useEffect,
	useMemo,
	//useRef,
	useReducer
	//Suspense,
} from 'react';

import moment from 'moment';

import * as types from "../../../../types";

// context
import {ArticleContext} from "../../index";
import {AppContext} from "../../../../app";

//import {ReadyContext} from "../../ready/Ready";
import styled from 'styled-components';




import {
	P1,
} from "../../styled";

import {
	Move,
	Drop4Move,
} from "../../move"

import {
	meta_el,
	//Button,
	Loader,
	Button,
	Droppable,
} from "../../../helper"


import {
	//getKeys,
	//debounce,
} from "../../../../util"



import {
	ApiError,
	$AbortError,
	isApiOrAbortError,
} from '../../../../api/useApi2';


import {
	parseText,
	toJsxElements,
	toMyElements,
	//lf2BrTag,
	//toNode,
} from "../../parseText2";

import {
	postTypes
} from "../../postTypes";

interface Window2 {
	clipboardData: any
}

import {
	TUpdate
} from "./index";


export const PostDiv = styled.div`
`
export const RevisionBody = styled.div`
	
`


//
//	props.article が null の場合は新規作成とする
//
export const TextPost: React.FC<{ 
		revision: types.IPostRevision;
		post: types.IPost;
		passage: types.IPassage;
		article: types.IArticle;
		update: TUpdate;
	}> = ({ revision, post, passage, article, update })=>{
	const here = "pages#Articles#Followers#Passage#Post#TextPost";
	console.time(`in ${here}`);
	const { articleState, controller } = useContext( ArticleContext );

	useEffect( ()=>{
		console.debug(`in ${here}, mounted.`);
		return ()=>console.debug(`in ${here}, unmounted.`)
	}, [] );


	const onPaste = ( e: React.ClipboardEvent<HTMLDivElement> )=>{
		// ペーストしたときに、PLAIN TEXTだけ貼る
		 e.preventDefault();
		 if ( ( window as any ).clipboardData) {
		 	const content = ( window as any ).clipboardData.getData('Text'); 
		 	if ( ( window as any ).getSelection ) {
		 		const selection = window.getSelection();
		 		if ( selection !== null ){
			 		const range =  selection.getRangeAt(0);
			 		range.deleteContents();
			 		range.insertNode(document.createTextNode(content));
			 	}
		 	}
		 } else if (e.clipboardData) {
		 	const content = e.clipboardData.getData('text/plain');
       		document.execCommand('insertText', false, content);
		 }
	};
	
	//
	const onCange = ( e: React.ChangeEvent<HTMLInputElement> )=>{
		// debounce で　updateするぞ
	}
	
	//
	const onBlur = ( e: React.FocusEvent<HTMLDivElement> )=>{
		return save( e.currentTarget ).then( res=>{
			if ( isApiOrAbortError(res) ){
				return;
			}
			res && myRender( res.revision );
		});
	}

	const save=( node: HTMLDivElement )=>{
		const ary = parseText( node );
		const text = JSON.stringify( ary );
		return update( { text: text }, post, revision, postTypes.text );
	}
	
	const myRender = ( revision: types.IPostRevision )=>{
		if ( revision.text === null ) return;

		if ( revision.type !== postTypes.text ) return;
		const node = toMyElements( revision.text )
		const parent = document.getElementById(`revision-${revision.id}`);
		if ( parent !== null ){
			while (parent.firstChild) {
			  parent.removeChild(parent.firstChild);
			}
			parent.append(node)
		}
	};
	
	useEffect( ()=>{
		myRender( revision );
	}, [ revision ] );

	console.timeEnd(`in ${here}`);
	// article が nullの場合は 編集画面(新規作成)、articleに値がある場合は編集画面か表示画面
	return ( 
		<div
			contentEditable={"true"}
			onInput={onCange}
			suppressContentEditableWarning={true}
			onBlur={onBlur}
			onPaste={onPaste}
			id={`revision-${revision.id}`}
			className="revision-text-body"
			data-panedisabledtarget={true}
			>
			{/* Reactの描画だと contentEditable が正しく描画されないので、自前で描画する。myRender参照 */}
			{/* revision.text && lf2BrTag(revision.text) */}
			{/* revision.text ? toJsxElements( revision.text ) : null */}
		</div>
	);
};
TextPost.displayName = "TextPost"
