import React,{
	//useContext,
	//useState,
	//useEffect,
	//useMemo,
	//useRef,
	//Suspense,
} from 'react';

const $startBlock = 	( idx: number )=>({ "<B": idx });
const $endBlock = 		( idx: number )=>({ "B>": idx });
const $Return = 		( idx: number )=>({ "R": idx });
const $startStrong = 	( idx: number )=>({ "<L": idx });
const $endStrong = 		( idx: number )=>({ "L>": idx });


/*
type TItems = string
			| ReturnType< typeof $startBlock >
			| ReturnType< typeof $endBlock >
			| ReturnType< typeof $Return >
			| ReturnType< typeof $startStrong >
			| ReturnType< typeof $endStrong >;
*/


// シングルタグをオブジェクト化（ <BR> )
interface ISingleTagNode { 
	t: "R";
};

// ブロック／インラインタグをオブジェクト化（ <DIV> <STRONG> )
interface ITagNode { 
	t: "B"|"L";
	_: ( ITagNode|string|ISingleTagNode )[] 
};

type TNode = string|ITagNode|ISingleTagNode;


const isHTMLElementNode = ( node: any ): node is HTMLElement =>{
	return node.nodeType === 1 ;
};

export const parseText = ( el:HTMLElement )=>{

	let ary = nodeWalker( el, 0 );
	return ary;
};

const nodeWalker = ( node: ChildNode, idx: number ) =>{
		
	let ary:( TNode )[] = [];

	Array.from( node.childNodes ).forEach( childNode=>{

		const childText = (childNode.textContent||"");
		if ( childNode.nodeType === 1 ){
			idx++;
			// Element
			if ( isHTMLElementNode(childNode) ) {
				switch( childNode.tagName ){
					case "DIV":
					case "P":
						ary = ary.concat( { t: "B", _: nodeWalker( childNode, 0 ) } );
						break;
					case "BR":
						ary = ary.concat( { t: "R" } );
						break;
					case "I":
					case "STRONG":
					case "B":
						ary = ary.concat( { t: "L", _: nodeWalker( childNode, 0 ) } );
						break;
					default:
						ary = ary.concat( childText );
				}
			}
			//ary = ary.concat( nodeWalker( childNode ) );
		} else if ( childNode.nodeType === 3 ){
			// Text
			ary.push( childText );
		} else {
			//ary.push( childText );
		}
	});

	return ary;
};

const isTagNode = ( value: any ): value is ITagNode | ISingleTagNode =>{
	return Object.prototype.toString.call(value) === "[object Object]";
}

export const toJsxElements = ( text: string )=>{
	try{
		//console.log("toJsxElements==>" );
		const ary = JSON.parse( text );
		const jsxElements = toJSX( ary );
		//console.log(`toJsxElements===>`,jsxElements);
		return jsxElements;
	} catch( err ){
		return "JSON ERROR!";
	}
}

const toJSX = ( ary: TNode[] )=>{
	//const result: ( JSX.Element | string )[] = [];
	
	return ary.map( ( node: TNode, idx )=>{
		if ( typeof node == "string" ){
			return node;
		} else if ( isTagNode(node) ){
			if ( node.t === "R" ){
				return ( <br key={idx} data-key={idx}/> );
			} else if ( node.t === "B" ){
				if ( node._.length === 0 ) {
					// 要素がなければ無視
				//} else if ( node._.length === 1 && isTagNode( node._[0] ) && node._[0].t === "R" ) {
					// 要素が1個だけで改行だったら
					//return ( <br /> );
				} else {
					return ( <div key={idx} data-key={idx}>{ toJSX( node._ ) }</div> );
				}
			} else if ( node.t === "L" ){
				if ( node._.length === 0 ) {
					// 要素がなければ無視
				} else {
					return ( <strong key={idx} data-key={idx} >{ toJSX( node._ ) }</strong> );
				}
			} else {
				//v想定外
				return ( <span key={idx} data-key={idx}>[?node]</span> );
			}
		}
		// 想定外
		return ( <span key={idx} data-key={idx}>[?]</span> );
	});
}


export const toMyElements = ( text: string )=>{
	try{
		//console.log("toJsxElements==>" );
		if ( text === "" ) return "";


		const fragment = new DocumentFragment();
		//const div = document.createElement("div");
		const ary = JSON.parse( text );
		//const myElements = toMyDom( ary, div );
		const myElements = toMyDom( ary, fragment );
		//console.log(`toJsxElements===>`,jsxElements);
		return myElements;
	} catch( err ){
		console.log("text===>", text );
		return "JSON ERRORです!";
	}
}

const toMyDom = ( ary: TNode[], dom: HTMLElement|DocumentFragment )=>{
	//const result: ( JSX.Element | string )[] = [];
	
	return ary.reduce( ( dom, node: TNode, idx )=>{
		if ( typeof node == "string" ){
			const text = document.createTextNode(node);
			dom.appendChild(text);
			return dom;
		} else if ( isTagNode(node) ){
			if ( node.t === "R" ){
				const tag = document.createElement("br");
				dom.appendChild(tag);
				return dom;
			} else if ( node.t === "B" ){
				if ( node._.length === 0 ) {
					// 要素がなければ無視
					return dom;
				} else if ( node._.length === 1 && isTagNode( node._[0] ) && node._[0].t === "R" ) {
					// 要素が1個だけで改行だったら <BR>だけに。
					const tag = document.createElement("br");
					dom.appendChild(tag);
					return dom;
				} else {
					const tag = document.createElement("div");
					toMyDom( node._, tag )
					dom.appendChild(tag);
					//return ( <div key={idx} data-key={idx}>{ toMyDom( node._ ) }</div> );
					return dom;
				}
			} else if ( node.t === "L" ){
				if ( node._.length === 0 ) {
					// 要素がなければ無視
					return dom;
				} else {
					const tag = document.createElement("strong");
					toMyDom( node._, tag )
					dom.appendChild(tag);
					//return ( <div key={idx} data-key={idx}>{ toMyDom( node._ ) }</div> );
					return dom;
					//return ( <strong key={idx} data-key={idx} >{ oMyDom( node._ ) }</strong> );
				}
			} else {
				//v想定外
				//return ( <span key={idx} data-key={idx}>[?node]</span> );
				return dom;
			}
		}
		// 想定外
		//return ( <span key={idx} data-key={idx}>[?]</span> );
		return dom;
	}, dom );
}