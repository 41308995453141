import styled from 'styled-components';
import React,{
	//useContext,
	useState,
	//useMemo,
	//useRef,
	useReducer,
	useEffect
	//Suspense,
} from 'react';

import { 
	Tab, 
	Tabs,
	TabList,
	TabPanel, 
} from 'react-tabs';

import lodash from 'lodash';


import * as types from "../../../types";

import {
	Sign,
} from "../index";

/*
import {
	//ControlledInput,
	useControlledInputSource,
	IControlledInputSource,
} from "../../../controlledInput"
*/

/*
import {
	colors
} from "../../../util/colors"
*/

import {
	Button
} from '../button';

import {
	getEntries,
	myEntries,
} from '../../../util';


import {
	StylingPanelRaw, 
} from "./StylingPanelRaw";

import {
	StylingPanelFeature
} from "./StylingPanelFeature";

import {
	reducer,
	initialStylingState,
} from "./reducer";


import {
	IStylingState,
	IRawItems,
	IFeatureItems,
} from "./types";

export const Styling: React.FC<{
	entityType: "passage"|"revision";
	entity: types.IPassage|types.IPostRevision;
	show: boolean;
	setLocalStyle: React.Dispatch<React.SetStateAction<{ body: string, strong: string }>>;
	save: ( x: IStylingState )=>void;
}> = ( props )=>{


	const str2json = ( str: string )=>{
		try{ 
			return JSON.parse( str )
		} catch(err){
			throw err;
		}
	};


	const [ stylingState, stylingDispatch ] = useReducer( reducer, undefined, ()=>{
		const str = props.entity.style ? props.entity.style : "{}";
		const data = lodash.merge( {}, initialStylingState, str2json( str ) );
		return data;
	} );


	useEffect( ()=>{

		const bgColor = stylingState.feature.slide.background && stylingState.feature.slide.background.map( bg=>bg.color ).join(",");
		const textColor = stylingState.feature.slide.text && stylingState.feature.slide.text.color;
		const strongColor = stylingState.feature.slide.strong && stylingState.feature.slide.strong.color;
		const strongWeight = stylingState.feature.slide.strong && stylingState.feature.slide.strong.weight;

		props.setLocalStyle({
			body: [
				`/* passage-${ props.entity.id } */`,
				`background-color: ${bgColor} !important;`,
				`color: ${textColor};`,
			].join("\n"),
			strong: [
				`/* passage-${ props.entity.id } */`,
				`color: ${strongColor};`,
				`font-weight: ${strongWeight};`
			].join("\n"),
		});

		props.save(stylingState);

	}, [ stylingState ] );

	
	// 新旧管理のための、古い方のデータ
	const [ oldStylingState, setOldStylingState ] = useState( ()=>stylingState );

	

	const save = ( e: React.MouseEvent )=>{
		props.save( stylingState );
	};
	
	/*
		| raw                   | feature             |
		| slide    | content.   | slide    | content. |
		| [panel]  | [panel]    | [panel]  | [panel]  |
	*/
	return (
		<div className="styling" style={{ display: props.show ? "block" : "none" }}>
			<Tabs defaultIndex={0} >
				<TabList>
					{
						myEntries( stylingState ).map( entry=>( 
							<Tab className="typeTab" key={entry.key}>{ entry.key }</Tab> 
						))
					}
					<li className="border-bottom" >&nbsp;</li> 
				</TabList>
				{
					// Panel
					
					myEntries( stylingState ).map( type=>(
						<TabPanel className="typePanel" key={ type.key }>
							<div className="panel">
								<Tabs>
									<TabList>
										{
											// FEATURE or RAW タブ
											myEntries( type.value ).map( section=>{
												return ( 
													<Tab className="sectionTab" key={ type.key + "-" + section.key}>{ section.key }</Tab> 
												);
											})
										}
										<li className="border-bottom" >&nbsp;</li> 
									</TabList>
									{
										type.key === "raw" ?
											// SLIDE or CONTENT タブ
											myEntries( type.value ).map( section=>{
												const oldTypeObject = oldStylingState[ type.key ];
												return ( 
													<TabPanel className="sectionPanel" key={ type.key + "-" + section.key }>
														<StylingPanelRaw 
															typeKey={type.key}
															sectionKey={section.key}
															styleItems={section.value}
															stylingDispatch={stylingDispatch}
															oldStyleItems={oldTypeObject[section.key]}
															/>
													</TabPanel> 
												)
											})
											:
											type.key === "feature" ?
												// SLIDE or CONTENT タブ
												myEntries( type.value ).map( section=>{
													const oldTypeObject = oldStylingState[ type.key ];
													return ( 
														<TabPanel className="sectionPanel" key={ type.key + "-" + section.key }>
															<StylingPanelFeature
																typeKey={type.key}
																sectionKey={section.key}
																styleItems={section.value}
																stylingDispatch={stylingDispatch}
																oldStyleItems={oldTypeObject[section.key]}
																/>
														</TabPanel> 
													)
												})
												:
												<TabPanel className="sectionPanel">NEVER!!</TabPanel> 
									}
								</Tabs>
							</div>
						</TabPanel> 
					))
				}
			</Tabs>

			{/*
			<div className="right" style={{ marginTop: "10px" }}>
				<Button 
					className={"icon-checkmark"}
					onClick={save}
					>&nbsp;Save</Button>&nbsp;
			</div>
			*/}
		</div>
	);

}
Styling.displayName = "Styling";