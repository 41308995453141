import { 
	useState, 
	useEffect,
	useContext,
} from 'react';
//import superagent from "superagent";
import axios from "axios";

// context
import {AppContext} from "../app";

export interface I$Auth {
	user: T$AuthUser|null;
	error: T$AuthError;
	//promise: T$AuthPromise;
	request: ()=>void; 
}

//type T$AuthPromise = Promise<void>|null;
type T$AuthError = Error|null;
type T$AuthUser = IUser;

// ユーザー
export interface IUser {
	access_token: string;
	email: string;
	name: string;
	imageUrl: string;
}

// 承認済みになったかどうか
export const isAuthorized = ( $Auth: any ): $Auth is I$Authorized => {
	return $Auth && $Auth.user !== null;
}

// I$Auth（ .userはnullかもしれない） から承認済みの状態になった型
export interface I$Authorized extends I$Auth {
	user: T$AuthUser;
}


// const
import {GOOGLE_CLIENT_ID} from "./const";


// api
import {
	useApi2,
	IApiJob,
} from '../api/useApi2';


interface TInitializeAuthError  { 
	details: string;
	error: string;
};



export const useAuth = ( messager: (str:string)=>void  = ()=>{} ):I$Auth =>{
	
	const here = "auth#useAuth";
	console.time(`in ${here}`);

	//const [ promise, setPromise ] = useState<T$AuthPromise>( null );
	const [ error, setError ] = useState<T$AuthError>( null );
	const [ user, setUser ] = useState<T$AuthUser|null>( null );
	const { $Snackbar } = useContext(AppContext);

	const descriptor = {
		onError: ( job: IApiJob )=>{
			$Snackbar.client.error(`"${job.option.label}" が失敗しました`)
		},
	};
	const api = useApi2(descriptor);

	useEffect( ()=>{
		console.debug(`in ${here}, mounted.`);
		return ()=>console.debug(`in ${here}, unmounted.`)
	}, [] );

	//
	// 1）Gapiのスクリプトをリモートから取得する。
	//
	const requestGapiScript = ()=>{
		const here = "auth#useAuth#requestGapiScript";
		messager("GoogleApiのスクリプトを取得開始");
		return new Promise<any>( (resolve,reject)=>{
			axios
			//superagent
				//.get("http://localhost:8081/aaa")
				.get("https://apis.google.com/js/api.js")
				.then( res=>{
					if ( res.status !== 200 ){ 
						messager(`GoogleApiのスクリプトを取得失敗 status code [${res.status}]`);
						reject( new Error(`status code has been responsed [${res.status}]`) ); 
						return; 
					}
					console.debug(`in ${here}, done`);
					messager("GoogleApiのスクリプトを取得完了");
					console.debug(res);
					//const gapiScript = eval("(function getGapi(){"+res.text+";return gapi;})()"); // superagent
					const gapiScript = eval("(function getGapi(){"+res.data+";return gapi;})()"); // axios
					resolve(gapiScript);
				})
				.catch( err=>{
					messager("GoogleApiのスクリプトを取得失敗");
					reject(err);
				});
		});
	};

	//
	// 2）Google認証のためのライブラリをリモート？から取得する
	//
	const loadGapiLibrary = ( gapi: any )=>{
		const here = "auth#useAuth#loadGapiLibrary";
		messager("GoogleApiのライブラリを取得開始");

		return new Promise( ( resolve, reject )=>{
			gapi.load( "auth2",{
				callback: ()=>{ 
					messager("GoogleApiのライブラリを取得完了");
					resolve( gapi );
				},
				onerror: ()=>{ 
					messager("GoogleApiのライブラリを取得失敗");
					reject( new Error(`in ${here}, fail, gapi load, reason Error.`));
				},
				timeout: 10000,
				ontimeout: ()=>{ 
					messager("GoogleApiのライブラリを取得失敗");
					reject( new Error(`in ${here}, fail, gapi load, reason Timeout`) )
				},
			});
		});
	};


	//
	// 3）Googleの認証をする
	//
	const initializeAuth = ( gapi: any )=>{
		const here = "auth#useAuth#initializeAuth";
		console.debug(`in ${here}, try`);
		messager("GoogleApiの初期化開始");
		return gapi.auth2.init({
			//'apiKey': GOOGLE_API_KEY,
			'clientId': GOOGLE_CLIENT_ID,
			//'clientId': "hoge!!",
		    'scope': 'profile',
		    'discoveryDocs': ['https://www.googleapis.com/discovery/v1/apis/drive/v3/rest'],
		}).then( ( auth2:any )=>{
			console.debug(`in ${here}, done`);
			messager("GoogleApiの初期化完了");
			return auth2;
		}).catch( ( err: TInitializeAuthError )=>{
			messager( `GoogleApiの初期化を失敗（よくある原因：GoogleApiのclient_idなどの間違い）` );
			messager( `${err.details}` );
			throw new Error(err.details);
		});
	};


	//
	// 4）認証状況が変わったかどうかの監視を開始する。
	//
	const listenUpdateSignInState = ( auth2: any )=>{
		//const here = "auth#useAuth#listenUpdateSignInState";
		messager("Google認証の状態変更を監視(listen)開始");
		auth2.isSignedIn.listen(onUpdateSigninStatus);
		return auth2;
	};


	//
	// 5）認証されていれば次へ、されていなければ確認画面を開いて認証
	//
	const signInIfNotAuthorized = ( auth2: any )=>{
		const here = "auth#useAuth#signInIfNotAuthorized";
		console.debug(`in ${here}, sign in state[${auth2.isSignedIn.get()}]`);
		
		if ( !auth2.isSignedIn.get() ){
			messager("Google認証が済んでいないためOAuth認証の確認画面を開く");
			return auth2.signIn()
				.then( ( res: any )=>{
					console.debug(`in ${here}, singin in now!`, auth2 );
					messager("OAuth認証の確認画面から認証完了");
					return auth2;
				})
				.catch( ( err: TInitializeAuthError )=>{
					console.error("error==>",err);
					messager(`OAuth認証の確認画面で認証失敗 [${ err.error }]`);
					if ( err.error == "popup_closed_by_user" ){
						throw new Error(`Googleの認証のためのポップアップがブロック（または閉じられた）されたため認証に失敗しました。`);
					}
					//return Promise.reject({ message: `in ${here}, fail, sign in reason:[${err.error}].`, Error: err });
					throw new Error(err.error);
				});
		}

		messager("Google認証が済んでいる");
		return Promise.resolve(auth2);
	};



	//
	// 6）ユーザー情報を取得
	//
	const getGoogleUserProfile = ( auth2: any ):IUser =>{
		//const here = "auth#useAuth#getGoogleUserProfile";
		messager("ユーザー情報を取得開始");

		let googleUser = auth2.currentUser.get();
		let profile = googleUser.getBasicProfile();
		
		let user:IUser = {
			access_token: googleUser.getAuthResponse().access_token,
			email: profile.getEmail(),
			name: profile.getFamilyName() + " " + profile.getGivenName(),
			imageUrl: profile.getImageUrl(),
		};

		messager("ユーザー情報を取得完了");
		return user;
	};

	//
	// 7) minsRの認証
	//
	const appAuth = ( user:IUser )=>{
		const here = "auth#useAuth#appAuth";
		console.debug( `in ${here}. try request`);

		messager(`Appの認証開始`);

		return api.post( `/auth/signin`, { body: { 
			googleToken: user.access_token,
			mail: user.email 
		}}, { label: "Appの認証" } ).then( (res: any )=>{
				messager(`Appの認証完了`);
				//console.log(`res.data==>`,res.data);
				sessionStorage.setItem("token", res.data.token );
				return { 
					response: res, // token, expiresAt。
					user: user,
				};
			})
			.catch( ( res: any )=>{
				if (!res.body) {
					if ( res.error instanceof Error ){
						return Promise.reject( res.error.message );
					}
					messager(`Appの認証失敗。返却されたデータが空。`);
					throw res; 
				}
				switch ( res.body.message ){
					case "Unauthorized":
						messager(`Appの認証が失敗(Unauthorized[401]) ※認証しようとしているユーザーは登録されていない可能性があります。`);
					break;
					case "Bad Request":
						messager(`Appの認証が失敗(Bad Request[400])`);
					break;
					case "Internal Server Error":
						messager(`Appの認証が失敗(Internal Server Error[500]) ※サーバ側の処理で問題が発生しました。`);
					break;
					case "Unsuccessful HTTP response":
						messager(`Appの認証が失敗(Unsuccessful HTTP response[401]) ※もう一度試してみてください。`);
					break;
					default:
						messager(`Appの認証が失敗(不明なエラー[？])`);
					break;
				}
				//throw new Error(`in ${here},message[${res.body.message}]`);
				return Promise.reject( new Error(`in ${here}, fail and get message[${res.body.message}]`) );
			});
	};

	//
	//
	//
	const request = ():void =>{
		const here = "auth#useAuth#request";
		console.debug(`in ${here}, try`);

			requestGapiScript()
				.then( loadGapiLibrary )
				.then( initializeAuth )
				.then( listenUpdateSignInState )
				.then( signInIfNotAuthorized )
				.then( getGoogleUserProfile )
				.then( appAuth )
				.then( ( param:{ user: IUser, response: any } )=>{
					setUser( param.user );
					//setPromise(null);
				}).catch( (err)=>{
					$Snackbar.client.error("認証失敗");
					console.error("useAuthでエラーが発生：", err );
					setError( err );
				});
		//});
		console.debug(`in ${here}, done`);
	};


	//
	//	ユーザーがGoogleをサインアウトしたりサインインしたら実行される。
	// 一度認証情報を全て初期化して、改めて認証する。
	//
	const onUpdateSigninStatus = ( isSignedIn: boolean )=>{
		const here = "Auth#useAuth#onUpdateSigninStatus";
		console.debug(`in ${here}, %cupdate sign in status, [${isSignedIn}]`,"color:red");
		messager("Google認証の状態変更を検知した");
		if (isSignedIn) {
			messager("Sign In!");
		} else {
			messager("Sign Out!");
		}

		// 一旦全部初期化して改めて認証する
		setUser(null);
		//setPromise(null);
		setError(null)
		request();
	};

	console.timeEnd(`in ${here}`);
	return {
		user,
		error,
		request,
		//promise, 
		//__debug_requestGapiScript: requestGapiScript,
		//__debug_loadGapiLibrary: loadGapiLibrary,
		//__debug_listenUpdateSignInState: listenUpdateSignInState,
		//__debug_signInIfNotAuthorized: signInIfNotAuthorized,
		//__debug_getGoogleUserProfile: getGoogleUserProfile,
		//__debug_appAuth: appAuth,
	}
};