



import * as types from "../../types";

import {
	TApiReturn,
	ApiError,
	$AbortError,
	isApiOrAbortError,
	//IApiContext,
} from "../../api/useApi2";

import {
	postTypes 
} from "./postTypes";

import {
	IStylingState
} from "../helper/styling/types";


//
//
//
export const requesterFactory = ( api1: TApiReturn, api2: TApiReturn )=>({

	getAll: ()=>{
		return api1.get<{ articles: types.IArticle[]}>( "/api/article/all", {}, { label: `記事一覧の取得` } ).then( res=>{
			return res.data.articles ;
		}).catch( err=>{
			if ( isApiOrAbortError(err) ) return err;
			console.error(err); throw new Error(`invalid`);
		});
	},
	getById: ( articleId: string, title: string )=>{
		return api1.get<{ article: types.IArticle}>( `/api/article/${articleId}`, {}, { label: `'${title}'の取得` } ).then( res=>{
			return res.data.article;
		}).catch( err=>{
			if ( isApiOrAbortError(err) ) return err;
			console.error(err); throw new Error(`invalid`);
		});
	},
	create: ( body: types.IArticle4Request )=>{
		return api1.post<{ article: types.IArticle}>("api/article",{ body: body }, { label: `'${body.title}'の作成`, snackbar: true } ).then( res=>{
			return res.data.article;
		}).catch( err=>{
			if ( isApiOrAbortError(err) ) return err;
			console.error(err); throw new Error(`invalid`);
		});
	},
	update: ( articleId: string, body: types.IArticle4Request )=>{
		return api1.patch<{ article: types.IArticle}>( `api/article/${articleId}`,{ body: body }, { label: `'${body.title}'の編集`, snackbar: true } ).then( res=>{
			return res.data.article;
		}).catch( err=>{
			if ( isApiOrAbortError(err) ) return err;
			console.error(err); throw new Error(`invalid`);
		});
	},
	getFollowers: ( article: types.IArticle )=>{
		return api1.get< types.IFollowers >(`api/article/${article.id}/followers`, {}, { label: `'${article.title}'のFollowersを取得` } ).then( res=>{
			//console.log("getPosts",res);
			return {
				passages:			res.data.passages,
				posts$passageId: 	res.data.posts$passageId,
				revision$postId:	res.data.revision$postId,
				image$id:			res.data.image$id,
			};
		}).catch( err=>{
			if ( isApiOrAbortError(err) ) return err;
			console.error(err); throw new Error(`invalid`);
		});
	},
	createPassage: ( article: types.IArticle, prevPassage: types.IPassage|null )=>{
		return api1.post<{ passage: types.IPassage}>(`api/passage/${article.id}`,{
			body: {
				prevPassageId: prevPassage ? prevPassage.id : null,
			}
		},{ label: `'${article.title}'のPassageを作成` }).then( res=>{
			return res.data.passage;
		}).catch( err=>{
			if ( isApiOrAbortError(err) ) return err;
			console.error(err); throw new Error(`invalid`);
		});
	},
	createPost: ( passage: types.IPassage, prevPost: types.IPost|null, postType: keyof typeof postTypes )=>{
		return api1.post<{ 
			post: types.IPost, 
			revision: types.IPostRevision,
			image: types.IImage | null,
		}>(`api/post/`, {
			body: {
				passageId: passage.id,
				prevPostId: prevPost ? prevPost.id : null,
				type: postType,
				text: "",
				//url: null,
				//imageId: null,
			}
		}, { label: `Post作成` } ).then( res=>{
			return res.data;
		}).catch( err=>{
			if ( isApiOrAbortError(err) ) return err;
			console.error(err); throw new Error(`invalid`);
		});
	},
	updatePassage: ( passage: types.IPassage, styleState: IStylingState )=>{
		return api2.patch<{
			passage: types.IPassage
		}>(`api/passage/${passage.id}`, {
			body: {
				style: JSON.stringify( styleState ),
			}
		}, { label: "style変更" } ).then( res=>{
			return res.data;
		}).catch( err=>{
			if ( isApiOrAbortError(err) ) return err;
			console.error(err); throw new Error(`invalid`);
		});
	},
	updateRevision: ( post: types.IPost, body: types.IPostRevision4Request )=>{
		return api2.patch<{ 
			post: types.IPost, 
			revision: types.IPostRevision,
			image: types.IImage | null,
		}>( `/api/post/${post.id}`, { body }, { label: `Revision[postId:${post.id}]更新` } ).then( res=>{
			return res.data;
		}).catch( err=>{
			if ( isApiOrAbortError(err) ) return err;
			console.error(err); throw new Error(`invalid`);
		});
	},
	// Imageやリンクはシンク（シンクというか1つのリクエストだけに制御しているやつ）で更新
	updateRevisionSync: ( post: types.IPost, body: types.IPostRevision4Request )=>{
		return api1.patch<{ 
			post: types.IPost, 
			revision: types.IPostRevision,
			image: types.IImage | null,
		}>( `/api/post/${post.id}`, { body }, { label: `Revision[postId:${post.id}]更新` } ).then( res=>{
			return res.data;
		}).catch( err=>{
			if ( isApiOrAbortError(err) ) return err;
			console.error(err); throw new Error(`invalid`);
		});
	},
	movePost: ( postId: string, prevPost: types.IPost|null, parentId: string|null )=>{
		return api1.patch<{ 
			post: types.IPost, 
			prevPost: types.IPost | null,
		}>( `/api/post/${postId}/move` , {
			body: {
				prevPostId: prevPost ? prevPost.id : null,
				parentId
			}
		}, { label: `Post[id:${postId}]の移動` } ).then( res=>{
			return res.data;
		}).catch( err=>{
			if ( isApiOrAbortError(err) ) return err;
			console.error(err); throw new Error(`invalid`);
		});
	},
	movePassage: ( passageId: string, prevPassage: types.IPassage|null )=>{
		return api1.patch<{ 
			passage: types.IPassage, 
			prevPassage: types.IPassage, 
		}>( `/api/passage/${passageId}/move` , {
			body: {
				prevPassageId: prevPassage ? prevPassage.id : null,
			}
		}, { label: `Passage[id:${passageId}]の移動` } ).then( res=>{
			return res.data;
		}).catch( err=>{
			if ( isApiOrAbortError(err) ) return err;
			console.error(err); throw new Error(`invalid`);
		});
	},
	deletePost: ( postId: string )=>{
		return api1.delete<{ 
		}>( `/api/post/${postId}` , {
		}, { label: `Passage[id:${postId}]の削除` } ).then( res=>{
			return res.data;
		}).catch( err=>{
			if ( isApiOrAbortError(err) ) return err;
			console.error(err); throw new Error(`invalid`);
		});
	},
	deletePassage: ( passageId: string )=>{
		return api1.delete<{ 
		}>( `/api/passage/${passageId}` , {
		}, { label: `Passage[id:${passageId}]の削除` } ).then( res=>{
			return res.data;
		}).catch( err=>{
			if ( isApiOrAbortError(err) ) return err;
			console.error(err); throw new Error(`invalid`);
		});
	},
	deleteArticle: ( articleId: string )=>{
		return api1.delete<{ 
		}>( `/api/article/${articleId}` , {
		}, { label: `Article[id:${articleId}]の削除` } ).then( res=>{
			return res.data;
		}).catch( err=>{
			if ( isApiOrAbortError(err) ) return err;
			console.error(err); throw new Error(`invalid`);
		});
	},
	uploadImage: ( binary: string|ArrayBuffer )=>{
		return api1.post<{ 
			image: types.IImage 
		}>(`/api/image/permanent`, {
			body: {
				file: binary
			}
		}, {label:  `画像アップロード`}).then( res=>{
			return res.data;
		}).catch( err=>{
			if ( isApiOrAbortError(err) ) return err;
			console.error(err); throw new Error(`invalid`);
		});
	},
	getImage: ( imageId: string )=>{
		return api2.get<{ 
			image: types.IImage 
		}>(`/api/image/${imageId}`, {}, {label:  `画像情報取得`}).then( res=>{
			return res.data;
		}).catch( err=>{
			if ( isApiOrAbortError(err) ) return err;
			console.error(err); throw new Error(`invalid`);
		});
	},
	publish: ()=>{
		return api1.get(`/api/publish`, {}, {label:  `公開`}).then( res=>{
			return res.data;
		}).catch( err=>{
			if ( isApiOrAbortError(err) ) return err;
			console.error(err); throw new Error(`invalid`);
		});
	}
})
