import styled from 'styled-components';
import React,{
	//useContext,
	//useState,
	//useEffect,
	//useMemo,
	//useRef,
	//useReducer
	//Suspense,
} from 'react';

import * as types from "../../../../types";

export const ImageSelected: React.FC<{
    image: types.IImage|null;
    onClick: ()=>void;
}> = ({ 
    image,
    onClick
})=>{

    const str2json = ( str: string )=>{
		try{
			return JSON.parse( str );
		} catch( err ){
			return { mini: { url: null }};
		}
    }
    
    if ( !process.env.CDN_URL_BASE ) throw new Error("INVALID!");
    const src = image ? `${process.env.CDN_URL_BASE||""}/${image.objectKey}_min.${image.ext}` : null ;
                       
    return (
        <div
         className="image-selected"
         onClick={onClick}
         >
            {
                image ? 
                    <div className="image-selected-img">
                        <img crossOrigin="anonymous" src={ src || "" } key={image.id}/>
                    </div>
                    :
                    <div>NULL</div>
            }
        </div>
    );
};

