import React, { 
	useEffect 
}  from 'react'

import { render 
} from 'react-dom';

// import usePortal from 'react-useportal'

// Components
import { GlobalStyle } from './style/globalStyle';
import { ErrorBoundary } from './app/ErrorBoundary';
import { Ready } from './ready/Ready';
import { Router } from './naviAndRouter/Router';


import {
	Portal
} from "./portal";

// hook
//import { useMessage, I$Message } from './message/useMessage';
import { usePane, TPane } from './pane/usePane';
import { useSnackbar, TSnackbar } from './snackbar/useSnackbar';

// Context
interface IAppContext {
	//$Message: I$Message;
	$Pane: TPane;
	$Snackbar: TSnackbar;
}
export const AppContext = React.createContext( {} as IAppContext );


// version
const appPackage = require('../package.json');
// console.table(`in App,${appPackage.name}.version: [${appPackage.version}]`); 
console.table({
	"ENV": process.env.NODE_ENV,
	"CDN_URL_BASE": process.env.CDN_URL_BASE,
	"API_URL_BASE": process.env.API_URL_BASE,
	name: appPackage.name,
	version: appPackage.version,
});


// Workerが必須
//if (!window.Worker) throw new Error("本システム は Worker が使用できる必要があります");


try{

	const App = ()=>{
		const here = "App";
		console.time(`in ${here}`);

		//const $MouseOnBody = useMouseOnBody();
		//const $Message = useMessage();
		//const $Frame = useFrame();
		const $Pane = usePane();
		//const $Snackbar = useSnackbar( str=>$Message.set(str) );
		const $Snackbar = useSnackbar( str=>console.debug(str) );
		//const { Portal } = usePortal();


		useEffect( ()=>{
			console.debug(`in ${here}, mounted.`);
		}, [] );

		console.timeEnd(`in ${here}`);
		return (
			<>
				<GlobalStyle/>
				<ErrorBoundary>
					<AppContext.Provider value={{ 
							//$Message, 
							$Snackbar, 
							$Pane 
						}}>
						<Ready>
							<Router />
						</Ready>
					</AppContext.Provider>
				</ErrorBoundary>
				<Portal data-portal-snackbar >
					{ $Snackbar.Items }
				</Portal>
			</>

		)
	};

	render(<App />, document.getElementById('root') );

}catch(err){
	console.error("* * * * エラーをキャッチ * * * *");
	throw err;
}