import React, { 
	useRef,
	useState,
	useContext,
} from 'react';
import { Link } from 'react-router-dom'
import styled from 'styled-components';

// context
import {ReadyContext} from "../ready/Ready";
import {AppContext} from "../app";
import {Pane} from "../pane/Pane";

const Pane2 = styled(Pane)`
	//display:none;
	position: absolute;
	right:0; top:25px;
	width: 200px;
	background: #303030;
`;


const Fixed = styled.div`
	position: fixed;
	right: 0px; top: 0px;
	z-index: 10000;
`;

const Button = styled.button`
	position: fixed;
	right:0; top:0;
	padding: 10px 10px;
	color: #F0F0F0;
	background: none;
	border: none;
	cursor: pointer;
	&:focus { outline:0; }; // クリック時の青い枠線を消す
	&:hover { 
		color: white;
		background: #303030;
	}
`;


const Profile = styled.div`
	//text-align: center;
	width: 80%;
	margin: 0 auto;
	display: flex;
	p:nth-child(1) { 
		width: 20%; 
		img { width: 100%; }
	}
	p:nth-child(2) { 
		width: 75%; 
		margin-left: 5%;
		align-self: center;
	}
`;

const Ul = styled.ul`
	list-style-type: none;
	background: #202020;
	padding:0;
	margin:0;
	li {
	  padding:0;
	  margin:0;
	  a { 
	  	display: block;
	  	padding: 8px 15px;
	  	color: white; 
	  	text-decoration: none;
	  	&:hover {
	  		background: rgba(255,255,255,0.2);
	  	}
	  }
	}
  }
`;


export const Navi = ()=>{

	const here = "navi#Navi";
	console.debug(`in ${here}`);

	const { $Pane } = useContext(AppContext);
	const { $Auth } = useContext(ReadyContext);
	const [ timerId, setTimerId ] = useState<NodeJS.Timeout|null>( null );
	
	const toggle = ()=>{
		//console.log( $Pane.paneState$id["navi"] );
		if ( $Pane.paneState$id["navi"].isOpen ){
			$Pane.dispatch({ type: "close", id: "navi" });
		} else {
			$Pane.dispatch({ type: "open", id: "navi" });
		}
	};

	const ttt = (x:boolean)=>{
		setTimerId( timerId=>{
			if ( !x ){
				if ( timerId !== null ) clearTimeout(timerId);
				timerId=null;
			} else {
				timerId = setTimeout( ()=>{ 
					$Pane.dispatch({ type: "close", id: "navi" }); 
				}, 1000 );
			}
			return timerId;
		});
	};

	return (
		<Fixed>
			<Button onClick={toggle}>
				<i className={"icon-menu"}/>
			</Button>

			<Pane2 
				id={"navi"} dispatch={$Pane.dispatch} 
				open={false} 
				onEnter={()=>{ ttt(false); }}
				onLeave={()=>{ ttt(true); }}
			>
				<Profile>
					<p><img src={ $Auth.user.imageUrl } /></p>
					<p>{ $Auth.user.name }</p>
				</Profile>
				<Ul>
					<li><Link to="/" onClick={toggle}><i className={"icon-home"}/> Home</Link></li>
					<li><Link to="/articles" onClick={toggle}><i className={"icon-document"}/> Articles</Link></li>
				</Ul>
			</Pane2>
		</Fixed>
    );
};


/*
<Menu
				id="global-men"
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={handleClose}
				>
				<MenuItem onClick={handleClose}></MenuItem>
				<MenuItem onClick={handleClose}><Link to="/foo">fooe2</Link></MenuItem>
				<MenuItem onClick={handleClose}><Link to="/bar">bar3</Link></MenuItem>
				<MenuItem onClick={handleClose}><User /></MenuItem>
				</Menu>
				*/