import React,{
	useContext,
	useState,
	useEffect,
	useMemo,
	//useRef,
	useReducer
	//Suspense,
} from 'react';

import moment from 'moment';

import * as types from "../../../types";

// context
import {ArticleContext} from "../index";
import {AppContext} from "../../../app";
//import {ReadyContext} from "../../ready/Ready";
import styled from 'styled-components';




import {
	P1,
	P2,
} from "../styled";

import {
	Drop4Move,
} from "../move"

import {
	meta_el,
	Button,
	//Label,
	Loader,
	Sign,
	Droppable,
} from "../../helper"

/*
import {
	getKeys,
	debounce,
} from "../../../util"
*/

import {
	ApiError,
	$AbortError,
	isApiOrAbortError,
} from '../../../api/useApi2';

import {
	Passage,
} from "./Passage";

import {
	Post,
} from "./Post";

//
//	props.article が null の場合は新規作成とする
//
export const Followers: React.FC<{ 
		article: types.IArticle|null|ApiError|$AbortError;
	}> = ({ article })=>{
	const here = "pages#Articles#Followers";

	const { articleState, controller } = useContext( ArticleContext );
	const { $Snackbar } = useContext(AppContext);

	if ( article === null ) {
		return <Loader/>
	} else if ( article instanceof ApiError ) {
		return <section>エラー</section>
	} else if ( article instanceof $AbortError ) {
		return <section>キャンセルされた</section>
	}

	if ( isApiOrAbortError( article ) || article === null ){
		throw new Error("invalid article"); // type対策。IArticle か null に絞る
	}

	useEffect( ()=>{
		console.debug(`in ${here}, mounted.`);
		return ()=>console.debug(`in ${here}, unmounted.`)
	}, [] );

	// articleが変わった時
	// todo: しまったここにおくと React規約違反だー
	useEffect( ()=>{
		controller.getFollowers( article );
	}, [ article ] );


	if ( articleState.followers === null ) {
		return <Loader/>
	} else if ( articleState.followers instanceof ApiError ) {
		return <section>エラー</section>
	} else if ( articleState.followers instanceof $AbortError ) {
		return <section>キャンセルされた</section>
	}

	const followers = articleState.followers ;
	if ( isApiOrAbortError( followers ) || followers === null ){
		throw new Error("invalid  followers"); // type対策。IArticle か null に絞る
	}

	// Passageの並び順を制御する
	followers.passages.sort( (a,b)=>a.order < b.order ? -1 : 1 );

	// article が nullの場合は 編集画面(新規作成)、articleに値がある場合は編集画面か表示画面
	return ( 
		<>
					
			<P1>
				<Button className={"icon-plus"} onClick={()=>controller.createPassage( article, null )} >&nbsp;Passage</Button>
			</P1>
			<div style={{ position: "relative" }}>
				{
					// POST用 ドロップゾーンを表示、基本的に bottom に表示されるのであえて div をここにおく
					articleState.editor.dragging4passageId ?
						<Drop4Move entity={null} entityType={"passage"} />
						:
						null
				}
				{
					articleState.editor.dragging4menu4passage ?
						<Droppable 
							onDrop={(e)=>controller.dropMenu( e, { entity: null, entityType: "passage", article: article } )}
							shouldAcceptType={ types=>types.some( t=>RegExp("^application/article-menu-").test(t) )}
							><div className="move-drop" /></Droppable>
						:
						null
				}
			</div>
			<div>{/* .passage:first-child の borderを消すため  */}
			{

				!followers.passages || followers.passages.length === 0 ?
					<><Sign className="icon-info"/>Passageを追加してください</>
					:
					followers.passages.map( passage=>{
						const posts = followers.posts$passageId[ passage.id ];
						
						// post を order 順に並び替える
						posts && posts.sort( (a,b)=>a.order < b.order ? -1 : 1 );
//console.log("passage==>", passage);
						return (
							<Passage passage={passage} key={passage.id}>
								{
									!posts || posts.length === 0 ?
										<><Sign className="icon-info"/>Postを追加してください</>
										:
										posts.map( post=>{
											const revision = followers.revision$postId[ post.id ];
											//console.log("revision==>", revision);
											const image = revision.imageId === null ? null : followers.image$id[ revision.imageId ];
											return (
												<Post article={article} passage={passage} post={post} revision={revision} image={image} key={post.id}/>
											)
										})
								}
							</Passage>
						);
					})
			}
			</div>
				
		</>
	);
};
