import styled from 'styled-components';



export const Ul = styled.ul`
	list-style: none;
	padding: 0;
	margin: 0;
`;

export const Li = styled.li`
	padding: 5px 10px;
	cursor: pointer;

	&:hover {
		//background: #303030;
		background-color: rgba( 255, 255, 255, .2 );
	}

	p[data-title]{
		font-size: small;
		margin:0;
		//color: white;
	}
	div[data-meta]{
		font-size: x-small;
	}
	span {
		margin:0 5px 0 0;
		padding:0;
		display: inline-block;
		&[data-date] {
			//span { color: #888 }
		}
		&[data-publish] {
			//color: white;
			//border-radius: 2px;
			//padding: 0 2px;
			font-size: x-small;
			vertical-align: middle;
		}
		&[data-publish="true"] {
			//background: green;
			//color: white;
		}
		&[data-publish="false"] {
			//background: brown;
			//color: orange;
		}
	}
`;

export const P1 = styled.div`
	/*
	margin-top: 1em;
	margin-bottom: 1em;
	*/
	margin-top: .5em;
	margin-bottom: .5em;

	margin-left: 0;
	margin-right: 0;
	padding-left: 0px;
	padding-right: 0px;
	&:first-child {
		margin-top: 0;
	}
	
`

export const P2 = styled.div`
	margin-top: .5em;
	margin-bottom: .5em;
	margin-left: 0;
	margin-right: 0;
	padding-left: 0;
	padding-right: 0;
	&:first-child {
		margin-top: 0;
	}
`




