

// https://stackoverflow.com/questions/52856496/typescript-object-keys-return-string
//
// ["a", "b", "c"] has the shape of "a"|"b"[]
// Array<keyof typeof obj>
export const getKeys = <T extends {}>(o: T): Array<keyof T> => <Array<keyof T>>Object.keys(o)

/*
type Entries<T> = {
  [K in keyof T]: [K, T[K]]
}[keyof T][]
*/

type PickByValue<T, V> = Pick<T, { [K in keyof T]: T[K] extends V ? K : never }[keyof T]>
type Entries<T> = {
  [K in keyof T]: [keyof PickByValue<T, T[K]>, T[K]]
}[keyof T][];


export function getEntries<T>(obj: T): Entries<T> {
  return Object.entries(obj) as any;
}

/*
export function typeEntries<T>( obj: T ): Entries3<T> {
  return Object.entries(obj) as any;
}
*/

/*
const C = {foo: "bar", baz: 0};
const c = getEntries(C).map( ([key,item])=>{
	return C[key]
} )
*/

/*
const X = { a: 100, b: "aaa", c: 300 } as const;
type Test3 = Entries3<typeof X>;
*/

// (["a" | "c", number] | ["b", string])[]


type EEE<T> = {
  [K in keyof T]: { key: K, value: T[K] }
}[keyof T][]

// どうしてもオブジェクトの keyとvalueのセットを key:xx,value:xx|key:xx,value:xx|key:xx,value:xx こんな風に持ちたかった。それにはタプルでは無理だった
export const myEntries = <T>( obj: T ): EEE<T> =>{
  return getKeys(obj).map( key=>({ key: key, value: obj[ key ]}) ) as any;
}

/*
//const XXX = typeEntries( X );
const XXX = myEntries( X );

myEntries( X ).map( (xxxxx)=>{

})
*/