
import {
    Paths
} from "../../../types"

import {
    IFeatureItems,
    IStylingState,
    TSectionKeys,
} from "./types";

export const initialStylingState: IStylingState = ({
    feature: {
        slide: {
            background: [{
                image: null,
                color: null,
            }],
            text: {
                color: null,
            },
            strong: {
                weight: null,
                color: null,
            },
        },
        content: {
        }
    },
    raw: {
        slide: {
            position: "relative",
            "background-color": "transparent",
            "background-image": "none",
            color: "inherit",
        },
        content: {
        }
    },
});

type xxxx = keyof ( Required<IFeatureItems> )[THasObjectValues]

export type TStylingDispatch = { 
    type: "setRaw";
    sectionKey: TSectionKeys;
    property: string;
    value: string;
}|{
    type: "addRaw";
    sectionKey: TSectionKeys;
    property: string;
}|{ 
    type: "setFeatureObject";
    sectionKey: TSectionKeys;
    property: THasObjectValues;
    //properties: Paths<Pick< IStylingState["feature"][TSectionKeys], THasObjectValues> >;
    value: IStylingState["feature"][TSectionKeys][THasObjectValues];
}|{
    type: "setFeatureObjectArray";
    sectionKey: TSectionKeys;
    property: THasObjectArrayValues; // background
    index: number;
    secondProperty: keyof Required<IFeatureItems>[THasObjectArrayValues][number]; // color | image
    value: string;
}

// Object Array な値をもつプロパティ
type THasObjectArrayValues = "background";
type THasObjectValues = "text"|"strong";

export const reducer: React.Reducer<
    IStylingState,
	TStylingDispatch
	> = ( state , action )=>{

	switch ( action.type ){
		case "setRaw":
			return { 
                ...state,
                raw: {
                    ...state.raw,
                    [action.sectionKey]: {
                        ...state.raw[action.sectionKey],
                        [action.property]: action.value
                    }
                }
            }
        break;
        case "addRaw":
			return { 
                ...state,
                raw: {
                    ...state.raw,
                    [action.sectionKey]: {
                        ...state.raw[action.sectionKey],
                        [action.property]: "",
                    }
                }
            }
        break;
        case "setFeatureObject":

			return { 
                ...state,
                feature: {
                    ...state.feature,
                    [action.sectionKey]: {
                        ...state.feature[action.sectionKey],
                        [action.property]: {
                            ...state.feature[action.sectionKey][action.property],
                            ...action.value,
                        }
                    }
                }
            }
        break;
        case "setFeatureObjectArray":
            // 値が配列の場合、目的のINDEXの内容を更新する
            const featureObjectArray = ( state.feature[action.sectionKey][action.property] || [] );
            if ( !Array.isArray(featureObjectArray) ) throw new Error("invalid");
            
            // 配列を更新、目的のINDEXのオブジェクトの、さらに目的のプロパティを書き換える
            featureObjectArray[ action.index ] = {
                ...featureObjectArray[ action.index ],  // { color: xxx, image: xxx }レベルのオブジェクトをスプレッド
                [ action.secondProperty ]: action.value
            }
            const newFeatureObjectArray = featureObjectArray.slice()

			return { 
                ...state,
                feature: {
                    ...state.feature,
                    [action.sectionKey]: {
                        ...state.feature[action.sectionKey],
                        [action.property]: featureObjectArray.slice() // 目的のINDEXのオブジェクトをスプレッド
                    }
                }
            }
        break;
    };
};