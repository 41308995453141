
export const meta = `
	*.meta {
		text-align: right;
		//margin: 0px 10px 0px 10px;
		padding: 0 0 0 0;
		&> span, button {
			margin-left: 3px;
			&: first-child {
				margin-left: 0;
			}
		}
	}
`;