

//import {useMemo} from 'react';
//import { useFrameClient } from '../Frame/useFrameClient';

export const reactTabs = `
	.react-tabs {
		*[role="tablist"] {
			*[role="tab"] {
			}
		}
	}
`;