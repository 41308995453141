import styled from 'styled-components';
/*
使い方
	<Button className={"icon-checkmark1"}>Save</Button>&nbsp;
	<Button className={"icon-cross"}>Cancel</Button>
*/

import React,{
	useContext,
	//useState,
	//useEffect,
	//useMemo,
	//useRef,
	//useReducer
	//Suspense,
} from 'react';

import * as types from "../../types";

const SpanDraggable = styled.span`
	display: inline-block;
	color: rgba( 255,255,255,.6);
	border: none;
	cursor: grab;
	&:hover { 
		color: white;
		/background-color: rgba( 255, 255, 255, .2 );
	}
	&[disabled], &[disabled="true"] {
		&:hover { 
			background: none;
		}
	}
`;

const SpanDroppable = styled.span`
	display: inline-block;
	color: rgba( 255,255,255,.6);
	border: none;
	&:hover { 
		//color: white;
		//background-color: rgba( 255, 255, 255, .2 );
	}
	&[disabled], &[disabled="true"] {
		&:hover { 
			background: none;
		}
	}
	&.dragover {
		#article-trush {
			color: white;
			transform: scale( 2, 2 );
			 transition: transform .2s;
		}
	}
	&.dragover {
		//background: white;
	}
`;

export const Draggable: React.FC<{
	onDragStart?: (e: React.DragEvent<HTMLDivElement>)=>void;
	onDragEnd?: (e: React.DragEvent<HTMLDivElement>)=>void;
	onStartEnd?: (e: React.DragEvent<HTMLDivElement>)=>void;
	//onWithValue?: ( e: React.DragEvent<HTMLDivElement>, value: any )=>void; // ドラッグ開始も終了時も value の値をもってコールバックを実行する
	//value?: any;
	children?: React.ReactElement;
}> = ({ onDragStart, onDragEnd, onStartEnd, children })=>{

	return (
		<SpanDraggable
			data-draggable
			draggable="true"
			onDragStart={(e: React.DragEvent<HTMLDivElement>)=>{
				onDragStart && onDragStart(e);
				onStartEnd && onStartEnd( e );
			}}
			onDragEnd={(e: React.DragEvent<HTMLDivElement>)=>{
				onDragEnd && onDragEnd(e);
				onStartEnd && onStartEnd( e );
			}}
			>
				{ children ? children : null }
		</SpanDraggable>
	)
}
Draggable.displayName = "Draggable";


export const Droppable: React.FC<{
	onDrop?: (e: React.DragEvent<HTMLDivElement>)=>void;
	shouldAcceptType?: ( types: readonly string[] )=>boolean; // ドラッグオーバーで反応する transfer type
	//onWithValue?: ( e: React.DragEvent<HTMLDivElement>, value: any )=>void; // ドラッグ開始も終了時も value の値をもってコールバックを実行する
	//value?: any;
	children?: React.ReactElement;
}> = ({ onDrop, shouldAcceptType, children })=>{

	return (
		<SpanDroppable
			data-droppable
			onDragOver={(e: React.DragEvent<HTMLDivElement>)=>{
				// DragOver を Preventしないと Dropが動作しない！
				e.preventDefault();
			}}
			onDragEnter={(e: React.DragEvent<HTMLDivElement>)=>{
				if ( shouldAcceptType ){
					// shouldAcceptTypeで指定されたタイプがオーバーした時に反応する。
					if ( !shouldAcceptType( e.dataTransfer.types ) ){
						return;
					}
				}
				e.currentTarget.classList.add("dragover")
		
			}}
			onDragLeave={(e: React.DragEvent<HTMLDivElement>)=>{
				if ( shouldAcceptType ){
					// shouldAcceptTypeで指定されたタイプがオーバーした時に反応する。
					if ( !shouldAcceptType( e.dataTransfer.types ) ){
						return;
					}
				}
				e.currentTarget.classList.remove("dragover")
			}}
			onDrop={(e: React.DragEvent<HTMLDivElement>)=>{
				if ( shouldAcceptType ){
					// shouldAcceptTypeで指定されたタイプがオーバーした時に反応する。
					if ( !shouldAcceptType( e.dataTransfer.types ) ){
						return;
					}
				}
				e.currentTarget.classList.remove("dragover");
				onDrop && onDrop(e);
				//onWithValue && onWithValue( e, value );
			}}	
			>
				{ children ? children : null }
			</SpanDroppable>
	);
}
Droppable.displayName = "Droppable";




