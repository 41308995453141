
import React,{
	//useContext,
	//useState,
	//useEffect,
	//useMemo,
	//useReducer,
	//Suspense,
} from 'react';


import * as types from "../../types";
import { 
	requesterFactory
} from "./requester";

import {
	//reducer,
	TArticleDispatch,
} from "./reducer";

import {
	isApiOrAbortError,
	ApiError,
	$AbortError,
} from "../../api/useApi2";

import {
	menuTypes
} from "./Menu/menuTypes";

import {
	postTypes
} from "./postTypes";

import {
	getKeys
} from "../../util";

import { 
	Base64
} from 'js-base64';

import {
	IStylingState
} from "../helper/styling/types";

//const aaaa = getKeys( menuTypes );
//type AAA = keyof typeof menuTypes;

// helper 
type TPendingArticle = types.IArticle|ApiError|$AbortError|null;
const isNotPendingArticle = ( article: TPendingArticle ): article is types.IArticle =>{
	return !( isApiOrAbortError( article) || article === null );
}

export const controllerFactory = ( request: ReturnType<typeof requesterFactory>, dispatch: React.Dispatch<TArticleDispatch> )=>{
	const here = `articles#controller`;
	const controller = {
		getAllArticles: ()=>{
			return request.getAll().then( articles=>{
				dispatch({ type: "setArticles", articles: articles });
			});
		},
		selectArticle: ( article: types.IArticle )=>{
			dispatch({ type: "setArticle4Select", article: null });
			dispatch({ type: "setFollowers", followers: null })
			return request.getById( article.id, article.title ).then( article=>{
				dispatch({ type: "setArticle4Select", article: article });
			});
		},
		newArticle: ()=>{
			dispatch({ type: "setNull2Article4New" });
		},
		createArticle: ( $input: types.IArticle4Request )=>{
			// 新規作成
			request.create({
				title: $input.title ,
				displayDate: $input.displayDate ,
				publish: !!$input.publish ,
			}).then( res=>{
				if ( isApiOrAbortError(res) ) return;
				dispatch({ type: "addOneOfArticles", article: res })
				controller.selectArticle( res );
			});
		},
		updateArticle: ( article: types.IArticle, $input: types.IArticle4Request )=>{
			// 更新
			request.update( article.id, {
				title: $input.title ,
				displayDate: $input.displayDate ,
				publish: !!$input.publish,
			}).then( res=>{
				if ( isApiOrAbortError(res) ) return;
				dispatch({ type: "replaceOneOfArticles", article: res });
				controller.selectArticle( res );
			});
		},
		getFollowers: ( article: types.IArticle )=>{
			return request.getFollowers( article ).then( res=>{
				dispatch({ type: "setFollowers", followers: res })
				return res;
			});
		},
		createPassage: ( article: types.IArticle, prevPassage: types.IPassage|null )=>{
			return request.createPassage( article, prevPassage ).then( res=>{
				if ( isApiOrAbortError(res) ) return;
				// ここで一回 dispatch すると単体が追加・変更されるが、order 値が他と同一になるので並び方が一瞬おかしくなる。
				// 間違いではないんだけど、やめておく
				//dispatch({ type: "addPassage", passage: res });
				return controller.getFollowers( article );
			});
		},
		updateRevision: ( post: types.IPost, body: types.IPostRevision4Request, sync: boolean )=>{
			if ( !sync ){
				// textの場合非同期
				return request.updateRevision( post, body ).then( res=>{
					if ( isApiOrAbortError(res) ) return res;
					dispatch({ type: "replaceOneOfRevision", post: res.post, revision: res.revision, image: res.image  });
					return res;
				})
			} else {
				// link, image, style の場合同期的に
				return request.updateRevisionSync( post, body ).then( res=>{
					if ( isApiOrAbortError(res) ) return res;
					dispatch({ type: "replaceOneOfRevision", post: res.post, revision: res.revision, image: res.image });
					return res;
				})
			}
		},
		createPost: ( passage: types.IPassage, article: TPendingArticle, prevPost: types.IPost|null, postType: keyof typeof postTypes )=>{
			if ( isApiOrAbortError(article) || article === null  ) return ;
			return request.createPost( passage, prevPost, postType ).then( res=>{
				if ( isApiOrAbortError(res) ) return;
				// ここで一回 dispatch すると単体が追加・変更されるが、order 値が他と同一になるので並び方が一瞬おかしくなる。
				// 間違いではないんだけど、やめておく
				// dispatch({ type: "addPost", passage: passage, post: res.post, revision: res.revision })
				return controller.getFollowers( article );
			})
		},
		moveDrag: ( e: React.DragEvent<HTMLDivElement|HTMLSpanElement>, entity: types.IPassage|types.IPost|types.IArticle, entityType: "post"|"passage"|"article", type: "start"|"end" )=>{
			if ( type == "start" ){
				// Passage ドラッグ開始
				e.dataTransfer.setData(`application/article-entity-${entityType}`, entity.id );
				e.dataTransfer.dropEffect = "move";
				let el: HTMLDivElement|null;
				el = document.querySelector<HTMLDivElement>(`#${entityType}-${entity.id} *[data-drag-image]`);
				if ( !el ){
					el = document.querySelector<HTMLDivElement>(`#${entityType}-${entity.id}`);
				}
				//const clone = el ? el.cloneNode(true) : null ;
				//clone && e.dataTransfer.setDragImage( clone, clone.offsetWidth -20, clone.offsetHeight -20 );
				el && e.dataTransfer.setDragImage( el, el.offsetWidth -20, el.offsetHeight -20 );
				dispatch({  type: "drag&drop4Move", entityType: entityType, id: entity.id })
			} else if ( type == "end" ){
				// Passageドラッグ終了
				dispatch({  type: "drag&drop4Move", entityType: entityType, id: null })
			}
		},
		moveDrop: ( e: React.DragEvent<HTMLDivElement|HTMLSpanElement>, article: TPendingArticle, toEntity: types.IPassage|types.IPost|null, toEntityType: "post"|"passage", parentId: string|null )=>{
			 e.preventDefault();
			 if ( isApiOrAbortError(article) || article === null  ) return ;
			 

			 const draggingId = e.dataTransfer.getData(`application/article-entity-${toEntityType}`);

			 if ( toEntityType === "post" ){
				 return request.movePost( draggingId, (toEntity as types.IPost), parentId ).then( res=>{
				 	if ( isApiOrAbortError(res) ) return;
				 	// 順番を変えたあとに再描画
				 	controller.getFollowers( article );
				 })
			} else if ( toEntityType === "passage" ){
				return request.movePassage( draggingId, (toEntity as types.IPassage) ).then( res=>{
					if ( isApiOrAbortError(res) ) return;
				 	// 順番を変えたあとに再描画
				 	controller.getFollowers( article );
				})
			} else {
				throw new Error(`invalid toEntityType[ ${toEntityType} ]`);
			}
		},
		deleteDrop: ( e: React.DragEvent<HTMLDivElement|HTMLSpanElement> )=>{
			e.preventDefault();
			const postId = e.dataTransfer.getData(`application/article-entity-post`);
			const passageId = e.dataTransfer.getData(`application/article-entity-passage`);
			const articleId = e.dataTransfer.getData(`application/article-entity-article`);
	
			if ( postId ){
				return request.deletePost( postId ).then( res=>{
					if ( isApiOrAbortError(res) ) return;
					dispatch({ type: "deletePost", postId });
				});
			} else if ( passageId ){
				return request.deletePassage( passageId ).then( res=>{
					if ( isApiOrAbortError(res) ) return;
					dispatch({ type: "deletePassage", passageId });
				});
			} else if ( articleId ){
				return request.deleteArticle( articleId ).then( res=>{
					if ( isApiOrAbortError(res) ) return;
					dispatch({ type: "deleteArticle", articleId });
				});
			} else {
				throw new Error("postId & passageId are invalid");
			}
		},
		dragMenu: ( 
			e: React.DragEvent<HTMLDivElement|HTMLSpanElement>, 
			value: { menuType: keyof typeof menuTypes, entityType: "passage"|"post" }
			)=>{

			if ( e.type == "dragstart" ){
				// Passage ドラッグ開始
				e.dataTransfer.setData(`application/article-menu-${value.entityType}`, value.menuType );
				e.dataTransfer.dropEffect = "copy";
				dispatch({  type: "drag&drop4Menu", menuType: value.menuType, entityType: value.entityType });

			} else if ( e.type == "dragend" ){
				// Passageドラッグ終了
				dispatch({  type: "drag&drop4Menu", menuType: null, entityType: value.entityType });
			}
		},
		dropMenu: ( 
			// todo: PostやPassageを更新した時に getFollowers(article) で全部書き換えるから、article がどうしても欲しくなる。( article.idだと string型だかた型判定弱くて嫌 )
			// 		 controller.ts は　フックじゃないから、いちいち取得してこないといけないのが煩雑だ。
			e: React.DragEvent<HTMLDivElement|HTMLSpanElement>, 
			param: { entityType: "passage", entity: null, article: TPendingArticle } // 新規passage、articleの中で一番先頭
					| { entityType: "passage", entity: types.IPassage, article: TPendingArticle  } // 新規passage prevPassageの次に
					| { entityType: "post", entity: null, passage: types.IPassage, article: TPendingArticle } // 新規post、passageの中で一番先頭
					| { entityType: "post", entity: types.IPost, passage: types.IPassage, article: TPendingArticle } // 新規post、prevPostの次に
			)=>{
			const postMenu = e.dataTransfer.getData(`application/article-menu-post` );
			const passageMenu = e.dataTransfer.getData(`application/article-menu-passage` );

			// 実処理
			if ( passageMenu == menuTypes.addPassage ) {
				// Passage関係
				if ( param.entityType == "passage" && param.entity === null && isNotPendingArticle( param.article ) ) {
					// 新規passage、articleの中で一番先頭
					return controller.createPassage( param.article, null );
				} else if ( param.entityType == "passage" && param.entity !== null && isNotPendingArticle( param.article ) ) {
					// 新規passage prevPassageの次に
					return controller.createPassage( param.article, param.entity );
				} else {
					throw new Error(`invalid menu param! ${JSON.stringify(param)}`);
				}

			} else if ( postMenu == menuTypes.addTextPost
						|| postMenu == menuTypes.addImagePost
						|| postMenu == menuTypes.addLinkPost
				 ) {
				// Post関係

				// Postのタイプを決める
				let postType: keyof typeof postTypes = (()=>{
					if ( postMenu == menuTypes.addTextPost ) {
						return postTypes.text;
					} else if ( postMenu == menuTypes.addImagePost ) {
						return postTypes.image;
					} else  if ( postMenu == menuTypes.addLinkPost ) {
						return postTypes.link;
					} else {
						throw new Error(`invalid post type`);
					}
				})()

				if ( param.entityType == "post" && param.entity === null && param.passage !== null && isNotPendingArticle( param.article ) ) {
					// 新規post、passageの中で一番先頭
					return controller.createPost( param.passage, param.article, null, postType );
				} else if ( param.entityType == "post" && param.entity !== null && param.passage !== null && isNotPendingArticle( param.article ) ) {
					// 新規post、prevPostの次に
					return controller.createPost( param.passage, param.article, param.entity, postType );
				} else {
					throw new Error(`invalid menu param! ${JSON.stringify(param)}`);
				}
			}
			console.log("drop menu param ===>", param, "postMenu==>", postMenu, "passageMenu==>", passageMenu );
		},
		uploadImage: ( binary: string|ArrayBuffer )=>{
			return request.uploadImage( binary ).then( res=>{
				if ( isApiOrAbortError(res) ) return res;
				return res;
			})
		},
		getImage: ( imageId: string )=>{
			return request.getImage( imageId ).then( res=>{
				if ( isApiOrAbortError(res) ) return res;
				
				return res;
			})
		},
		savePassageStyle: ( passage: types.IPassage, stylingState: IStylingState )=>{
			return request.updatePassage( passage, stylingState ).then( res=>{
				if ( isApiOrAbortError(res) ) return;
				return res;
			});
		},
		publish: ()=>{
			return request.publish().then( res=>{
				if ( isApiOrAbortError(res) ) return;
				return res;
			});
		}
	}// controller
	return controller;
}