import React,{
	useContext,
	useState,
	useEffect,
	useMemo,
	//useRef,
	useReducer
	//Suspense,
} from 'react';

import moment from 'moment';

import * as types from "../../../../types";

// context
import {ArticleContext} from "../../index";
import {AppContext} from "../../../../app";

//import {ReadyContext} from "../../ready/Ready";
import styled from 'styled-components';




import {
	P1,
} from "../../styled";

import {
	Move,
	Drop4Move,
} from "../../move"

import {
	meta_el,
	//Button,
	Loader,
	Button,
	Droppable,
	Sign,
	
} from "../../../helper"


import {
	//getKeys,
	//debounce,
} from "../../../../util"



import {
	ApiError,
	$AbortError,
	isApiOrAbortError,
} from '../../../../api/useApi2';


import {
	parseText,
	toJsxElements,
	toMyElements,
	//lf2BrTag,
	//toNode,
} from "../../parseText2";

import {
	postTypes
} from "../../postTypes";


import {
	TUpdate
} from "./index";


export const PostDiv = styled.div`
`
export const RevisionBody = styled.div`
	
`


//
//	props.article が null の場合は新規作成とする
//
export const ImagePost: React.FC<{ 
		image: types.IImage | null;
		revision: types.IPostRevision;
		post: types.IPost;
		passage: types.IPassage;
		article: types.IArticle;
		update: TUpdate;
	}> = ({ revision, post, passage, article, update, image })=>{
	const here = "pages#Articles#Followers#Passage#Post#ImagePost";
	console.time(`in ${here}`);
	const { articleState, controller } = useContext( ArticleContext );

	useEffect( ()=>{
		console.debug(`in ${here}, mounted.`);
		return ()=>console.debug(`in ${here}, unmounted.`)
	}, [] );

	// IMAGE
	const [ currentImage, setCurrentImage ] = useState<types.IImage|null>( ()=>image );

	/*useEffect( ()=>{
		console.log("==> 1", revision );
		if ( revision.imageId !== null ){
			console.log("==> 2");
			if ( currentImage === null || revision.imageId !== currentImage.id ){
				console.log("==> 3");
				// currentImageが無いか、revision.imageId(最新の値)が currentImage に反映されていない
				controller.getImage( revision.imageId ).then( res=>{
					if ( isApiOrAbortError(res) ){
						return;
					}
					console.log("==> 4");
					setCurrentImage( res.image );
				});
			}
		}
	}, [ revision.imageId ] );
	*/

	//
	const onCange = ( e: React.ChangeEvent<HTMLInputElement> )=>{
		// debounce で　updateするぞ
	}

	const upload = ( e: React.ChangeEvent<HTMLInputElement> )=>{
		console.log( "===>", e.currentTarget.value );
		//controller.upload(  );
	}

	//
	const save = ( image: types.IImage )=>{
		//if ( currentImage === null ){
		//	return;
		//}
		update( { imageId: image.id }, post, revision, postTypes.image ).then( res=>{
			if ( isApiOrAbortError(res) ){
				return;
			}
			setCurrentImage( image );
		});
	}


	console.timeEnd(`in ${here}`);
	const src = currentImage ? `${process.env.CDN_URL_BASE||""}/${currentImage.objectKey}_min.${currentImage.ext}` : null;

	return ( 
		<div
			id={`revision-${revision.id}`}
			className="revision-image-body"
			data-panedisabledtarget={true}
			>
			<Droppable
				shouldAcceptType={types=>types.includes("Files")}
				onDrop={(e)=>{
					e.preventDefault();
					const files = e.dataTransfer.files;
					const fileReader = new FileReader();
					fileReader.readAsDataURL(files[0]);
					fileReader.onload = ()=>{
						const binary = fileReader.result;
						//controller.upload( binary );
						if ( binary === null ) throw new Error(`invalid binary`)
						controller.uploadImage( binary ).then( res=>{
							if ( isApiOrAbortError(res) ) throw new Error(`invalid res`);
							save( res.image );
						});
					}
				}}
				>
				<div className="image-drop">
					{
						src === null  ?
							<p><Sign className="icon-info"/>画像をここにドロップしてください</p>
							:
							<img crossOrigin="anonymous" src={ src } />
					}
				</div>
			</Droppable>
			<div className="meta">
				{ meta_el( "revision image id", revision.imageId ) }
				{ meta_el( "currentImage id", currentImage ? currentImage.id : "---" ) }
				{ meta_el( "created at", currentImage ? currentImage.createdAt : "---" ) }
				{ meta_el( "updated at", currentImage ? currentImage.updatedAt : "---" ) }
			</div>
			{/*<P1>
				<input id={`revision-${revision.id}-file`} type="file" accept="currentImage/*" value="" onChange={upload} />
			</P1>*/}
			{/*
			<P1 className="right">
				<Button 
					className={"icon-checkmark"}
					onClick={save}
					>&nbsp;Save</Button>&nbsp;
			</P1>
			*/}
		</div>
	);
};
ImagePost.displayName = "ImagePost"
