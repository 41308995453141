

import React,{
	//useContext,
	//useState,
	//useEffect,
	useMemo,
	//useRef,
	//useReducer
	//Suspense,
} from 'react';

import {
    colors,
} from "./colors";

import {
    Modal,
    TModalClient,
} from "../../../portal/modal"

type TColors = typeof colors;

export const ColorPallet: React.FC<{
    //id: string;
    //modalDispatch:  TModalDispatch;
    //currentName?: string, // 色の名前で指定
    currentValue?: string|null, // 色の値で指定
    commit: (value:string)=>void;
    client: TModalClient;
}> = ( props )=>{
    let style:{[x:string]:string} = {};


    return (
        <>

            <Modal 
                className="modal-colorpallet" 
                open={false} 
                client={props.client}
                data-modal-colorpallet
                >
                
                {
                    colors.map( ({ name, value })=>{
                        //const isCurrent = ( name == props.currentName || value == props.currentValue );
                        const isCurrent = ( value == props.currentValue );
                        return (
                            <div 
                                key={name}
                                title={name}
                                className={`
                                    colorPallet-color colorPallet-color-${name} ${ isCurrent ? "colorPallet-color-current" : ""}
                                `}

                                data-value={value}
                                    
                                style={ Object.assign( {
                                    backgroundColor: value
                                }, style ) }

                                onClick={e=>{
                                    //const value = e.currentTarget.dataset.value;
                                    //if ( !value ) return;
                                    props.commit(value);
                                    props.client.current && props.client.current.setOpen(false);
                                }}
                                />
                        );
                    })
                }
            </Modal>
        </>
    );
};
ColorPallet.displayName = "ColorPallet";
