import React,{
	//useContext,
	useState,
	//useEffect,
	//useMemo,
	useRef,
	//useReducer
	//Suspense,
} from 'react';

import {
	IStylingState,
	IFeatureItems,
	TStylingDispatch,
} from "../types";

import * as types from "../../../../types";

import {
	ColorSelected,
} from "./colorSelected";

import {
	ImageSelected,
} from "./imageSelected";

import {
	ColorPallet
} from "../../colorPallet";

import {
	Gallery
} from "../../gallery";

import {
    IModalClientProperties
} from "../../../../portal/modal"


export const Background: React.FC<{
    styleItems: IFeatureItems;
    sectionKey: keyof IStylingState[keyof IStylingState];
    stylingDispatch: React.Dispatch<TStylingDispatch>;
}> = ( props )=>{

    

    return (
        <>
                {
                    ( props.styleItems.background || []).map( ( bg, idx )=>{
                        const bgImage = bg.image ? ( JSON.parse( bg.image ) as types.IImage ) : null;

                        const client4colorPallet = useRef<IModalClientProperties|null>(null);
                        const client4gallery = useRef<IModalClientProperties|null>(null);

                        return (
                            <>
                                <div className="secontItem">
                                    <div className="label">
                                        color
                                    </div>
                                    <div className="value">
                                        <ColorSelected 
                                            colorValue={bg.color}
                                            onClick={()=>client4colorPallet.current && client4colorPallet.current.setOpen(true) }
                                            />
                                    </div>
                                </div>
                                
                                <div className="secontItem">
                                    <div className="label">
                                        image
                                    </div>
                                    <div className="value">
                                        <ImageSelected
                                            image={bgImage}
                                            onClick={()=>client4gallery.current && client4gallery.current.setOpen(true) }
                                            />
                                    </div>
                                </div>
                                
                                <ColorPallet
                                    currentValue={bg.color||undefined}
                                    client={client4colorPallet}
                                    commit={ value=>{
                                        props.stylingDispatch({
                                            type: "setFeatureObjectArray",
                                            sectionKey: props.sectionKey,
                                            property: "background",
                                            index: idx,
                                            secondProperty: "color",
                                            value: value,
                                        })
                                    }}
                                    />
                                    
                                <Gallery
                                    currentImage={ bgImage }
                                    client={client4gallery}
                                    commit={ image=>{
                                        props.stylingDispatch({
                                            type: "setFeatureObjectArray",
                                            sectionKey: props.sectionKey,
                                            property: "background",
                                            index: idx,
                                            secondProperty: "image",
                                            value: JSON.stringify(image),
                                        })
                                    }}
                                    />
                            </>
                        );
                    })// styleItems.background.map( bg=>{
                }
                
        </>
    );
}

Background.displayName = "Background";