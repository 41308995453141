import React,{
	//useContext,
	//useState,
	//useEffect,
	//useMemo,
	//useRef,
	//Suspense,
} from 'react';

import moment from 'moment';

import styled from 'styled-components';

const Span1 = styled.span`
	/*
	font-family: 'Odibee Sans';
	font-family: "Oswald";
	font-family: 'Peddana';
	font-family: 'Teko';
	*/
	font-family: 'Open Sans Condensed';

	margin-left: 6px;
	font-size: 5pt;
	
	&:first-child {
		margin-left: 0px;
	}
	opacity: .5;
	&:hover {
		opacity: .6;
	}
`;

const SpanLabel = styled.span`
	
	

`;
const SpanValue = styled.span`
	
`;

//
//
// data-meta
// 2020-12-14T02:31:51.000Z
export const meta_el = ( label: string, value: string|number|boolean|null|undefined|Date )=>{
	if ( value instanceof Date || moment.isMoment( value ) || ( typeof value == "string" && (/\w{2}\-\w{2}\-\w{2}T\w{2}:\w{2}:\w{2}\.\w{3}Z/).test(value) ) ){
		return ( 
			<Span1 title={ label + ": " + moment( value ).format("YY/MM/DD HH:mm:ss") }>
				<SpanLabel>{ label }</SpanLabel>:&nbsp;
				<SpanValue>{ moment( value ).format("YY/MM/DD HH:mm:ss") }</SpanValue>
			</Span1> 
		);
	} else if ( typeof value == "string" ){
		return ( 
			<Span1 title={ label + ": " + value }>
				<SpanLabel>{ label }</SpanLabel>:&nbsp;
				<SpanValue>{ value }</SpanValue>
			</Span1> 
		);
	} else if ( typeof value == "number" ){
		return ( 
			<Span1 title={ label + ": " + value }>
				<SpanLabel>{ label }</SpanLabel>:&nbsp;
				<SpanValue>{ value }</SpanValue>
			</Span1> 
		);
	} else if ( typeof value == "boolean" ){
		return ( 
			<Span1 title={ label + ": " + value }>
				<SpanLabel>{ label }</SpanLabel>:&nbsp;
				<SpanValue>{ value?"Yes":"No" }</SpanValue>
			</Span1> 
		);
	} else if ( value === undefined ){
		return ( 
			<Span1 title={ label + ": " + value }>
				<SpanLabel>{ label }</SpanLabel>:&nbsp;
				<SpanValue>undefined</SpanValue>
			</Span1> 
		);
	} else if ( value === null ){
		return ( 
			<Span1 title={ label + ": " + value }>
				<SpanLabel>{ label }</SpanLabel>:&nbsp;
				<SpanValue>NULL</SpanValue>
			</Span1> 
		);
	}
}
meta_el.displayName = "meta_el";