//import { Module } from 'module';
import React,{
	//useContext,
	useState,
    useEffect,
	//useMemo,
	//useRef,
	//useReducer
    //Suspense,
    ComponentProps,
} from 'react';

import {
    Portal
} from "../index";

export interface IModalClientProperties {
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export type TModalClient = React.MutableRefObject<IModalClientProperties|null>

// DIVが取りうるプロパティ
type TDivProps = ComponentProps<"div">;


/*
    // ボタンでモーダルを開くサンプル
    const client = useRef<IModalClientProperties|null>(null);
    return (
        <Modal className="colorPallet" open={false} client={client}>
            { children }
        </Modal>
        <buttton onClick={()=>client.current && client.current.setOpen(true) }
    );

*/

interface IProps extends TDivProps {
    open?: boolean;
    defaultStyle?: boolean;
    onClose?: ()=>void;
    client?: TModalClient;
}

export const Modal: React.FC<
    IProps
> = ( props )=>{



    const [ open, setOpen ] = useState( !!props.open );
    
    useEffect( ()=>{
        setOpen( !!props.open );
    }, [props.open] );  

    useEffect( ()=>{
        if ( props.client ){
            // 外部から操作できるようにする
            props.client.current = { 
                setOpen 
            };
        }
    }, [] );  

    // 閉じる操作
    const close = ()=>{
        props.onClose && props.onClose();
        setOpen(false);
    }

    // props は全て コンポーネントに適用する。その時邪魔なものを削除する。
    const omit = ( props: any )=>{
        const xxx = Object.assign( {}, props );
        delete xxx["open"];
        delete xxx["defaultStyle"];
        delete xxx["onClose"];
        delete xxx["client"];
        return xxx;
    }


    const defaultStyle = {
        position: "fixed",
        left: 0, 
        top: 0,
        right: 0, 
        bottom: 0,
        width: "30vw",
        height: "30vh",
        zIndex: 2,
        backgroundColor: "gray",
    } as const;

    
    return (
        <Portal>
            {
                open ? 
                    <>
                        <Overlay onClick={ e=>close() } >
                            <div 
                                style={ props.defaultStyle ? defaultStyle : {} }
                                { ...omit(props) }
                                className={ `portal-modal ${ props.className ||  ""}` }
                                >
                                { props.children }
                            </div>
                        </Overlay>
                    </>
                    :
                    null
            }
        </Portal>
    );
}
Modal.displayName="Modal";


const Overlay: React.FC<{
    onClick?: ( e: React.MouseEvent )=>void;
}> = ( props )=>{

    const defaultStyle = {
        position: "fixed",
        left: 0, 
        top: 0,
        width: "100%",
        height: "100%",
        zIndex: 1,
        background: "rgba(0,0,0,.8)",
    } as const;

    return (
        <div
            style={defaultStyle}
            className="portal-modal-overlay"
            onClick={ e=>{
                e.stopPropagation();
                props.onClick && props.onClick(e);
             }}
            >
                { props.children }
        </div>
    );
}
Overlay.displayName="Overlay";