
import React,{
	//useContext,
	//useState,
    useEffect,
	//useMemo,
	//useRef,
	//useReducer
	//Suspense,
    ComponentProps,
} from 'react';

import ReactDOM from 'react-dom';

// DIVが取りうるプロパティ
type TDivProps = ComponentProps<"div">;


const rootEl = document.getElementById('root');

interface IProps extends TDivProps {
}


export const Portal: React.FC<
    IProps
> = ( props )=>{

    if ( !rootEl ) return null;
    
    return (
        ReactDOM.createPortal(
            <div 
                { ...props }
                className={`${ props.className || ""} portal`}
                >
                {props.children}
            </div>
            ,
            rootEl
        )
    );
};

Portal.displayName = "Portal";