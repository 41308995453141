import styled from 'styled-components';
import React,{
	//useContext,
	//useState,
	//useEffect,
	//useMemo,
	//useRef,
	//useReducer
	//Suspense,
} from 'react';

export const Sign = ( props: any )=>{
	return (
		<span {...props} data-sign >
		</span>
	);
}
Sign.displayName="Sign";