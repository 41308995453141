import React,{
	useContext,
	useState,
	useEffect,
	useMemo,
	useRef,
	useReducer
	//Suspense,
} from 'react';

import moment from 'moment';

import * as types from "../../../types";

// context
import {ArticleContext} from "../index";
import {AppContext} from "../../../app";
//import {ReadyContext} from "../../ready/Ready";
import styled from 'styled-components';




import {
	P1,
	P2,
} from "../styled";

import {
	Move,
	Drop4Move,
} from "../move"



import {
	meta_el,
	Button,
	Loader,
	Droppable,
	Sign,
	
	//Style,
	Styling,
} from "../../helper"

/*
import {
	getKeys,
	debounce,
} from "../../../util"
*/

import {
	ApiError,
	$AbortError,
	isApiOrAbortError,
} from '../../../api/useApi2';

import {
	postTypes
} from "../postTypes"

//
//	props.article が null の場合は新規作成とする
//
export const Passage: React.FC<{ 
		passage: types.IPassage,
		children: React.ReactNode;
	}> = ({ passage, children })=>{
	const here = "pages#Articles#Followers#Passage";
	console.time(`in ${here}`);
	const { articleState, controller } = useContext( ArticleContext );

	//Stylingコンポーネントの変更を styleタグを作って反映する
	const [ localStyle, setLocalStyle ] = useState<{
		body: string;
		strong: string;
	}>({
		body: "",
		strong: "",
	})

	const [ stylingShow, setStylingShow ] = useState(false);
	

	useEffect( ()=>{
		console.debug(`in ${here}, mounted.`);
		return ()=>console.debug(`in ${here}, unmounted.`)
	}, [] );



	console.timeEnd(`in ${here}`);
	// article が nullの場合は 編集画面(新規作成)、articleに値がある場合は編集画面か表示画面
	return ( 
			<>
			<style type="text/css">
				{[
					`#passage-${passage.id} {`,
						localStyle.body,
					`}`,
					`#passage-${passage.id} strong {`,
						localStyle.strong,
					`}`,
				].join("\n")}
			</style>

			<div 
				className="passage" 
				key={passage.id}
				id={`passage-${passage.id}`}
				>
				<div style={{ position: "relative" }}>
					{
						// PASSAGE用 ドロップゾーンを表示、基本的に bottom に表示されるのであえて div をここにおく
						articleState.editor.dragging4postId ?
							<Drop4Move entity={null} entityType={"post"} parentId={passage.id} />
							:
							null
					}
					{
						articleState.editor.dragging4menu4post ?
							<Droppable 
								onDrop={(e)=>controller.dropMenu( e, { entity: null, entityType: "post", passage: passage, article: articleState.article } )} 
								shouldAcceptType={ types=>types.some( t=>RegExp("^application/article-menu-").test(t) )}
								><div className="move-drop" /></Droppable>
							:
							null
					}
				</div>
				{/* data-drag-image がつくものが drag時にゴーストとして使われる */}
				<div data-drag-image >
					{
						children
					}
				</div>
				<div className="meta">
					{ meta_el( "id" ,passage.id ) }
					{ meta_el( "created at", passage.createdAt ) }
					{ meta_el( "updated at", passage.updatedAt ) }
					{ meta_el( "order", passage.order ) }
					&nbsp;
					<Button className={"icon-plus"} onClick={()=>controller.createPost( passage, articleState.article, null, postTypes.text )} title="テキストpost作成" >
						&nbsp;Post
					</Button>
					・
					<Move entity={passage} entityType={"passage"}>
						&nbsp;Move
					</Move>
					・
					<Button className={"icon-equalizer"} onClick={(e: React.MouseEvent<HTMLButtonElement>)=>{
						setStylingShow( prev=>!prev );
					}} title="スタイル変更" >
						&nbsp;Style
					</Button>
				</div>

				< Styling 
					entityType={"passage"} 
					entity={passage}
					setLocalStyle={setLocalStyle}
					show={stylingShow}
					save={ stylingState=>{
						
						controller.savePassageStyle( passage, stylingState );
					}}
					/>
				
				{
					/* 自分以外のドロップゾーンを表示 */
					(articleState.editor.dragging4passageId && articleState.editor.dragging4passageId != passage.id) ?
						<Drop4Move entity={passage} entityType={"passage"} />
						:
						null
				}
				{
					articleState.editor.dragging4menu4passage ?
						<Droppable 
							onDrop={(e)=>controller.dropMenu( e, { entity: passage, entityType: "passage", article: articleState.article } )}
							shouldAcceptType={ types=>types.some( t=>RegExp("^application/article-menu-").test(t) )}
							><div className="move-drop" /></Droppable>
						:
						null
				}
			</div>
		</>
	);
};
Passage.displayName = "Passage";
