
export const styling = `
	.styling {
		

		.sectionTab {
			background-color: black;
		}
		.sectionPanel {
			background-color: black;
		}
		.typeTab {
			background-color: black;
		}
		.typePanel {
			background-color: black;
		}
		*[role="tablist"] {
			display: flex;
			overflow: hidden;
			margin: 0;
			padding: 0;
			list-style-type: none;
		}
		*[role="tab"] {
			cursor: pointer;
			list-style-type: none;
			width: 15%;
			border-radius: 3px 3px 0 0;
			padding: 2px 8px;
			
			color: rgba( 255,255,255, .5 );
			border-left: 1px solid white;
			border-top: 1px solid white;
			border-right: 1px solid white;
			
			margin-left: 2px;
			&:first-child { margin-left: 0 }
			&:focus, &:active { outline: none }
			
			&[aria-selected="true"]{
				color: white;
			}
		}
		li.border-bottom {
			font-size: 0;
			width: calc( 100% - 15% - 15% );
			border-bottom: 1px solid white;
			display: inline-block;
		}
		.panel {
			// TabPanel そのものにスタイルをつけてはいけない。常に表示されているものだから。
			padding: 5px 5px;
			width: 100%;
			border-left: 1px solid white;
			border-bottom: 1px solid white;
			border-right: 1px solid white;
		}

		.heading {
			font-weight: normal;
			font-size: 10pt;
			font-family: 'Open Sans Condensed';
		}
		input {
			width: 100%;
			font-size: 10pt;
		}
		table {
			width: 100%;
			border-collapse: collapse;
			margin: 10px 0 0 0;
			font-family: 'Open Sans Condensed';
			font-size: 9pt;
			color: rgba( 255,255,255, .6 );

			th {
				width: 10%;
				text-align: right;
			}
			td,th {
				vertical-align: middle;
				padding: 2px 5px;
				font-weight: normal;
				//border-left: 1px dotted rgba( 0,0,0, .3 );
				&: first-child {
					border: none;
				}
			}
			tr {
				border-top: 1px dotted rgba( 255,255,255,.3 );
				&: first-child {
					border: none;
				}
				&: hover {
					color: rgba( 255,255,255, .8 );
				}
			}
			
		}//table

		.secontItem{
			//display: inline-block;
			display: inline-flex;
			align-items: center;
			//width: 150px;
			margin-left: 10px;
			&:first-child {
				mmargin-left: 0;
			}
			.label {
				text-align: right;
    			padding: 5px;
			}
			.value {
				//width: 100px;
			}
		}

		.color-selected {
			cursor: pointer;
			.color-selected-cube {
				width: 50px;
				height: 50px;
			}
		}

		.image-selected {
			cursor: pointer;
			.image-selected-img {
				width: 50px;
				height: 50px;
				overflow: hidden;
				position: relative;
				display: inline-block;
	
				img {
					width: 80px;
					position: absolute;
					left: -1000px;
					right: -1000px;
					top: -1000px;
					bottom: -1000px;
					margin: auto;
				}
			}
		}

	}//.styling {

`;