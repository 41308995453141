import React,{
	useContext,
	useState,
	useEffect,
	useMemo,
	//useRef,
	useReducer
	//Suspense,
} from 'react';

import moment from 'moment';

import * as types from "../../../types";



// context
import {ArticleContext} from "../index";
import {AppContext} from "../../../app";
//import {ReadyContext} from "../../ready/Ready";
import styled from 'styled-components';




import {
	P1,
	P2,
} from "../styled";

import {
	Drop4Move,
} from "../move"

import {
	Button,
	Sign,
	Draggable, Droppable,
	Toggle,
} from "../../helper"


// menuTypes
import {
	menuTypes
} from "./menuTypes";

export {menuTypes}



// API
import {
	ApiError,
	$AbortError,
	isApiOrAbortError,
} from '../../../api/useApi2';


//
//	props.article が null の場合は新規作成とする
//
export const Menu: React.FC = ()=>{
	const here = "pages#Articles#Menu";

	const { articleState, controller } = useContext( ArticleContext );

	useEffect( ()=>{
		console.debug(`in ${here}, mounted.`);
		return ()=>console.debug(`in ${here}, unmounted.`)
	}, [] );
	
	if ( articleState.article === null ) {
		return null;
	} else if ( articleState.article instanceof ApiError ) {
		return null;
	} else if ( articleState.article instanceof $AbortError ) {
		return null;
	}


	if ( isApiOrAbortError( articleState.article ) || articleState.article === null ){
		throw new Error("invalid article"); // type対策。IArticle か null に絞る
	}

	if ( articleState.followers === null ) {
		return null;
	} else if ( articleState.followers instanceof ApiError ) {
		return null;
	} else if ( articleState.followers instanceof $AbortError ) {
		return null;
	}

	const followers = articleState.followers ;
	if ( isApiOrAbortError( followers ) || followers === null ){
		throw new Error("invalid  followers"); // type対策。IArticle か null に絞る
	}



	// article が nullの場合は 編集画面(新規作成)、articleに値がある場合は編集画面か表示画面
	return ( 
		<div id="article-menu-inner">
			<Draggable onStartEnd={ (e)=>controller.dragMenu( e, { menuType: menuTypes.addPassage, entityType: "passage" } ) } data-panedisabledtarget={true} ><Sign className="icon-insert-template" title="passage作成"/></Draggable>

			<div style={{ borderBottom: "1px dotted rgba(255,255,255, .3 )", paddingTop: "15px", marginBottom: "15px" }} />

			<Draggable onStartEnd={ (e)=>controller.dragMenu( e, { menuType: menuTypes.addTextPost, entityType: "post" } )} data-panedisabledtarget={true} ><Sign className="icon-text-box" title="テキストpost作成"/></Draggable>
			<Draggable onStartEnd={ (e)=>controller.dragMenu( e, { menuType: menuTypes.addImagePost, entityType: "post" } )} data-panedisabledtarget={true} ><Sign className="icon-image" title="画像post作成"/></Draggable>
			<Draggable onStartEnd={ (e)=>controller.dragMenu( e, { menuType: menuTypes.addLinkPost, entityType: "post" } )} data-panedisabledtarget={true} ><Sign className="icon-link" title="リンクpost作成"/></Draggable>

			<div style={{ borderBottom: "1px dotted rgba(255,255,255, .3 )", paddingTop: "15px", marginBottom: "15px" }} />
		
			{/* 
			<Toggle group={"align"} ><Sign className="icon-align-left" title="文章左寄せ" data-panedisabledtarget={true} /></Toggle>
			<Toggle group={"align"} ><Sign className="icon-align-center" title="文章中央寄せ" data-panedisabledtarget={true} /></Toggle>
			<Toggle group={"align"} ><Sign className="icon-align-right" title="文章右寄せ" data-panedisabledtarget={true} /></Toggle>
			*/}
			
			<div style={{ borderBottom: "1px dotted rgba(255,255,255, .3 )", paddingTop: "15px", marginBottom: "15px" }} />

			<Droppable onDrop={controller.deleteDrop} shouldAcceptType={types=>types.some( t=>RegExp("^application/article-entity-").test(t) )}>
				<Sign className="icon-trash" title="ここにドロップで削除" id="article-trush" data-panedisabledtarget={true} />
			</Droppable>

		</div>
	);
};
Menu.displayName = "Menu";
