
export const native = `
	* {
		box-sizing: border-box;
	}
	body {
		//background-color: white;
		background-color: black;
		padding: 0;
		margin: 0;
		color: #DDD;
		font-size: .8em;
		font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Segoe UI", "Yu Gothic", YuGothic, sans-serif;
		position: relative;
	}
	img {
		vertical-align: bottom;
	}
	button {
		cursor: pointer;
	}
	details, summary, button {
		&:focus {
			outline:0;
		}
	}
	h1,h2 {
		font-size: 9pt;
	}
	section {
		padding: 10px 10px; 
		//padding: 5px 0px; 
	}
	ul[data-process]{
		padding:0;
		list-style: none;
		font-size: 8pt;
	}
	a {
		color: #8CF;
	}

	button {
		&:focus { 
			outline:0; // クリック時の青い枠線を消す
		}; 
	}

	input {
		padding: 5px 5px;
		margin: 1px 0px;
		border: none;
		//border-radius: 2px;
		//border-bottom: 1px solid rgba(255,255,255, .3 );
		//background-color: transparent;
		background-color: rgb( 0,0,0, .1 );
		background-image: linear-gradient( to bottom,rgba( 0,0,0,0 ) 90%,rgba( 0,0,0,.05 ) );
		color: rgba( 255,255,255, .85 );

		// background-image: linear-gradient( to top , #FFFFFF 90%, #303030 100% );
		&:focus { 
			outline:0; // クリック時の青い枠線を消す
		}; 
		&[disabled], &[disabled="true"] {
			//background-color: transparent;
			background-image: none;
		}
		&[readonly], &[eadonly="true"] {
			//background-color: transparent;
			background-image: none;
		}
		&:focus {
			background-color: rgb( 0,0,0, .18 );
			//color: rgba( 255,255,255, 1 );
		}
		&:hover {
			background-color: rgb( 0,0,0, .18 );
			//color: rgba( 255,255,255, 1 );
		}
		&.changed {
			//color: rgba( 155, 155, 0, 9 );
		}
	}

	strong, b {
		color: red
	}
`;