import React,{
} from 'react';

import styled from 'styled-components';

// http://www.netyasun.com/home/color.html
const loaderImg = require ( "./ZZ5H.gif" );

const Div = styled.div`
	width: 100%;
	height: 100%;
	background: rgba( 255, 255, 255, .1 );
`;

const Div2 = styled.div`
	width: 50px;
	height: 50px;
	position: absolute;
	left:0;right:0;top:0;bottom:0;
	margin auto;
	img {
		width: 50%;
	}
`;

export const Loader = ()=>{
	return (
		<Div data-loader-outside>
			<Div2 data-loader-inside >
				<img src={loaderImg} />
			</Div2>
		</Div>
	);
};

