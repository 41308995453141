
import React, { 
	useEffect, 
	useRef,
	useState,
	useMemo,
} from 'react';

import styled from 'styled-components';

import {throttle} from "./throttle";

import {TResize} from "./Pane";

interface IProps {
	gripKey: "top" | "bottom" | "left" | "right";
	resizePane: TResize;
}

// react 管理外
//let dragStartPosition = { x:0, y:0 }

//
//	サイズ変更 のグリップ（ハンドル）
//
const hover = `
	background: gray; 
	border-radius: 3px; 
	//border: 1px solid gray;
	//opacity: 0.5;
`;

const Div = {
	top: styled.div`
		position: absolute; cursor: row-resize; z-index: 3;
		left: 0; top: -2px; 
		height: 5px;
		width: 100%;
		&:hover { ${hover} }
	`,
	bottom: styled.div`
		position: absolute; cursor: row-resize; z-index: 3;
		left: 0; bottom: -2px; 
		height: 5px;
		width: 100%;
		&:hover { ${hover} }
	`,
	left: styled.div`
		position: absolute; cursor: col-resize; z-index: 3;
		left: -2px; top: 0; 
		height: 100%;
		width: 5px;
		&:hover { ${hover} }
	`,
	right: styled.div`
		position: absolute; cursor: col-resize; z-index: 3;
		right: -2px; top: 0; 
		height: 100%;
		width: 5px;
		&:hover { ${hover} }
	`,
}



export const Grip = ( props: IProps )=>{
	const here = `pane#Grip[${ props.gripKey }]`;
	console.time(`in ${here}`);

	const refObject = useRef<HTMLDivElement>(null);

	useEffect( ()=>{
		console.debug(`in ${here}, mounted.`);
		return ()=>{ 
			console.debug(`in ${here}, unmounted.`);
		}
	}, [] );

	const [ startXY, setStartXY ] = useState(()=>({x:0,y:0}) );
	// onDrag で無用に処理しないように前回の位置との比較用
	const [ prevXY, setPrevXY ] = useState(()=>({x:0,y:0}) ); 

	//
	//
	//
	const on = ( e:React.DragEvent<HTMLElement> )=>{
		const here = `pane#Grip[${ props.gripKey }]#on[type:${e.type}]`;
		if ( !(e.currentTarget instanceof HTMLElement) ) throw new Error(`e.currentTarget must be instanceof HTMLElement`);
		if ( !(props.gripKey == "top" || props.gripKey ==  "bottom" || props.gripKey == "left" || props.gripKey == "right" ) ) throw new Error(`props.gripKey is invalid`);
		if( e.clientX == 0 && e.clientY == 0 && e.pageX == 0 && e.pageY == 0 ) {
			// なぜか drop 直前の「drag」イベントで 全てのポジションが 一瞬 0 になる。無視する。
			console.warn(`in ${here}, all position is 0.`);
			return ;
		}

		let targetKey:string, value: number; 
		
		if ( props.gripKey == "left" ){
			targetKey = "width"; value = startXY.x - e.clientX;
		} else if ( props.gripKey == "right" ){
			targetKey = "width"; value = e.clientX - startXY.x;
		} else if ( props.gripKey == "top" ){
			targetKey = "height"; value = startXY.y - e.clientY;
		} else if ( props.gripKey == "bottom" ){
			targetKey = "height"; value = e.clientY - startXY.y;
		} else { throw new Error(`invalid gripKey`) }

		if ( e.type == "dragstart" ){
			console.debug(`in ${here}`);
			e.dataTransfer.dropEffect = "move";
			e.dataTransfer.effectAllowed = "move" ;
			// ドラッグ開始の位置を保存
			setStartXY({ x: e.clientX, y: e.clientY });
			console.debug(`in ${here},start x: ${e.clientX}, y: ${e.clientY}`);

		} else if ( e.type == "drag" ){

			if ( e.clientX === prevXY.x && e.clientY === prevXY.y ) return;
			_resizePane( false, targetKey, value );
			console.debug(`in ${here},drag x: ${e.clientX}, y: ${e.clientY}  start x: ${startXY.x}, y: ${startXY.y}`);
			setPrevXY({ x: e.clientX, y: e.clientY });

		} else if ( e.type == "dragend" ){
			console.debug(`in ${here},end x: ${e.clientX}, y: ${e.clientY}  start x: ${startXY.x}, y: ${startXY.y}`);
			props.resizePane( true, targetKey, value );

		}
	};// on

	const _resizePane = useMemo( ()=>throttle( props.resizePane, 100 ), [] );

	const Dom = Div[props.gripKey];

	console.timeEnd(`in ${here}`);
	return (
			<Dom 
				ref={refObject} 
				key={props.gripKey} 
				onDragStart={on} 
				onDrag={on} 
				onDragEnd={on} 
				draggable 
			/>
		)

}
