import styled from 'styled-components';
import React,{
	//useContext,
	useState,
	//useEffect,
	//useMemo,
	//useRef,
	//useReducer
	//Suspense,
} from 'react';


import * as types from "../../../../types";


import {
	IStylingState,
	IFeatureItems,
	TStylingDispatch,
} from "../types";

import {
	//getNewValue
	hasChanged,
} from "../helper";




import {
	Modal,
} from "../../../../portal/modal";


import {
	Background
} from "./Background";

import {
	Text
} from "./Text";

import {
	Strong
} from "./Strong";


export const StylingPanelFeature: React.FC<{
	typeKey: keyof IStylingState;
	sectionKey: keyof IStylingState[keyof IStylingState];
	
	styleItems: IFeatureItems;
	stylingDispatch: React.Dispatch<TStylingDispatch>;
	oldStyleItems: IFeatureItems;
}> = ({ 
	typeKey, 
	sectionKey, 
	styleItems,
	stylingDispatch,
	oldStyleItems,
})=>{


	return (
		<>
			<table cellPadding="0" cellSpacing="0" >
				<tbody>
					<tr>
						<th>backgrounds</th>
						<td><Background {...{ styleItems, sectionKey, stylingDispatch }}/></td>
					</tr>
					<tr>
						<th>text</th>
						<td><Text {...{ styleItems, sectionKey, stylingDispatch }} /></td>
					</tr>
					<tr>
						<th>strong</th>
						<td><Strong {...{ styleItems, sectionKey, stylingDispatch }} /></td>
					</tr>
				</tbody>
			</table>
			
									
		</>
	);

}
StylingPanelFeature.displayName = "StylingPanelFeature";






