

export const debounce = ( callback:( ...x: any[] )=>any, duration: number )=>{
	//console.log("debounce set!")
	let tid: any = null;
	return function( ...args: any[] ){
		//console.log("debounce enter!", (new Date()).getTime() )
		if ( tid !== null ) {
			//console.log("debounce clear!")
			clearTimeout(tid);
			tid = null;
		}
		tid = setTimeout( ()=>{
			//console.log("debounce go!")
			callback.apply( null, args );
		}, duration );
	};
}